// authContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from './firebase'; // Importez l'instance auth de Firebase
import { onAuthStateChanged } from 'firebase/auth';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);  // Stockage de l'utilisateur connecté
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);  // Met à jour l'état utilisateur
      setLoading(false);     // Indique que la vérification est terminée
    });

    return unsubscribe; // Nettoyage du listener à la déconnexion
  }, []);

  return (
    <AuthContext.Provider value={{ user, loading }}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

// Hook personnalisé pour consommer facilement le contexte
export function useAuth() {
  return useContext(AuthContext);
}
