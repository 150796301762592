import React, { useState, useEffect, useContext, Suspense, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, setDoc, collection, updateDoc, getDoc, increment, Timestamp } from 'firebase/firestore';
import Screen1 from './Screens/Screen1';
import Screen2 from './Screens/Screen2';
import Screen3 from './Screens/Screen3';
import Screen4 from './Screens/Screen4';
import Screen4Bis from './Screens/Screen4Bis';
import Screen5 from './Screens/Screen5';
import Screen6 from './Screens/Screen6';
import Screen6Bis from './Screens/Screen6Bis';
import Screen6Ter from './Screens/Screen6Ter';
import Screen7 from './Screens/Screen7';
import StandPreview from './StandPreview';
import { ConfigContext } from './Contexte';
import '../index.css';

function MainComponent() {
  const [currentScreen, setCurrentScreen] = useState(1);
  const [isLoading, setIsLoading] = useState(true); // Pour le chargement des données
  const navigate = useNavigate();
  const location = useLocation();

  const { configuration: standConfig, setConfiguration: updateContextConfig } = useContext(ConfigContext);
  const projectId = location.state?.projectId;
  const targetScreen = location.state?.targetScreen;

  // Fonction pour mettre à jour la configuration
  const updateConfig = useCallback((key, value) => {
    updateContextConfig((prevConfig) => ({
      ...prevConfig,
      [key]: value,
    }));
  }, [updateContextConfig]);
  
  
  // Charger les données du stand si `projectId` est défini
  useEffect(() => {
    const fetchStandData = async () => {
      if (projectId) {
        setIsLoading(true); // Commence le chargement
        try {
          const standRef = doc(db, 'stands', projectId);
          const standSnapshot = await getDoc(standRef);
          if (standSnapshot.exists()) {
            const standData = standSnapshot.data();
            updateContextConfig((prevConfig) => ({
              ...prevConfig,
              ...standData,
              standId: projectId,
            }));
            console.log("Données du stand chargées :", standData);
          } else {
            console.warn("Stand non trouvé avec l'ID :", projectId);
          }
        } catch (error) {
          console.error("Erreur lors du chargement des données du stand :", error);
        } finally {
          setIsLoading(false); // Fin du chargement
        }
      }
    };
    fetchStandData();
  }, [projectId, updateContextConfig]);

  // Définir l'écran cible si `targetScreen` est fourni
  useEffect(() => {
    if (targetScreen) {
      const screenMap = {
        Screen1: 1,
        Screen2: 2,
        Screen3: 3,
        Screen4: 4,
        Screen4Bis: 5,
        Screen5: 6,
        Screen6: 7,
        Screen6Bis: 8,
        Screen6Ter: 9,
        Screen7: 10,
      };
      setCurrentScreen(screenMap[targetScreen] || 1);
    }
  }, [targetScreen]);

  // Fonction de sauvegarde pour Screen1
  const saveScreen1Data = async () => {
    const user = auth.currentUser;
    if (!user) {
      console.warn("Utilisateur non authentifié");
      return;
    }

    try {
      const exposantId = user.uid;
      const standRef = doc(collection(db, "stands"));
      const standId = standRef.id;

      console.log("Création d'un stand avec l'ID :", standId);

      await setDoc(standRef, {
        exposantId,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        floorSize: standConfig.floorSize || "300x300",
      });

      updateConfig("standId", standId);

      const userRef = doc(db, "users", exposantId);
      await updateDoc(userRef, {
        savedStands: increment(1),
      });

      console.log("Stand et configuration de Screen1 sauvegardés !");
    } catch (error) {
      console.error("Erreur lors de la sauvegarde de Screen1 :", error);
    }
  };

  // Fonction de sauvegarde pour Screen2
  const saveScreen2Data = async () => {
    const user = auth.currentUser;
    const standId = standConfig.standId;

    if (!user || !standId) {
      console.warn("Utilisateur non authentifié ou standId manquant");
      return;
    }

    try {
      const standRef = doc(db, "stands", standId);
      await updateDoc(standRef, {
        type: standConfig.type || "Stand linéaire",
        updatedAt: Timestamp.now(),
      });

      console.log("Type de stand pour Screen2 sauvegardé !");
    } catch (error) {
      console.error("Erreur lors de la sauvegarde de Screen2 :", error);
    }
  };

  // Fonction de sauvegarde pour Screen3
  const saveScreen3Data = async () => {
    const user = auth.currentUser;
    const standId = standConfig.standId;

    if (!user || !standId) {
      console.warn("Utilisateur non authentifié ou standId manquant");
      return;
    }

    try {
      const standRef = doc(db, "stands", standId);
      await updateDoc(standRef, {
        wallColor: standConfig.wallColor || "#ffffff",
        floorColor: standConfig.floorColor || "#ffffff",
        floorMaterial: standConfig.floorMaterial || "Moquette",
        updatedAt: Timestamp.now(),
      });

      console.log("Sélections de Screen3 sauvegardées !");
    } catch (error) {
      console.error("Erreur lors de la sauvegarde de Screen3 :", error);
    }
  };

  // Fonction de sauvegarde pour Screen4
  const saveScreen4Data = async () => {
    const user = auth.currentUser;
    const standId = standConfig.standId;

    if (!user || !standId) {
      console.warn("Utilisateur non authentifié ou standId manquant");
      return;
    }

    try {
      const standRef = doc(db, "stands", standId);
      await updateDoc(standRef, {
        elements: standConfig.elements || [],
        updatedAt: Timestamp.now(),
      });

      console.log("Éléments pour Screen4 sauvegardés !");
    } catch (error) {
      console.error("Erreur lors de la sauvegarde de Screen4 :", error);
    }
  };

  // Fonction de sauvegarde pour Screen4Bis
  const saveScreen4BisData = async () => {
    const user = auth.currentUser;
    const standId = standConfig.standId;

    if (!user || !standId) {
      console.warn("Utilisateur non authentifié ou standId manquant");
      return;
    }

    try {
      const standRef = doc(db, "stands", standId);
      await updateDoc(standRef, {
        services: standConfig.services || [],
        updatedAt: Timestamp.now(),
      });

      console.log("Services sélectionnés pour Screen4Bis sauvegardés !");
    } catch (error) {
      console.error("Erreur lors de la sauvegarde des services de Screen4Bis :", error);
    }
  };

  // Fonction de sauvegarde pour Screen5
  const saveScreen5Data = async () => {
    const user = auth.currentUser;
    const standId = standConfig.standId;

    if (!user || !standId) {
      console.warn("Utilisateur non authentifié ou standId manquant");
      return;
    }

    try {
      const standRef = doc(db, "stands", standId);
      await updateDoc(standRef, {
        totalCost: standConfig.totalCost || 0,
        updatedAt: Timestamp.now(),
      });

      console.log("Coût total sauvegardé pour Screen5 !");
    } catch (error) {
      console.error("Erreur lors de la sauvegarde de Screen5 :", error);
    }
  };

  // Fonction de sauvegarde pour Screen6
  const saveScreen6Data = async () => {
    const user = auth.currentUser;
    const standId = standConfig.standId;

    if (!user || !standId) {
      console.warn("Utilisateur non authentifié ou standId manquant");
      return;
    }

    try {
      const standRef = doc(db, "stands", standId);

      if (Object.keys(standConfig.devis || {}).length > 0) {
        await updateDoc(standRef, {
          devis: standConfig.devis,
          updatedAt: Timestamp.now(),
        });

        console.log("Données de Screen6 sauvegardées !");
      } else {
        console.warn("Aucune donnée valide dans devis à sauvegarder");
      }
    } catch (error) {
      console.error("Erreur lors de la sauvegarde de Screen6 :", error);
    }
  };

  // Fonction de sauvegarde pour Screen6Bis
const saveScreen6BisData = async () => {
  const user = auth.currentUser;
  const standId = standConfig.standId;

  if (!user || !standId) {
    console.warn("Utilisateur non authentifié ou standId manquant");
    return;
  }

  try {
    const standRef = doc(db, "stands", standId);

    // Filtrer `standConfig.devis` pour éviter les champs vides et doublons
    const filteredCompanyInfo = Object.fromEntries(
      Object.entries(standConfig.devis?.companyInfo || {}).filter(([key, value]) => value && key !== "companyName")
    );

    console.log("Données filtrées à sauvegarder pour Screen6Bis:", filteredCompanyInfo);

    await updateDoc(standRef, {
      "devis.companyInfo": filteredCompanyInfo, // Assurez-vous que `companyInfo` est bien structurée
      updatedAt: Timestamp.now(),
    });

    console.log("Données de Screen6Bis sauvegardées !");
  } catch (error) {
    console.error("Erreur lors de la sauvegarde de Screen6Bis :", error.message);
  }
};

// Fonction de sauvegarde pour Screen6Ter
const saveScreen6TerData = async () => {
  const user = auth.currentUser;
  const standId = standConfig.standId;

  if (!user || !standId) {
    console.warn("Utilisateur non authentifié ou standId manquant");
    return;
  }

  try {
    const standRef = doc(db, "stands", standId);

    // Filtrer `standConfig.contactInfo` pour éviter les champs vides
    const filteredContactInfo = Object.fromEntries(
      Object.entries(standConfig.contactInfo || {}).filter(([_, value]) => value)
    );

    console.log("Données filtrées à sauvegarder pour Screen6Ter:", filteredContactInfo);

    await updateDoc(standRef, {
      contactInfo: filteredContactInfo,
      updatedAt: Timestamp.now(),
    });

    console.log("Données de Screen6Ter sauvegardées !");
  } catch (error) {
    console.error("Erreur lors de la sauvegarde de Screen6Ter :", error.message);
  }
};


// Définir les libellés des boutons de navigation
const getNextButtonText = () => {
    switch (currentScreen) {
      case 1:
        return "Suivant";
      case 2:
        return "Suivant : Personnaliser votre stand";
      case 3:
        return "Suivant : Ajouter des éléments";
      case 4:
        return "Suivant : Ajouter des services";
      case 5:
        return "Suivant : Coût de votre projet";
      case 6:
        return "Suivant : Recevoir votre Devis";
      case 7:
        return "Suivant : Recevoir votre Devis";
      case 8:
        return "Suivant : Recevoir votre Devis";
      case 9:
        return "Suivant : Enregistrez votre Stand"
      default:
        return "Suivant";
    }
  }; 

  // Gestion de la navigation entre les écrans avec les sauvegardes
  const goToNextScreen = async () => {
    if (currentScreen === 1) {
      await saveScreen1Data();
    } else if (currentScreen === 2) {
      await saveScreen2Data();
    } else if (currentScreen === 3) {
      await saveScreen3Data();
    } else if (currentScreen === 4) {
      await saveScreen4Data();
    } else if (currentScreen === 5) {
      await saveScreen4BisData();  // Sauvegarde des services de Screen4Bis
    } else if (currentScreen === 6) {
      await saveScreen5Data();
    } else if (currentScreen === 7) {
      await saveScreen6Data();
    } else if (currentScreen === 8) {
      await saveScreen6BisData();
    } else if (currentScreen === 9) {
      await saveScreen6TerData();
    }
    setCurrentScreen((prevScreen) => Math.min(prevScreen + 1, 10));
  };

  const goToPreviousScreen = () => {
    if (currentScreen === 1) {
      navigate('/');
    } else {
      setCurrentScreen((prevScreen) => Math.max(prevScreen - 1, 1));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentScreen]);

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      <div className="lg:w-1/2 w-full h-1/3 lg:h-full p-4">
        <StandPreview
          floorColor={standConfig.floorColor || "#ffffff"}
          wallColor={standConfig.wallColor || "#ffffff"}
        />
      </div>
  
      <div className="lg:w-1/2 w-full h-2/3 lg:h-full p-4 overflow-auto">
        <Suspense fallback={<div>Chargement...</div>}>
          {currentScreen === 1 && <Screen1 updateConfig={updateConfig} standConfig={standConfig} />}
          {currentScreen === 2 && <Screen2 updateConfig={updateConfig} standConfig={standConfig} />}
          {currentScreen === 3 && <Screen3 updateConfig={updateConfig} standConfig={standConfig} />}
          {currentScreen === 4 && <Screen4 updateConfig={updateConfig} standConfig={standConfig} />}
          {currentScreen === 5 && <Screen4Bis updateConfig={updateConfig} standConfig={standConfig} />}
          {currentScreen === 6 && <Screen5 updateConfig={updateConfig} standConfig={standConfig} />}
          {currentScreen === 7 && <Screen6 updateConfig={updateConfig} standConfig={standConfig} />}
          {currentScreen === 8 && <Screen6Bis updateConfig={updateConfig} standConfig={standConfig} />}
          {currentScreen === 9 && <Screen6Ter updateConfig={updateConfig} standConfig={standConfig} />}
          {currentScreen === 10 && <Screen7 updateConfig={updateConfig} standConfig={standConfig} />}
        </Suspense>
  
        <div className="flex justify-between mt-4">
          <button 
            onClick={goToPreviousScreen} 
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400"
          >
            {currentScreen === 1 ? "Retour à l'accueil" : "Précédent"}
          </button>
  
          <button 
            onClick={goToNextScreen} 
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600" 
            disabled={currentScreen === 10}
          >
            {currentScreen < 10 ? getNextButtonText() : "Enregistrer et Terminer"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MainComponent;