// src/Formules/SuccessPage/MainSuccessPage.js
import React, { useState, useEffect, useRef } from "react";
import SuccessPage from "./SuccessPage"; // Chemin : src/Formules/SuccessPage/SuccessPage.js
import Header from "../../Site/Accueil/Header"; // Chemin : src/Site/Accueil/Header.js
import Footer from "../../Site/Accueil/Footer"; // Chemin : src/Site/Accueil/Footer.js

function MainSuccessPage() {
  const [counter, setCounter] = useState(15); // Compte à rebours en secondes
  const canvasRef = useRef(null);

  // Compte à rebours et redirection
  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => setCounter((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    } else {
      window.location.href = "/espace-prestataire";
    }
  }, [counter]);

  // Animation des confettis
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const confettis = [];

    // Ajuster les dimensions du canvas
    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    // Initialiser les dimensions du canvas avant l'animation
    handleResize();

    // Génération des confettis
    for (let i = 0; i < 150; i++) {
      confettis.push({
        x: Math.random() * canvas.width,
        y: Math.random() * -canvas.height,
        width: Math.random() * 6 + 4,
        height: Math.random() * 8 + 4,
        speed: Math.random() * 1.5 + 0.5,
        angle: Math.random() * 360,
        rotationSpeed: Math.random() * 3 + 0.5,
        color: Math.random() < 0.15
          ? `hsl(50, 100%, ${50 + Math.random() * 20}%)`
          : `hsl(${200 + Math.random() * 60}, 100%, ${50 + Math.random() * 10}%)`,
        drift: Math.random() * 0.5 - 0.25,
      });
    }

    // Fonction d'animation des confettis
    function animateConfetti() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      confettis.forEach((c) => {
        c.angle += c.rotationSpeed;
        const radians = (c.angle * Math.PI) / 180;

        ctx.save();
        ctx.translate(c.x + c.width / 2, c.y + c.height / 2);
        ctx.rotate(radians);

        ctx.fillStyle = c.color;
        ctx.fillRect(-c.width / 2, -c.height / 2, c.width, c.height);
        ctx.restore();

        c.y += c.speed;
        c.x += c.drift;
        if (c.y > canvas.height) {
          c.y = -10;
          c.x = Math.random() * canvas.width;
        }
      });

      requestAnimationFrame(animateConfetti);
    }

    // Commencer l'animation après une courte pause pour stabiliser le canvas
    setTimeout(() => animateConfetti(), 50);

    // Ajuster la taille du canvas lors du redimensionnement
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="relative min-h-screen flex flex-col bg-white text-center overflow-hidden">
      <Header />
      
      {/* Canvas des confettis en premier plan */}
      <canvas ref={canvasRef} className="absolute top-0 left-0 w-full h-full z-50 pointer-events-none"></canvas>
      
      <main className="relative flex-grow flex items-center justify-center z-10">
        <SuccessPage />
      </main>
      
      <Footer />
    </div>
  );
}

export default MainSuccessPage;
