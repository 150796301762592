// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';

function PrivateRoute({ children, fallback }) {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return <p>Chargement...</p>;
  }

  if (user) {
    return children;
  }

  return <Navigate to={fallback} />;
}

export default PrivateRoute;
