import React from "react";

const DemandeCard = ({ lead, viewMode, getServiceIcon }) => {
  return (
    <div
      className={`group bg-white/95 backdrop-blur-sm rounded-3xl shadow-lg p-8 hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2 border-2 border-transparent hover:border-blue-200 relative overflow-hidden ${
        viewMode === "list" ? "flex flex-col md:flex-row md:items-center md:gap-8" : ""
      }`}
    >
      <div className="absolute top-4 left-4 flex gap-2">
        {lead.services.map((service, index) => (
          <div
            key={index}
            className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 hover:bg-blue-200 transition-colors"
            title={service}
          >
            <i className={`fas ${getServiceIcon(service)}`}></i>
          </div>
        ))}
      </div>

      {lead.status === "new" && (
        <div className="absolute -right-12 top-4 bg-gradient-to-r from-blue-600 to-indigo-500 text-white px-16 py-1 rotate-45 text-sm font-bold shadow-lg z-10">
          NOUVEAU
        </div>
      )}
      <div className={`mb-4 ${viewMode === "list" ? "flex-1" : ""}`}>
        <div className="flex justify-between items-start mb-4 mt-8">
          <div>
            <h3 className="text-2xl font-bold text-gray-900 font-roboto mb-2">{lead.event}</h3>
            <p className="text-gray-600 text-lg mb-3">{lead.description}</p>
          </div>
          <div className="flex flex-col items-end">
            <div className="bg-blue-50 rounded-xl p-2 mb-2">
              <span className="text-blue-600 font-bold text-lg">Match {lead.matchScore}</span>
            </div>
            {lead.emailVerified && (
              <span className="text-green-600 text-sm bg-green-50 rounded-lg px-2 py-1">
                <i className="fas fa-check-circle mr-1"></i>
                Email vérifié
              </span>
            )}
          </div>
        </div>
        <div className="flex items-center gap-4">
          <div className="bg-green-50 rounded-xl p-2 flex items-center">
            <i className="fas fa-coins text-green-600 mr-2"></i>
            <span className="text-green-600 font-medium">Revenu potentiel: {lead.potentialRevenue}</span>
          </div>
        </div>
        <div className="mt-1 text-gray-500 text-sm">
          <i className="fas fa-clock mr-2"></i>
          Actif {lead.lastActive}
        </div>
      </div>
      <div className={`flex flex-wrap gap-3 mb-4 ${viewMode === "list" ? "w-1/3" : ""}`}>
        <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm font-medium">
          {lead.category}
        </span>
        <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm font-medium">
          {lead.service}
        </span>
        <span className="px-3 py-1 bg-purple-100 text-purple-800 rounded-full text-sm font-medium">
          {lead.budget}
        </span>
        <span
          className={`px-3 py-1 rounded-full text-sm font-medium ${
            lead.urgency === "haute" ? "bg-red-100 text-red-800" : lead.urgency === "moyenne" ? "bg-yellow-100 text-yellow-800" : "bg-gray-100 text-gray-800"
          }`}
        >
          Urgence {lead.urgency}
        </span>
      </div>
      <div className="flex justify-between items-center">
        <span className="text-gray-500 text-sm">
          <i className="far fa-calendar-alt mr-2"></i>
          {lead.date}
        </span>
        {lead.available ? (
          <span className="text-green-600 text-sm font-medium">
            <i className="fas fa-check-circle mr-2"></i>
            Coordonnées disponibles
          </span>
        ) : (
          <span className="text-red-600 text-sm font-medium">
            <i className="fas fa-times-circle mr-2"></i>
            Coordonnées indisponibles
          </span>
        )}
      </div>
      <button className="w-full mt-6 bg-gradient-to-r from-blue-600 to-indigo-500 text-white py-4 rounded-2xl hover:from-blue-700 hover:to-indigo-600 transition-all duration-300 flex items-center justify-center font-medium text-lg shadow-lg hover:shadow-xl group relative overflow-hidden">
        <div className="absolute inset-0 bg-white/20 transform -skew-x-12 -translate-x-full group-hover:translate-x-full transition-transform duration-700"></div>
        <i className="fas fa-eye mr-3 group-hover:scale-110 transition-transform"></i>
        <span className="relative z-10">Voir les détails</span>
        <div className="absolute right-4">
          <i className="fas fa-arrow-right group-hover:translate-x-2 transition-transform"></i>
        </div>
      </button>
    </div>
  );
};

export default DemandeCard;
