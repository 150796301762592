import React from "react";
import { useNavigate } from "react-router-dom";

const PrestatairesAnnuaire = () => {
  const navigate = useNavigate();

  const handleViewAnnuaire = () => {
    navigate("/annuaire");
  };

  const handleInscription = () => {
    navigate("/onboarding");
  };

  return (
    <section 
      className="py-32 px-4 text-[#1a1a1a] relative overflow-hidden"
      style={{
        background: "linear-gradient(to bottom right, #1e3a8a, #000)",
      }}
    >
      <div className="max-w-7xl mx-auto grid md:grid-cols-2 gap-20 items-center relative z-10">
        <div className="text-center md:text-left">
          <h2 className="text-4xl md:text-5xl font-bold mb-8 text-[#007bff] leading-tight">
            L'Annuaire des Prestataires pour les Exposants
          </h2>
          <p className="text-xl mb-12 text-[#666] leading-relaxed">
            Accédez à une audience exclusive d'exposants qualifiés à la
            recherche de services pour leurs stands.
          </p>
          <div className="flex flex-col md:flex-row gap-6">
            <button 
              onClick={handleViewAnnuaire}
              className="bg-[#007bff] text-white hover:bg-[#0056b3] font-bold py-5 px-10 rounded-2xl text-xl transition-all duration-300 transform hover:scale-105 hover:shadow-lg"
            >
              Voir l'annuaire
            </button>
            <button 
              onClick={handleInscription}
              className="bg-transparent border-3 border-[#007bff] text-[#007bff] hover:bg-[#007bff]/5 font-bold py-5 px-10 rounded-2xl text-xl transition-all duration-300 hover:shadow-lg"
            >
              Inscription gratuite
            </button>
          </div>
        </div>
        <div className="bg-white rounded-[2rem] p-12 space-y-8 shadow-2xl transform hover:scale-105 transition-all duration-500 border border-[#007bff]/10 hover:shadow-3xl">
          <div className="flex items-center justify-between mb-8">
            <h3 className="text-3xl font-bold text-[#007bff]">Audience Exclusive</h3>
            <span className="bg-[#007bff]/10 text-[#007bff] px-4 py-1 rounded-full text-sm font-semibold">
              Premium
            </span>
          </div>
          <div className="space-y-6">
            <div className="flex items-center gap-6 bg-[#f8f9fa] p-6 rounded-2xl hover:bg-[#007bff]/5 transition-all duration-300 border border-[#007bff]/10">
              <div className="w-16 h-16 bg-[#007bff]/10 rounded-xl flex items-center justify-center">
                <i className="fas fa-building text-3xl text-[#007bff]"></i>
              </div>
              <div>
                <p className="font-bold text-xl mb-1 text-[#1a1a1a]">Exposants Qualifiés</p>
                <p className="text-[#666]">Plus de 5000 entreprises actives</p>
              </div>
            </div>
            <div className="flex items-center gap-6 bg-[#f8f9fa] p-6 rounded-2xl hover:bg-[#007bff]/5 transition-all duration-300 border border-[#007bff]/10">
              <div className="w-16 h-16 bg-[#007bff]/10 rounded-xl flex items-center justify-center">
                <i className="fas fa-calendar-check text-3xl text-[#007bff]"></i>
              </div>
              <div>
                <p className="font-bold text-xl mb-1 text-[#1a1a1a]">Organisateurs de Salons</p>
                <p className="text-[#666]">Top 50 des salons français</p>
              </div>
            </div>
            <div className="flex items-center gap-6 bg-[#f8f9fa] p-6 rounded-2xl hover:bg-[#007bff]/5 transition-all duration-300 border border-[#007bff]/10">
              <div className="w-16 h-16 bg-[#007bff]/10 rounded-xl flex items-center justify-center">
                <i className="fas fa-chart-line text-3xl text-[#007bff]"></i>
              </div>
              <div>
                <p className="font-bold text-xl mb-1 text-[#1a1a1a]">Décideurs</p>
                <p className="text-[#666]">Budget moyen de 15-30K€</p>
              </div>
            </div>
          </div>
          <div className="pt-4">
            <div className="flex items-center justify-between text-[#666] text-sm border-t border-[#007bff]/10 pt-6">
              <span className="flex items-center gap-2">
                <i className="fas fa-check-circle text-[#007bff]"></i> Profils vérifiés
              </span>
              <span className="flex items-center gap-2">
                <i className="fas fa-sync text-[#007bff]"></i> Mise à jour quotidienne
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrestatairesAnnuaire;
