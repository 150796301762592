import React from "react";
import Header from "../Accueil/Header";  // Chemin mis à jour vers src/Site/Accueil/Header.js
import Devis from "./Devis";    // Chemin inchangé, assure-toi que Devis.js est bien dans le même répertoire
import Footer from "../Accueil/Footer";  // Chemin mis à jour vers src/Site/Accueil/Footer.js

function MainDevis() {
  return (
    <div>
      {/* Inclusion du Header */}
      <Header />

      {/* Section Devis */}
      <Devis />

      {/* Inclusion du Footer */}
      <Footer />
    </div>
  );
}

export default MainDevis;
