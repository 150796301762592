import React, { useState, useEffect } from "react";
import { auth, db } from "../firebase";
import { collection, where, query, getDocs } from "firebase/firestore";
import StandCard from "./StandCard";

function BOExposant() {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState("Tous");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.warn("Aucun utilisateur connecté.");
          return;
        }

        const projectsQuery = query(
          collection(db, "stands"),
          where("contactInfo.contactEmail", "==", user.email)
        );

        const projectsSnapshot = await getDocs(projectsQuery);
        const projectsData = projectsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate() || new Date(),
        }));

        // Trier les projets par date de création
        projectsData.sort((a, b) => b.createdAt - a.createdAt);

        setProjects(projectsData);
        setFilteredProjects(projectsData);
      } catch (error) {
        console.error("Erreur lors de la récupération des projets :", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    filterProjects(value, statusFilter);
  };

  const handleStatusFilter = (e) => {
    const value = e.target.value;
    setStatusFilter(value);
    filterProjects(search, value);
  };

  const filterProjects = (searchTerm, status) => {
    const filtered = projects.filter(
      (project) =>
        (status === "Tous" || project.status === status) &&
        project.standName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProjects(filtered);
  };

  return (
    <div className="px-4 py-8">
      {/* Titre principal */}
      <div className="mb-6 text-center">
        <h1 className="text-4xl font-bold text-gray-800">Mon Espace Exposant</h1>
        <p className="text-gray-600 mt-2">
          Gérez vos stands, consultez les détails de chaque projet et suivez l'état de vos demandes de devis.
        </p>
      </div>

      {/* Header aligné et espacé */}
      <div className="bg-gradient-to-r from-blue-100 to-indigo-100 p-4 md:p-6 rounded-lg shadow-md flex flex-wrap justify-between items-center gap-4 mb-8">
        <div className="flex items-center gap-2 text-gray-700 text-sm md:text-base">
          <i className="fas fa-folder text-xl text-blue-600"></i>
          <span className="font-semibold">
            Total : {filteredProjects.length} projets
          </span>
        </div>
        <div className="flex flex-wrap gap-4 items-center">
          <input
            type="text"
            value={search}
            onChange={handleSearch}
            placeholder="Rechercher un projet..."
            className="border border-gray-300 rounded-lg px-4 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <select
            value={statusFilter}
            onChange={handleStatusFilter}
            className="border border-gray-300 rounded-lg px-4 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="Tous">Tous les statuts</option>
            <option value="en cours">En cours</option>
            <option value="en pause">En pause</option>
            <option value="relancer">Relancer</option>
          </select>
          <button className="flex items-center gap-2 bg-gradient-to-r from-blue-600 to-blue-700 text-white px-4 py-2 rounded-lg shadow-md font-semibold hover:scale-105 transition-all duration-200 text-sm">
            <i className="fas fa-plus"></i>
            Créer un Stand
          </button>
          <button className="flex items-center gap-2 bg-gradient-to-r from-teal-500 to-teal-600 text-white px-4 py-2 rounded-lg shadow-md font-semibold hover:scale-105 transition-all duration-200 text-sm">
            <i className="fas fa-file-invoice"></i>
            Devis Rapide
          </button>
          <button className="flex items-center gap-2 bg-gradient-to-r from-purple-500 to-purple-600 text-white px-4 py-2 rounded-lg shadow-md font-semibold hover:scale-105 transition-all duration-200 text-sm">
            <i className="fas fa-book"></i>
            Annuaire Prestataires
          </button>
        </div>
      </div>

      {/* Liste des projets */}
      {loading ? (
        <div className="flex justify-center items-center h-40">
          <div className="loader border-t-4 border-blue-500 rounded-full w-8 h-8 animate-spin"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 place-items-center">
          {filteredProjects.map((project) => (
            <StandCard
              key={project.id}
              standId={project.id}
              standName={project.standName}
              createdAt={project.createdAt}
              totalCost={project.totalCost}
              status={project.status}
              onDelete={() => console.log(`Supprimer le stand : ${project.id}`)}
              onTitleChange={(newTitle) =>
                console.log(`Titre mis à jour pour ${project.id} : ${newTitle}`)
              }
              onView={() => console.log(`Visualiser le stand : ${project.id}`)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default BOExposant;
