import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const PrestatairesDemandes = () => {
  const [recentRequests, setRecentRequests] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecentRequests = async () => {
      const data = [
        {
          title: "Stand sur-mesure 72m²",
          location: "Salon de l'automobile - Paris",
          budget: "25-30K€",
          status: "Nouveau",
          date: new Date("2024-11-01T10:30:00"),
        },
        {
          title: "Solution audiovisuelle complète",
          location: "Salon du digital - Lyon",
          budget: "8-12K€",
          status: "Urgent",
          date: new Date("2024-11-02T14:15:00"),
        },
        {
          title: "Location de mobilier 50m²",
          location: "Salon des startups - Marseille",
          budget: "5-7K€",
          status: "Standard",
          date: new Date("2024-11-03T09:45:00"),
        },
      ];
      setRecentRequests(data);
    };

    fetchRecentRequests();
  }, []);

  const formatDate = (date) => {
    return date.toLocaleString("fr-FR", {
      dateStyle: "long",
      timeStyle: "short",
    });
  };

  const handleButtonClick = () => {
    navigate("/recherche");
  };

  return (
    <section className="py-32 px-4 bg-gradient-to-br from-white to-[#f8f9fa] relative">
      <div className="max-w-7xl mx-auto relative z-10">
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            Demandes de Devis Exclusives
          </h2>
          <p className="text-xl text-[#666] max-w-3xl mx-auto">
            Accédez aux demandes de devis d'exposants activement à la
            recherche de prestataires pour leurs stands.
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-20 items-stretch">
          <div className="bg-white p-12 rounded-[2rem] shadow-2xl space-y-8 hover:shadow-3xl transition-all duration-500 transform hover:-translate-y-2">
            {recentRequests.map((request, index) => (
              <div key={index} className="border-l-4 border-[#007bff] pl-4 mb-6">
                <div className="flex justify-between items-start mb-2">
                  <p className="text-xl font-bold">{request.title}</p>
                  <span className="bg-[#007bff]/10 text-[#007bff] px-3 py-1 rounded-full text-sm">
                    {request.status}
                  </span>
                </div>
                <p className="text-[#666] mb-2">{request.location}</p>
                <p className="text-[#007bff] font-bold">Budget: {request.budget}</p>
                <p className="text-[#666] text-sm">Date de soumission : {formatDate(request.date)}</p>
              </div>
            ))}
          </div>
          <div className="flex flex-col justify-center bg-gradient-to-br from-[#f8f9fa] to-white p-12 rounded-[2rem] shadow-2xl hover:shadow-3xl transition-all duration-500 transform hover:-translate-y-2">
            <h3 className="text-3xl font-bold mb-8">
              Répondez aux appels d'offres
            </h3>
            <ul className="space-y-6">
              <li className="flex items-start gap-4">
                <i className="fas fa-check-circle text-[#007bff] text-xl mt-1"></i>
                <div>
                  <p className="font-bold text-xl mb-2">Demandes qualifiées</p>
                  <p className="text-[#666]">
                    Accédez à des projets avec budgets validés.
                  </p>
                </div>
              </li>
              <li className="flex items-start gap-4">
                <i className="fas fa-check-circle text-[#007bff] text-xl mt-1"></i>
                <div>
                  <p className="font-bold text-xl mb-2">Contact direct</p>
                  <p className="text-[#666]">
                    Échangez directement avec les décideurs.
                  </p>
                </div>
              </li>
            </ul>
            <button 
              onClick={handleButtonClick}
              className="mt-8 bg-[#007bff] text-white hover:bg-[#0056b3] font-bold py-4 px-8 rounded-lg text-xl transition-all duration-300 transform hover:scale-105 w-full md:w-auto"
            >
              Accéder aux demandes de devis
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrestatairesDemandes;
