import React from 'react';
import Header from '../Accueil/Header';
import Actus from './Actus';
import Footer from '../Accueil/Footer';
import FAQAll from './FAQAll'; // Import du composant FAQAll
import Contact from './Contact'; // Import du composant Contact

const MainRessources = () => {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Intégration du Header */}
      <Header />

      {/* Section des actualités sous le Header */}
      <main className="flex-grow">
        <Actus />
      </main>

      {/* Section FAQ après les actualités */}
      <FAQAll />

      {/* Section Contact juste au-dessus du Footer */}
      <Contact />

      {/* Intégration du Footer */}
      <Footer />
    </div>
  );
};

export default MainRessources;
