// src/Site/Legal/CGV/MainCGV.js
import React, { useEffect, useRef, useState } from "react";
import Header from "../../Accueil/Header";
import Footer from "../../Accueil/Footer";
import CGV from "./CGV"; // Assurez-vous que ce chemin est correct

function MainCGV() {
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    // Met à jour la hauteur du header et ajoute un décalage de 100px
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight + 100); // Ajout de 100px de décalage
    }
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-[#f8fafc]">
      <div ref={headerRef}>
        <Header />
      </div>
      <main
        className="flex-grow py-8 px-4 sm:px-6 lg:px-8"
        style={{ paddingTop: `${headerHeight}px`, minHeight: "100%" }} // Applique une hauteur de 100%
      >
        <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8 border-t-4 border-[#1e3a8a] h-full">
          <CGV />
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default MainCGV;
