const standConfigurations = {
  "Stand linéaire": {
    "200x100": {
      sol: { path: "/assets3d/200x100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-lineaire-200x100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/comptoir-droit-90.glb", position: [1, 0, -1], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-90.glb", position: [-1, 0, -1], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "200x200": {
      sol: { path: "/assets3d/200x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-lineaire-200x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/comptoir-droit-90.glb", position: [1, 0, -1], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-90.glb", position: [-1, 0, -1], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "300x200": {
      sol: { path: "/assets3d/300x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-lineaire-300x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/comptoir-droit-100.glb", position: [1.5, 0, -1], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-100.glb", position: [-1.5, 0, -1], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "300x300": {
      sol: { path: "/assets3d/300x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-lineaire-300x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/comptoir-droit-100.glb", position: [1.5, 0, -1.5], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-100.glb", position: [-1.5, 0, -1.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "400x300": {
      sol: { path: "/assets3d/400x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-lineaire-400x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/comptoir-droit-120.glb", position: [2, 0, -1.5], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-2, 0, -1.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "500x300": {
      sol: { path: "/assets3d/500x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-lineaire-400x400.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/comptoir-droit-120.glb", position: [2.5, 0, -1.5], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-2.5, 0, -1.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "400x400": {
      sol: { path: "/assets3d/400x400.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-lineaire-500x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/comptoir-droit-200.glb", position: [2, 0, -2], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-2, 0, -2], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "500x500": {
      sol: { path: "/assets3d/500x500.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-lineaire-500x500.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/comptoir-droit-200.glb", position: [2.5, 0, -2.5], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-2.5, 0, -2.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "600x300": {
      sol: { path: "/assets3d/600x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-lineaire-600x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/comptoir-droit-200.glb", position: [3, 0, -1.5], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-3, 0, -1.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "600x600": {
      sol: { path: "/assets3d/600x600.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-lineaire-600x600.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/comptoir-droit-200.glb", position: [3, 0, -3], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-3, 0, -3], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "900x600": {
      sol: { path: "/assets3d/900x600.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-lineaire-900x600.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/comptoir-droit-200.glb", position: [4.5, 0, -3], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-4.5, 0, -3], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
  },
  "Stand d'angle": {
    "200x100": {
      sol: { path: "/assets3d/200x100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-angle-200x100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/arche-100x250x100.glb", position: [1, 0, -0.5], rotation: [0, Math.PI / 2, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-90.glb", position: [-1, 0, -0.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "200x200": {
      sol: { path: "/assets3d/200x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-angle-200x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/arche-100x250x200.glb", position: [1, 0, -1], rotation: [0, Math.PI / 2, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-90.glb", position: [-1, 0, -1], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "300x200": {
      sol: { path: "/assets3d/300x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-angle-300x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/arche-100x250x200.glb", position: [1.5, 0, -1], rotation: [0, Math.PI / 2, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-100.glb", position: [-1.5, 0, -1], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "300x300": {
      sol: { path: "/assets3d/300x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-angle-300x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/arche-100x250x300.glb", position: [1.5, 0, -1.5], rotation: [0, Math.PI / 2, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-100.glb", position: [-1.5, 0, -1.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "400x300": {
      sol: { path: "/assets3d/400x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-angle-400x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/arche-100x250x300.glb", position: [2, 0, -1.5], rotation: [0, Math.PI / 2, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-2, 0, -1.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "500x300": {
      sol: { path: "/assets3d/500x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-angle-400x400.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/arche-100x250x300.glb", position: [2.5, 0, -1.5], rotation: [0, Math.PI / 2, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-2.5, 0, -1.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "400x400": {
      sol: { path: "/assets3d/400x400.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-angle-500x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/arche-100x250x300.glb", position: [2, 0, -2], rotation: [0, Math.PI / 2, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-2, 0, -2], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "500x500": {
      sol: { path: "/assets3d/500x500.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-angle-500x500.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/arche-100x250x300.glb", position: [2.5, 0, -2.5], rotation: [0, Math.PI / 2, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-2.5, 0, -2.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "600x300": {
      sol: { path: "/assets3d/600x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-angle-600x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/arche-100x250x300.glb", position: [3, 0, -1.5], rotation: [0, Math.PI / 2, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-3, 0, -1.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "600x600": {
      sol: { path: "/assets3d/600x600.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-angle-600x600.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/arche-100x250x300.glb", position: [3, 0, -3], rotation: [0, Math.PI / 2, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-3, 0, -3], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "900x600": {
      sol: { path: "/assets3d/900x600.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/stand-angle-900x600.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/arche-100x250x300.glb", position: [4.5, 0, -3], rotation: [0, Math.PI / 2, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-4.5, 0, -3], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
  },  
  "Stand Ilot": {
    "200x100": {
      sol: { path: "/assets3d/200x100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      element1: { path: "/assets3d/comptoir-courbe-90.glb", position: [0.5, 0, -0.5], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-90.glb", position: [-0.5, 0, -0.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "200x200": {
      sol: { path: "/assets3d/200x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      element1: { path: "/assets3d/comptoir-courbe-90.glb", position: [0.5, 0, -0.5], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-90.glb", position: [-0.5, 0, -0.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "300x200": {
      sol: { path: "/assets3d/300x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      element1: { path: "/assets3d/comptoir-courbe-100.glb", position: [0.75, 0, -1], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-100.glb", position: [-0.75, 0, -1], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "300x300": {
      sol: { path: "/assets3d/300x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      element1: { path: "/assets3d/comptoir-courbe-100.glb", position: [1, 0, -1], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-100.glb", position: [-1, 0, -1], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "400x300": {
      sol: { path: "/assets3d/400x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      element1: { path: "/assets3d/comptoir-courbe-120.glb", position: [1, 0, -1.5], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-1, 0, -1.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "500x300": {
      sol: { path: "/assets3d/500x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      element1: { path: "/assets3d/comptoir-courbe-120.glb", position: [1.5, 0, -1.5], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-1.5, 0, -1.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "400x400": {
      sol: { path: "/assets3d/400x400.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      element1: { path: "/assets3d/comptoir-courbe-200.glb", position: [1, 0, -2], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-1, 0, -2], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "500x500": {
      sol: { path: "/assets3d/500x500.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      element1: { path: "/assets3d/comptoir-courbe-200.glb", position: [1.5, 0, -2.5], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-1.5, 0, -2.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "600x300": {
      sol: { path: "/assets3d/600x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      element1: { path: "/assets3d/comptoir-courbe-200.glb", position: [1.8, 0, -1.5], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-1.8, 0, -1.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "600x600": {
      sol: { path: "/assets3d/600x600.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      element1: { path: "/assets3d/comptoir-courbe-200.glb", position: [1.8, 0, -3], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-1.8, 0, -3], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "900x600": {
      sol: { path: "/assets3d/900x600.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      element1: { path: "/assets3d/comptoir-courbe-200.glb", position: [2.5, 0, -3], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/mange-debout-120.glb", position: [-2.5, 0, -3], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
  }, // Ajoutez cette virgule ici pour séparer les objets

  "StandVR Visio": {
    "200x100": {
      sol: { path: "/assets3d/200x100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/cloison-100x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/standvr-90x190.glb", position: [0.5, 0, -0.5], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/enseigne-suspendue-ronde-100.glb", position: [0, 2.5, 0], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "200x200": {
      sol: { path: "/assets3d/200x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/cloison-200x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/standvr-90x190.glb", position: [0.5, 0, -0.5], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/enseigne-suspendue-ronde-100.glb", position: [0, 2.5, 0], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "300x200": {
      sol: { path: "/assets3d/300x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/cloison-300x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/standvr-90x190.glb", position: [0.75, 0, -1], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/standvr-90x190.glb", position: [-0.75, 0, -1], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "300x300": {
      sol: { path: "/assets3d/300x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/cloison-300x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/standvr-90x190.glb", position: [1, 0, -1], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/standvr-90x190.glb", position: [-1, 0, -1], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "400x300": {
      sol: { path: "/assets3d/400x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/cloison-400x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/standvr-90x190.glb", position: [1.25, 0, -1.5], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/standvr-90x190.glb", position: [-1.25, 0, -1.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "500x300": {
      sol: { path: "/assets3d/500x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/cloison-400x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/standvr-90x190.glb", position: [1.5, 0, -1.5], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/standvr-90x190.glb", position: [-1.5, 0, -1.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "400x400": {
      sol: { path: "/assets3d/400x400.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/cloison-500x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/standvr-90x190.glb", position: [1.5, 0, -2], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/standvr-90x190.glb", position: [-1.5, 0, -2], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "500x500": {
      sol: { path: "/assets3d/500x500.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/cloison-500x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/standvr-90x190.glb", position: [2, 0, -2], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/standvr-90x190.glb", position: [-2, 0, -2], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "600x300": {
      sol: { path: "/assets3d/600x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/cloison-600x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/standvr-90x190.glb", position: [1.8, 0, -1.5], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/standvr-90x190.glb", position: [-1.8, 0, -1.5], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "600x600": {
      sol: { path: "/assets3d/600x600.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/cloison-600x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/standvr-90x190.glb", position: [2, 0, -3], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/standvr-90x190.glb", position: [-2, 0, -3], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
    "900x600": {
      sol: { path: "/assets3d/900x600.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#dddddd" },
      cloison: { path: "/assets3d/cloison-600x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#ffffff" },
      element1: { path: "/assets3d/standvr-90x190.glb", position: [2.5, 0, -3], rotation: [0, 0, 0], color: "#cccccc" },
      element2: { path: "/assets3d/standvr-90x190.glb", position: [-2.5, 0, -3], rotation: [0, 0, 0], color: "#aaaaaa" },
    },
  },
};

export default standConfigurations;
