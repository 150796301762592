import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ConfigProvider } from "./Configurateur/Contexte";
import { AuthProvider } from "./authContext";
import MainComponent from "./Configurateur/MainComponent";
import MainAccueil from "./Site/Accueil/MainAccueil";
import MainPrestataires from "./Site/Prestataires/MainPrestataires.js";
import MainDevis from "./Site/Devis/MainDevis";
import MainQui from "./Site/QuiSommesNous/MainQui";
import MainRessources from "./Site/Ressources/MainRessources";
import MainBos from "./BO-Standiste/BosScreens/MainBos";
import MainBoe from "./BO-Exposant/MainBoe";
import MainOs from "./Onboarding-Standiste/MainOs";
import MainOe from "./Onboarding-Exposant/MainOe";
import EmailVerified from "./Onboarding-Standiste/EmailVerified";
import MainAnnuaire from "./Annuaire/AccueilAnnuaire/MainAnnuaire";
import MainPageStandiste from "./Annuaire/PageStandiste/MainPageStandiste";
import MainRecherche from "./Demandes/MainRecherche";
import MainDemande from "./Demandes/MainDemande";
import MainLesFormules from "./Formules/LesFormules/MainLesFormules";
import MainFormulePro from "./Formules/FormulePro/MainFormulePro";
import MainFormuleProPlus from "./Formules/FormuleProPlus/MainFormuleProPlus";
import MainCancelPage from "./Formules/CancelPage/MainCancelPage";
import MainSuccessPage from "./Formules/SucessPage/MainSuccessPage";
import Connexion from "./Connexion/Connexion";
import PopupInscription from "./Site/Accueil/PopupInscription";
import ScrollToTop from "./ScrollToTop";
import PrivateRoute from "./PrivateRoute";
import MonProfil from "./Site/Accueil/MonProfil";
import MonProfilDetails from "./Site/Accueil/MonProfilDetails";
import MainCGU from "./Site/Legal/CGU/MainCGU";
import MainCGV from "./Site/Legal/CGV/MainCGV";
import MainMentionsLegales from "./Site/Legal/MentionsLegales/MainMentionsLegales";
import MainPolitiqueConfidentialite from "./Site/Legal/PolitiqueConfidentialite/MainPolitiqueConfidentialite";
import MainCookies from "./Site/Legal/PolitiqueCookies/MainPolitiqueCookies";
import MainContact from "./Site/Contact/MainContact";
import PageNotFound from "./Site/PageNotFound.js";
import "./index.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

// ErrorBoundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Quelque chose a mal tourné.</h1>;
    }
    return this.props.children;
  }
}

function App() {
  return (
    <AuthProvider>
      <ConfigProvider>
        <Router>
          <ScrollToTop />
          <div className="main-content">
            <ErrorBoundary>
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<MainAccueil />} />
                <Route path="/devenir-prestataire" element={<MainPrestataires />} />
                <Route path="/devis" element={<MainDevis />} />
                <Route path="/qui-sommes-nous" element={<MainQui />} />
                <Route path="/ressources" element={<MainRessources />} />
                <Route path="/onboarding" element={<MainOs />} />
                <Route path="/email-verified" element={<EmailVerified />} />
                <Route path="/onboarding-exposant" element={<MainOe />} />
                <Route path="/annuaire" element={<MainAnnuaire />} />
                <Route path="/prestataire" element={<MainPageStandiste />} />
                <Route path="/recherche" element={<MainRecherche />} />
                <Route path="/demande" element={<MainDemande />} />
                <Route path="/formules" element={<MainLesFormules />} />
                <Route path="/formules/formule-pro" element={<MainFormulePro />} />
                <Route path="/formules/formule-pro-plus" element={<MainFormuleProPlus />} />
                <Route path="/cancel" element={<MainCancelPage />} />
                <Route path="/success" element={<MainSuccessPage />} /> 
                <Route path="/connexion" element={<Connexion />} />
                <Route path="/popup-inscription" element={<PopupInscription />} />
                <Route path="/cgu" element={<MainCGU />} />
                <Route path="/cgv" element={<MainCGV />} />
                <Route path="/mentions-legales" element={<MainMentionsLegales />} />
                <Route path="/politique-confidentialite" element={<MainPolitiqueConfidentialite />} />
                <Route path="/cookies" element={<MainCookies />} />
                <Route path="/contact" element={<MainContact />} />

                {/* Protected Routes */}
                <Route path="/configurateur" element={<PrivateRoute fallback="/onboarding-exposant" requiredRole="exposant"><MainComponent /></PrivateRoute>} />
                <Route path="/espace-prestataire" element={<PrivateRoute fallback="/onboarding" requiredRole="prestataire"><MainBos /></PrivateRoute>} />
                <Route path="/espace-exposant" element={<PrivateRoute fallback="/onboarding-exposant" requiredRole="exposant"><MainBoe /></PrivateRoute>} />

                {/* Protected Profile Routes */}
                <Route path="/mon-profil" element={<PrivateRoute><MonProfil /></PrivateRoute>} />
                <Route path="/mon-profil/details" element={<PrivateRoute><MonProfilDetails /></PrivateRoute>} />

                {/* 404 Page */}
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </ErrorBoundary>
          </div>
        </Router>
      </ConfigProvider>
    </AuthProvider>
  );
}

export default App;
