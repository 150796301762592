"use client"; 
import React from "react";
import Header from "../Site/Accueil/Header"; // Chemin vers le Header
import Demande from "./Demande"; // Chemin vers le composant Demande
import Footer from "../Site/Accueil/Footer"; // Chemin vers le Footer

function MainDemande() {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Header */}
      <Header />

      {/* Contenu principal */}
      <main className="flex-grow">
        <Demande />
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default MainDemande;
