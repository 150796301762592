// src/Site/Legal/PolitiqueConfidentialite/PolitiqueConfidentialite.js
import React from "react";

function PolitiqueConfidentialite() {
  return (
    <div>
      <h1 className="text-4xl font-bold mb-12 text-[#1e3a8a] font-roboto text-center">
        Politique de Confidentialité
      </h1>
      <section className="mb-12">
        <p className="text-gray-700 mb-6">
          <strong>Date de mise à jour :</strong> 7 novembre 2024
        </p>
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Introduction
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          La protection de vos données personnelles est une priorité pour REALITIM. Cette Politique de Confidentialité explique comment nous collectons, utilisons et protégeons vos informations lorsque vous utilisez notre site web, accessible à l’adresse{" "}
          <a href="https://stand-3d.com" className="text-blue-600 underline">
            https://stand-3d.com
          </a>.
        </p>
      </section>
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Collecte de données personnelles
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Nous collectons différentes catégories de données pour fournir et améliorer nos services : informations de contact, données de navigation, données de profil, et données de paiement, si applicable.
        </p>
      </section>
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Utilisation des données collectées
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Les informations recueillies sont utilisées pour fournir, exploiter et améliorer nos services, répondre à vos demandes et personnaliser votre expérience utilisateur sur la plateforme Stand3D Builder.
        </p>
      </section>
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Partage des données
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Nous nous engageons à ne pas vendre vos informations personnelles. Toutefois, nous pouvons partager certaines données avec des prestataires de services tiers pour des raisons de paiement, d’hébergement ou d’analyses.
        </p>
      </section>
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Système de mise en avant des Prestataires Pro
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Les prestataires Pro et Pro+ bénéficient d'une visibilité accrue sur la plateforme via un système de mise en avant qui garantit une position supérieure dans les résultats de recherche et un accès prioritaire aux demandes de devis, dans la limite de leur abonnement.
        </p>
      </section>
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Sécurité des données
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles pour protéger vos informations contre tout accès non autorisé, perte ou altération.
        </p>
      </section>
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Droits des utilisateurs
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Vous avez le droit d’accéder, de rectifier, de supprimer ou de porter vos données personnelles. Pour exercer ces droits, veuillez nous contacter à <a href="mailto:contact@realitim.com" className="text-blue-600 underline">contact@realitim.com</a>.
        </p>
      </section>
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Modifications de la politique
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          REALITIM se réserve le droit de modifier cette Politique de Confidentialité. La date de dernière mise à jour sera toujours indiquée en haut de cette page.
        </p>
      </section>
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Contact
        </h2>
        <p className="text-gray-700 leading-relaxed">
          Pour toute question concernant cette politique, vous pouvez nous contacter à :
          <br />
          <strong>Email :</strong> <a href="mailto:contact@realitim.com" className="text-blue-600 underline">contact@realitim.com</a>
          <br />
          <strong>Téléphone :</strong> +33 06 61 18 01 15
        </p>
      </section>
    </div>
  );
}

export default PolitiqueConfidentialite;
