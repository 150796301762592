import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaCubes, FaMoneyBillWave, FaEdit, FaSearch, FaCalendarAlt, FaLeaf, FaChevronDown } from "react-icons/fa";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";

function FAQ() {
  const [openFAQ, setOpenFAQ] = useState(null);
  const navigate = useNavigate();

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  const handleViewAllResources = () => {
    navigate('/ressources');
  };

  const faqItems = [
    {
      question: "Comment fonctionne le configurateur 3D ?",
      answer:
        "Notre configurateur vous permet de concevoir votre stands d'exposition 3D en temps réel. Choisissez les dimensions, les matériaux et les accessoires pour visualiser instantanément votre création.",
      icon: <FaCubes />,
    },
    {
      question: "Puis-je obtenir un devis instantané ?",
      answer:
        "Oui, le configurateur vous permet d'obtenir une estimation en temps réel des coûts de votre projet. Une fois la configuration de votre stand terminée, il est possible de demander en un clic le chiffrage de votre projet à notre réseau de prestataires, pour obtenir jusqu'à 5 devis.",
      icon: <FaMoneyBillWave />,
    },
    {
      question: "Est-il possible de modifier ma conception après l'avoir sauvegardée ?",
      answer:
        "Absolument ! Vous pouvez revenir à votre conception à tout moment et y apporter des modifications. Votre projet est sauvegardé dans votre espace personnel.",
      icon: <FaEdit />,
    },
    {
      question: "Comment choisir le bon prestataire dans l'annuaire ?",
      answer:
        "Notre annuaire propose des filtres par localisation, spécialité et budget. Vous pouvez également consulter les avis des autres clients pour faire le meilleur choix.",
      icon: <FaSearch />,
    },
    {
      question: "Quels types d'événements sont adaptés à vos stands ?",
      answer:
        "Nos solutions s'adaptent à tous types d'événements : salons professionnels, foires commerciales, expositions, conférences, et même des événements en extérieur.",
      icon: <FaCalendarAlt />,
    },
    {
      question: "Proposez-vous des options écologiques pour les stands ?",
      answer:
        "Oui, nous offrons une gamme de matériaux durables et de solutions éco-responsables. Vous pouvez les sélectionner directement dans le configurateur 3D.",
      icon: <FaLeaf />,
    },
  ];

  return (
    <div className="container mx-auto px-6 py-16 max-w-3xl">
      <h2 className="text-4xl font-bold mb-10 text-center text-gray-800">Foire Aux Questions</h2>
      <IconContext.Provider value={{ color: "#0056b3", size: "1.5em" }}>
        <div className="space-y-4">
          {faqItems.map((item, index) => (
            <div
              key={index}
              className="bg-gradient-to-r from-blue-50 to-white rounded-lg shadow-md hover:shadow-lg overflow-hidden transition-all duration-300"
            >
              <button
                className="flex justify-between items-center w-full p-5 text-left font-semibold text-gray-800 hover:bg-blue-100/50 transition duration-300"
                onClick={() => toggleFAQ(index)}
              >
                <div className="flex items-center">
                  <div className="mr-2 text-blue-500">{item.icon}</div>
                  <span>{item.question}</span>
                </div>
                <span
                  className={`transform transition-transform duration-300 ${openFAQ === index ? "rotate-180" : ""}`}
                >
                  <FaChevronDown />
                </span>
              </button>
              {openFAQ === index && (
                <div className="p-5 pt-0 text-gray-600 bg-gray-50 transition-opacity duration-500 ease-in-out opacity-100">
                  <p>{item.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </IconContext.Provider>

      {/* Bouton pour voir toutes les ressources */}
      <div className="text-center mt-10">
        <Link
          to="./configurateur"
          className="px-8 py-3 bg-gradient-to-r from-blue-500 to-blue-700 text-white font-semibold rounded-lg shadow-md hover:shadow-lg transition-all duration-300 transform hover:scale-105 inline-block text-center"
        >
          Créer un stand en 3D
        </Link>
      </div>
    </div>
  );
}

export default FAQ;
