import React, { useState } from 'react';

function BosHeader({ onNotificationToggle, notificationsCount, onLogout }) {
  const [showNotifications, setShowNotifications] = useState(false);

  const handleNotificationsToggle = () => {
    setShowNotifications(!showNotifications);
    if (onNotificationToggle) {
      onNotificationToggle(!showNotifications);
    }
  };

  return (
    <header className="bg-gradient-to-r from-blue-500 to-purple-600 text-white p-4 w-full flex justify-between items-center fixed top-0 left-0 right-0 z-20 shadow-lg">
      <h1 className="text-2xl font-bold tracking-wide">Mon Espace Prestataire</h1>
      
      <div className="flex items-center space-x-6">
        {/* Voir ma page Standiste */}
        <button
          onClick={() => window.open('/ma-page-standiste', '_blank')}
          className="bg-green-500 hover:bg-green-600 text-white px-5 py-2 rounded-full shadow-lg hover:shadow-xl transform hover:scale-105 transition duration-300 ease-in-out flex items-center"
        >
          <i className="fas fa-external-link-alt mr-2"></i>
          Voir ma page Standiste
        </button>

        {/* Notifications */}
        <button
          onClick={handleNotificationsToggle}
          className="relative bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full shadow-lg hover:shadow-xl transform hover:scale-105 transition duration-300 ease-in-out flex items-center"
        >
          <i className="fas fa-bell mr-2"></i>
          Notifications
          {notificationsCount > 0 && (
            <span className="absolute -top-2 -right-2 bg-red-500 text-xs rounded-full w-6 h-6 flex items-center justify-center font-semibold">
              {notificationsCount}
            </span>
          )}
        </button>

        {/* Déconnexion */}
        <button
          onClick={onLogout}
          className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-full shadow-lg hover:shadow-xl transform hover:scale-105 transition duration-300 ease-in-out flex items-center"
        >
          <i className="fas fa-sign-out-alt mr-2"></i>
          Déconnexion
        </button>
      </div>

      {/* Notifications Dropdown */}
      {showNotifications && (
        <div className="absolute right-4 top-16 w-64 bg-white text-gray-800 p-4 rounded-lg shadow-lg animate-slide-down z-30">
          <h3 className="font-bold text-lg mb-2">Notifications récentes</h3>
          <ul className="space-y-2">
            <li className="flex items-center text-sm">
              <i className="fas fa-envelope text-blue-500 mr-2"></i>
              Nouveau message de support
            </li>
            <li className="flex items-center text-sm">
              <i className="fas fa-star text-yellow-500 mr-2"></i>
              Nouvel avis client reçu
            </li>
            <li className="flex items-center text-sm">
              <i className="fas fa-file-invoice-dollar text-green-500 mr-2"></i>
              Nouveau devis en attente
            </li>
          </ul>
        </div>
      )}
    </header>
  );
}

export default BosHeader;
