import React from "react";
import Header from "../Accueil/Header"; // Chemin correct pour le Header
import Qui from "./Qui"; // Chemin correct pour Qui
import Footer from "../Accueil/Footer"; // Chemin correct pour le Footer

function MainQui() {
  return (
    <div>
      {/* Inclusion du Header */}
      <Header />

      {/* Inclusion du composant Qui */}
      <Qui />

      {/* Inclusion du Footer */}
      <Footer />
    </div>
  );
}

export default MainQui;
