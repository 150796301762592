import React, { useState } from "react";
import { auth, db } from "../firebase"; 
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore"; 

function OsInscription({ nextStep, setFormData, formData }) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeProvider, setActiveProvider] = useState(null);
  const provider = new GoogleAuthProvider();

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    setActiveProvider("Google");
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Enregistrement de l'utilisateur comme prestataire dans Firestore
      await setDoc(doc(db, "standistes", user.uid), {
        email: user.email,
        displayName: user.displayName,
        createdAt: new Date(),
        role: "prestataire", // Définit le rôle de prestataire
      });

      nextStep();
    } catch (error) {
      setError("Erreur lors de la connexion avec Google. Veuillez réessayer.");
    }
    setIsLoading(false);
    setActiveProvider(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    setActiveProvider("Email");

    const { contactEmail, contactPassword } = formData;

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, contactEmail, contactPassword);
      await sendEmailVerification(userCredential.user);

      // Enregistrement de l'utilisateur comme prestataire dans Firestore
      await setDoc(doc(db, "standistes", userCredential.user.uid), {
        email: contactEmail,
        createdAt: new Date(),
        role: "prestataire", // Définit le rôle de prestataire
      });

      nextStep(); 
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
    setActiveProvider(null);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-100 via-purple-100 to-pink-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white rounded-3xl shadow-2xl overflow-hidden">
        <div className="md:flex">
          <div className="md:w-1/2 bg-gradient-to-br from-indigo-600 to-purple-700 p-12 text-white flex flex-col justify-center">
            <h2 className="text-4xl font-extrabold mb-6 leading-tight">Inscription Prestataire</h2>
            <p className="text-xl mb-8 leading-relaxed">
              Connectez-vous via Google ou inscrivez-vous avec votre adresse email pour rejoindre notre annuaire et commencer à recevoir des demandes de devis des exposants.
            </p>
          </div>
          <div className="md:w-1/2 p-6 bg-white">
            <h2 className="text-3xl font-bold text-gray-900 mt-4 text-center">Inscription</h2>

            {error && <p className="text-red-500 text-center mb-4">{error}</p>}

            <button
              onClick={handleGoogleSignIn}
              className="w-full mt-8 mb-6 bg-red-500 text-white py-3 px-6 rounded-lg hover:bg-red-600 transition-all duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center shadow-md"
              disabled={isLoading && activeProvider === "Google"}
            >
              <i className="fab fa-google mr-3"></i>
              Connexion avec Google
            </button>

            <div className="relative flex justify-center text-sm mb-4">
              <span className="px-2 bg-white text-gray-500">Ou</span>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="rounded-md shadow-sm">
                <div>
                  <input
                    type="email"
                    name="contactEmail"
                    className="appearance-none rounded-t-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Adresse e-mail"
                    value={formData.contactEmail}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div>
                  <input
                    type="password"
                    name="contactPassword"
                    className="appearance-none rounded-b-md relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Mot de passe"
                    value={formData.contactPassword}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="mt-6 flex justify-between">
                <button
                  type="submit"
                  className={`w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300 ease-in-out ${
                    isLoading && activeProvider === "Email" ? "opacity-75 cursor-not-allowed" : ""
                  }`}
                  disabled={isLoading}
                >
                  <i className="fas fa-envelope mr-2"></i>
                  S'inscrire avec l'email
                </button>
              </div>
            </form>

            {isLoading && (
              <div className="mt-6 text-center">
                <div className="inline-block animate-spin rounded-full h-10 w-10 border-t-4 border-b-4 border-indigo-500"></div>
                <p className="mt-3 text-base text-gray-600 animate-pulse">
                  Inscription en cours...
                </p>
              </div>
            )}

            <div className="mt-8 text-center">
              <p className="text-sm text-gray-500">
                En vous inscrivant, vous acceptez nos{" "}
                <button
                  type="button"
                  className="font-medium text-indigo-600 hover:text-indigo-500 underline transition-colors duration-300"
                >
                  Conditions d'utilisation
                </button>{" "}
                et notre{" "}
                <button
                  type="button"
                  className="font-medium text-indigo-600 hover:text-indigo-500 underline transition-colors duration-300"
                >
                  Politique de confidentialité
                </button>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OsInscription;
