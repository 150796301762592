import React from "react";
import { useNavigate } from "react-router-dom";

const PrestatairesHero = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/configurateur");
  };

  return (
    <header
      className="relative h-screen z-10 flex items-center justify-center bg-[#1e3a8a] overflow-hidden"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/stands-demo/stand-demo-1.jpeg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        backgroundColor: "#1e3a8a",
      }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-black/80 via-black/60 to-black/40 z-20"></div>

      {/* Hero text content */}
      <div className="relative z-30 h-full flex flex-col items-center justify-center text-white px-4 sm:px-8 lg:px-12 text-center max-w-screen-md mx-auto">
        <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold mb-4 sm:mb-6 lg:mb-8 animate-fade-in leading-tight">
          Augmentez votre visibilité auprès des exposants
        </h1>
        <p className="text-base sm:text-lg lg:text-xl mb-6 sm:mb-10 lg:mb-12 animate-fade-in-delay">
          Rejoignez l'annuaire leader des prestataires événementiels
        </p>
        <button
          onClick={handleButtonClick}
          className="bg-gradient-to-r from-[#007bff] via-[#0056b3] to-[#004094] hover:from-[#0056b3] hover:via-[#004094] hover:to-[#003166] text-white font-bold py-3 px-6 sm:py-4 sm:px-8 rounded-full text-base sm:text-lg lg:text-xl transition-transform duration-500 transform hover:scale-105 animate-bounce-subtle shadow-lg hover:shadow-2xl"
        >
          Inscription gratuite en 1 minute
        </button>
      </div>
    </header>
  );
};

export default PrestatairesHero;
