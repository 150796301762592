import React, { useContext, Suspense, useMemo } from "react";
import { ConfigContext } from "../Configurateur/Contexte";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";

function Model({ modelPath }) {
  const { scene } = useGLTF(modelPath, true);
  return <primitive object={scene} scale={0.5} />;
}

function StandPreview() {
  const { configuration } = useContext(ConfigContext);
  const modelPath = configuration.floorSize ? `/assets3d/${configuration.floorSize}.glb` : null;

  // Définissez la position de la caméra initiale en fonction de la taille sélectionnée
  const cameraPosition = useMemo(() => {
    switch (configuration.floorSize) {
      case "300x300":
        return [0, 2, 5];
      case "400x300":
        return [0, 2.5, 6];
      case "500x500":
        return [0, 3, 7];
      default:
        return [0, 2, 5]; // Position par défaut
    }
  }, [configuration.floorSize]);

  return (
    <div className="p-4 bg-white shadow-md rounded-lg lg:p-6">
      <h2 className="text-xl lg:text-2xl font-bold mb-4 lg:mb-6 text-blue-800">
        Aperçu du Stand 3D
      </h2>

      <div className="stand-preview-container" style={{ height: "400px" }}>
        {modelPath ? (
          <Canvas camera={{ position: cameraPosition }}>
            <ambientLight intensity={0.4} />
            <directionalLight position={[5, 5, 5]} intensity={1} />
            <Suspense fallback={null}>
              <Model modelPath={modelPath} />
            </Suspense>
            <OrbitControls enableZoom={true} />
          </Canvas>
        ) : (
          <p className="text-gray-600">Sélectionnez un format pour afficher le modèle 3D du stand.</p>
        )}
      </div>
    </div>
  );
}

export default StandPreview;
