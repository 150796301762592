import React, { useState } from 'react';

const categoriesData = [
  {
    id: 1,
    title: "Logistique",
    icon: "fa-truck",
    description: "Transport, montage, stockage post-salon",
    services: [
      "Transport et installation",
      "Stockage post-salon",
      "Assistance technique sur place",
      "Démontage et retour logistique",
      "Coordination avec les organisateurs",
      "Autre",
    ],
  },
  {
    id: 2,
    title: "Animation",
    icon: "fa-users",
    description: "Hôtesses, Animateurs, Démonstrateurs",
    services: [
      "Hôtesses d'accueil",
      "Animateurs spécialisés",
      "Présentateurs/conférenciers",
      "Démonstrateurs de produits",
      "Animations interactives",
      "Autre",
    ],
  },
  {
    id: 3,
    title: "E-Marketing",
    icon: "fa-bullhorn",
    description: "Stratégies digitales pour attirer du trafic",
    services: [
      "Campagnes publicitaires sur réseaux sociaux",
      "Landing page événementielle personnalisée",
      "Emailing ciblé",
      "Notifications push",
      "Publicité sur place",
      "Suivi post-salon",
      "Autre",
    ],
  },
  {
    id: 4,
    title: "Traiteurs",
    icon: "fa-glass-martini-alt",
    description: "Service traiteur, mixologie et animations",
    services: [
      "Plateau traiteur et champagne",
      "Barman mixologue",
      "Bar VIP sur le stand",
      "Atelier de découpe de jambon ibérique",
      "Snacking sur stand",
      "Autre",
    ],
  },
  {
    id: 5,
    title: "Communication",
    icon: "fa-paint-brush",
    description: "Supports imprimés et objets publicitaires",
    services: [
      "Impression de brochures et flyers",
      "Cartes de visite personnalisées",
      "Kakémonos et roll-ups",
      "Objets publicitaires",
      "Signalétique directionnelle",
      "Stand d'information numérique",
      "Autre",
    ],
  },
  {
    id: 6,
    title: "Digital",
    icon: "fa-laptop",
    description: "Solutions pour collecter et gérer vos leads facilement.",
    services: [
      "Collecte de données via QR codes",
      "CRM et gestion des leads",
      "Analyse de fréquentation",
      "Tablettes interactives pour collecte d'avis",
      "Rapports de performance post-salon",
      "Autre",
    ],
  },
];

function BosProfilCategories() {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedServices, setSelectedServices] = useState({});
  const [error, setError] = useState("");

  const toggleCategory = (categoryId) => {
    setError("");
    if (selectedCategories.includes(categoryId)) {
      setSelectedCategories(selectedCategories.filter((id) => id !== categoryId));
      setSelectedServices((prev) => {
        const updated = { ...prev };
        delete updated[categoryId];
        return updated;
      });
    } else if (selectedCategories.length < 3) {
      setSelectedCategories([...selectedCategories, categoryId]);
      setSelectedServices((prev) => ({
        ...prev,
        [categoryId]: [],
      }));
    } else {
      setError("Vous ne pouvez sélectionner que 3 catégories maximum");
    }
  };

  const toggleService = (categoryId, service) => {
    setSelectedServices((prev) => {
      const currentServices = prev[categoryId] || [];
      const updatedServices = currentServices.includes(service)
        ? currentServices.filter((s) => s !== service)
        : [...currentServices, service];
      return {
        ...prev,
        [categoryId]: updatedServices,
      };
    });
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-6 text-gray-800 border-b pb-2">
        Dans quelles catégories de l'annuaire des prestataires souhaitez-vous apparaitre ?
      </h2>
      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg">
          {error}
        </div>
      )}
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
        {categoriesData.map((category) => (
          <div
            key={category.id}
            className={`relative p-4 rounded-lg border-2 ${
              selectedCategories.includes(category.id)
                ? "border-indigo-600 bg-indigo-50"
                : "border-gray-200 hover:border-indigo-300"
            }`}
            onClick={() => toggleCategory(category.id)}
          >
            <div className="cursor-pointer">
              <div className="flex items-center gap-3 mb-2">
                <i className={`fas ${category.icon} text-xl text-indigo-600`}></i>
                <h3 className="font-semibold">{category.title}</h3>
              </div>
              <p className="text-sm text-gray-600 mb-2">
                {category.description}
              </p>
            </div>

            {selectedCategories.includes(category.id) && (
              <button
                className="absolute top-2 right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleCategory(category.id);
                }}
              >
                ✕
              </button>
            )}

            {selectedCategories.includes(category.id) && (
              <div className="mt-4 border-t pt-4">
                {category.services.map((service, index) => (
                  <div key={index} className="flex items-center gap-2 mb-2">
                    <input
                      type="checkbox"
                      id={`service-${category.id}-${index}`}
                      checked={selectedServices[category.id]?.includes(service)}
                      onClick={(e) => e.stopPropagation()}
                      onChange={() => toggleService(category.id, service)}
                      className="w-4 h-4 text-indigo-600"
                    />
                    <label
                      htmlFor={`service-${category.id}-${index}`}
                      className="text-sm"
                    >
                      {service}
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="mt-8 flex justify-between">
      </div>
    </div>
  );
}

export default BosProfilCategories;
