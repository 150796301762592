import React, { useState, useEffect } from "react";
import { FaBuilding, FaMapMarkerAlt, FaCity, FaFlag } from "react-icons/fa";

function Screen6Bis({ updateConfig, standConfig }) {
  const [formValues, setFormValues] = useState({
    companyName: standConfig.devis?.companyInfo?.companyName || "", // Ajout de companyName
    projectDetails: standConfig.devis?.projectDetails || "",
    salonDate: standConfig.devis?.salonDate || "",
    address: standConfig.devis?.companyInfo?.address || "",
    postalCode: standConfig.devis?.companyInfo?.postalCode || "",
    city: standConfig.devis?.companyInfo?.city || "",
    country: standConfig.devis?.companyInfo?.country || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Mise à jour uniquement avec les champs valides pour éviter les valeurs vides
  useEffect(() => {
    const filteredFormValues = Object.fromEntries(
      Object.entries(formValues).filter(([_, value]) => value) // Exclure les valeurs vides
    );

    // Mise à jour de standConfig avec companyName
    updateConfig("devis", {
      ...standConfig.devis,
      companyInfo: { ...standConfig.devis?.companyInfo, ...filteredFormValues },
    });
  }, [formValues, updateConfig, standConfig.devis]);

  return (
    <div className="p-6 bg-white rounded-lg shadow-xl max-w-4xl mx-auto">
      <h2 className="text-3xl font-extrabold mb-4 text-blue-800 text-center">
        Complétez les informations de votre structure
      </h2>
      <p className="text-sm text-gray-500 text-center mb-6">
        Remplissez le formulaire pour recevoir jusqu'à 5 devis
      </p>
      
      <form className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="flex flex-col">
          <label htmlFor="companyName" className="text-sm font-medium text-gray-700 flex items-center gap-2">
            <FaBuilding /> Nom de votre structure
          </label>
          <input
            type="text"
            id="companyName"
            name="companyName"
            value={formValues.companyName} // Utilisation de formValues pour companyName
            onChange={handleChange}
            className="mt-1 p-2 border border-blue-400 rounded-md focus:ring-blue-500 focus:border-blue-500 shadow-sm"
            required
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="address" className="text-sm font-medium text-gray-700 flex items-center gap-2">
            <FaMapMarkerAlt /> Adresse
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={formValues.address}
            onChange={handleChange}
            className="mt-1 p-2 border border-blue-400 rounded-md focus:ring-blue-500 focus:border-blue-500 shadow-sm"
            required
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="postalCode" className="text-sm font-medium text-gray-700 flex items-center gap-2">
            <FaCity /> Code postal
          </label>
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            value={formValues.postalCode}
            onChange={handleChange}
            className="mt-1 p-2 border border-blue-400 rounded-md focus:ring-blue-500 focus:border-blue-500 shadow-sm"
            required
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="city" className="text-sm font-medium text-gray-700 flex items-center gap-2">
            <FaCity /> Ville
          </label>
          <input
            type="text"
            id="city"
            name="city"
            value={formValues.city}
            onChange={handleChange}
            className="mt-1 p-2 border border-blue-400 rounded-md focus:ring-blue-500 focus:border-blue-500 shadow-sm"
            required
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="country" className="text-sm font-medium text-gray-700 flex items-center gap-2">
            <FaFlag /> Pays (facultatif)
          </label>
          <input
            type="text"
            id="country"
            name="country"
            value={formValues.country}
            onChange={handleChange}
            className="mt-1 p-2 border border-blue-400 rounded-md focus:ring-blue-500 focus:border-blue-500 shadow-sm"
          />
        </div>
      </form>
    </div>
  );
}

export default Screen6Bis;
