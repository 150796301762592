import React from "react";

function AvisClients() {
  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
          Ce que nos clients disent
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Avis de Marie */}
          <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-2xl transform hover:scale-105 transition duration-300">
            <div className="flex items-center mb-6">
              <img
                src="/avis-clients/avatar-marie.jpg" // Utilisez un chemin absolu depuis le dossier public
                alt="Marie D."
                className="w-20 h-20 rounded-full border-4 border-blue-500 object-cover"
              />
              <div className="ml-4">
                <p className="font-bold text-xl text-gray-800">Marie D.</p>
                <p className="text-gray-500">Vinci Environnement</p>
              </div>
            </div>
            <p className="text-gray-600 italic mb-6">
              "Configurateur de stands simple et rapide a tester absolument si vous préparez un salon."
            </p>
            <div className="flex space-x-1">
              {[...Array(5)].map((_, index) => (
                <i key={index} className="fas fa-star text-yellow-400 text-2xl"></i>
              ))}
            </div>
          </div>

          {/* Avis de Pierre */}
          <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-2xl transform hover:scale-105 transition duration-300">
            <div className="flex items-center mb-6">
              <img
                src="/avis-clients/avatar-pierre.jpg" // Utilisez un chemin absolu depuis le dossier public
                alt="Pierre L."
                className="w-20 h-20 rounded-full border-4 border-blue-500 object-cover"
              />
              <div className="ml-4">
                <p className="font-bold text-xl text-gray-800">Pierre L.</p>
                <p className="text-gray-500">Doctolib</p>
              </div>
            </div>
            <p className="text-gray-600 italic mb-6">
              "Outils trés facile à utiliser et en plus Gratuit !"" "
            </p>
            <div className="flex space-x-1">
              {[...Array(4)].map((_, index) => (
                <i key={index} className="fas fa-star text-yellow-400 text-2xl"></i>
              ))}
              <i className="fas fa-star-half-alt text-yellow-400 text-2xl"></i>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AvisClients;
