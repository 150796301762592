import React from 'react';

function Contact() {
  const [showChat, setShowChat] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Message envoyé!');
  };

  return (
    <div className="min-h-screen bg-white font-sans">
      {/* Bannière de contact avec fond blanc */}
      <div className="relative h-48 md:h-30 bg-white flex items-center justify-center">
        <h1 className="text-4xl font-bold text-black tracking-wide">Contactez-nous</h1>
      </div>

      {/* Contenu principal */}
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          
          {/* Formulaire de contact */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="bg-blue-600 text-white py-4 px-6">
              <h2 className="text-2xl font-semibold">Envoyez-nous un message</h2>
            </div>
            <form className="px-6 py-8" onSubmit={handleSubmit}>
              <div className="mb-6">
                <input
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="name"
                  type="text"
                  placeholder="Nom"
                  required
                />
              </div>
              <div className="mb-6">
                <input
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="email"
                  type="email"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="mb-6">
                <input
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="subject"
                  type="text"
                  placeholder="Sujet"
                />
              </div>
              <div className="mb-6">
                <textarea
                  className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  name="message"
                  placeholder="Message"
                  rows="4"
                  required
                ></textarea>
              </div>
              <div>
                <button
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  type="submit"
                >
                  Envoyer le message
                </button>
              </div>
            </form>
          </div>

          {/* Informations de contact et options supplémentaires */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="bg-blue-600 text-white py-4 px-6">
              <h2 className="text-2xl font-semibold">Informations de contact</h2>
            </div>
            <div className="px-6 py-8">
              <p className="mb-4 flex items-center text-gray-600">
                <i className="fas fa-phone mr-3 text-blue-600"></i> (+33) 06 61 18 01 15
              </p>
              <p className="mb-6 flex items-center text-gray-600">
                <i className="fas fa-envelope mr-3 text-blue-600"></i> contact@realitim.com
              </p>

              <h3 className="text-xl font-bold mb-4 text-blue-600">Service client</h3>
              <button
                onClick={() => setShowChat(!showChat)}
                className="w-full mb-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <i className="fas fa-comments mr-2"></i> Démarrer le chat en direct
              </button>
              <button className="w-full bg-blue-400 hover:bg-blue-500 text-white font-bold py-3 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400">
                <i className="fas fa-calendar-alt mr-2"></i> Réserver un appel
              </button>

              {showChat && (
                <div className="mt-6 p-4 border-2 border-blue-200 rounded-md bg-blue-50">
                  <p className="text-blue-800">La fonctionnalité de chat en direct serait implémentée ici.</p>
                </div>
              )}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Contact;
