import React, { useState, useEffect } from 'react';
import OeStart from './OeStart';
import OeInscription from './OeInscription';
import OeVerif from './OeVerif';
import OeSuccess from './OeSuccess';
import { useNavigate } from 'react-router-dom';

const MainOe = () => {
  const [step, setStep] = useState(1);
  const [isGoogleAuthenticated, setIsGoogleAuthenticated] = useState(false); // Nouvel état pour l'authentification Google
  const [formData, setFormData] = useState({
    siret: "",
    contactEmail: "",
    contactPhone: "",
    verificationCode: "",
    logo: null,
    website: "",
    address: "",
  });

  const navigate = useNavigate();

  // Fonction pour passer à l'étape suivante
  const nextStep = () => setStep(step + 1);

  // Fonction pour revenir à l'étape précédente
  const prevStep = () => setStep(step - 1);

  // Simulation de l'authentification Google, à remplacer par l'authentification réelle
  useEffect(() => {
    // Ici, vous pourriez vérifier si l'utilisateur est connecté via Google
    // Par exemple, setIsGoogleAuthenticated(true) si l'utilisateur est authentifié via Google
  }, []);

  // Passer directement à l'étape suivante si l'utilisateur est authentifié par Google
  useEffect(() => {
    if (isGoogleAuthenticated) {
      setStep(3); // Passer directement à l'étape 3, en sautant la vérification email
    }
  }, [isGoogleAuthenticated]);

  useEffect(() => {
    if (step === 5) {
      const timer = setTimeout(() => {
        navigate('/dashboard');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [step, navigate]);

  // Gestion des étapes du formulaire
  switch (step) {
    case 1:
      return <OeStart nextStep={nextStep} />;
    case 2:
      return (
        <OeInscription 
          nextStep={nextStep} 
          prevStep={prevStep} 
          formData={formData} 
          setFormData={setFormData} 
        />
      );
    case 3:
      return (
        <OeVerif 
          nextStep={nextStep} 
          prevStep={prevStep} 
          formData={formData} 
          setFormData={setFormData} 
        />
      );
    case 4:
      return <OeSuccess />;
    default:
      return <OeStart nextStep={nextStep} />;
  }
};

export default MainOe;
