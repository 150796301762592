"use client"; 
import React from "react";
import Header from "../Site/Accueil/Header"; // Chemin vers le Header
import Recherche from "../Demandes/Recherche"; // Chemin vers le composant Recherche
import Footer from "../Site/Accueil/Footer"; // Chemin vers le Footer

function MainRecherche() {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Header */}
      <Header />

      {/* Contenu principal */}
      <main className="flex-grow">
        <Recherche />
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default MainRecherche;
