// src/Formules/CancelPage/MainCancelPage.js
import React from "react";
import CancelPage from "./CancelPage"; // Chemin : src/Formules/CancelPage/CancelPage.js
import Header from "../../Site/Accueil/Header"; // Chemin : src/Site/Accueil/Header.js
import Footer from "../../Site/Accueil/Footer"; // Chemin : src/Site/Accueil/Footer.js

function MainCancelPage() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <CancelPage />
      </main>
      <Footer />
    </div>
  );
}

export default MainCancelPage;
