import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Ensure priceList is declared only onceA
const priceList = [
  { category: "Décoration", name: "Cafetière Nespresso", size: "Standard", price: 50 },
  { category: "Décoration", name: "Plante colonne", size: "100", price: 30 },
  { category: "Décoration", name: "Plante colonne", size: "150", price: 40 },
  { category: "Décoration", name: "Plante colonne", size: "200", price: 50 },
  { category: "Décoration", name: "Arbre", size: "50*100", price: 50 },
  { category: "Décoration", name: "Arbre", size: "100*200", price: 70 },
  { category: "Décoration", name: "Arbre", size: "150*250", price: 100 },
  { category: "Décoration", name: "Mur végétal", size: "100*250", price: 1200 },
  { category: "Décoration", name: "Mur végétal", size: "200*250", price: 1500 },
  { category: "Décoration", name: "Mur végétal", size: "300*250", price: 1700 },
  { category: "Décoration", name: "Panneau mural bois", size: "100*250", price: 600 },
  { category: "Décoration", name: "Panneau mural bois", size: "200*250", price: 1200 },
  { category: "Décoration", name: "Panneau mural bois", size: "300*250", price: 1800 },
  { category: "Digital", name: "StandVR", size: "90*190", price: 990 },
  { category: "Digital", name: "Écran mural", size: "43\" 100*60", price: 100 },
  { category: "Digital", name: "Écran mural", size: "55\" 125*75", price: 135 },
  { category: "Digital", name: "Écran mural", size: "73\" 222*125", price: 150 },
  { category: "Digital", name: "Écran sur pied", size: "43\" Hauteur 190", price: 140 },
  { category: "Digital", name: "Écran sur pied", size: "55\" Hauteur 190", price: 200 },
  { category: "Digital", name: "Écran sur pied", size: "73\" Hauteur 190", price: 400 },
  { category: "Digital", name: "Totem vidéo", size: "43\" Hauteur 190", price: 250 },
  { category: "Digital", name: "Totem vidéo", size: "55\" Hauteur 190", price: 350 },
  { category: "Digital", name: "Totem vidéo", size: "65\" Hauteur 190", price: 450 },
  { category: "Digital", name: "Borne interactive", size: "Tablette", price: 120 },
  { category: "Digital", name: "Borne interactive", size: "Ecran 43\"", price: 300 },
  { category: "Digital", name: "Borne interactive", size: "Ecran 55\"", price: 400 },
  { category: "Digital", name: "Tablette", size: "Standard", price: 60 },
  { category: "Digital", name: "Ordinateur portable", size: "Standard", price: 100 },
  { category: "Enseignes Suspendues", name: "Enseigne Suspendue Ronde", size: "100", price: 300 },
  { category: "Enseignes Suspendues", name: "Enseigne Suspendue Ronde", size: "140", price: 300 },
  { category: "Enseignes Suspendues", name: "Enseigne Suspendue Ronde", size: "200", price: 300 },
  { category: "Enseignes Suspendues", name: "Enseigne Suspendue Carrée", size: "100", price: 280 },
  { category: "Enseignes Suspendues", name: "Enseigne Suspendue Carrée", size: "140", price: 280 },
  { category: "Enseignes Suspendues", name: "Enseigne Suspendue Carrée", size: "200", price: 280 },
  { category: "Éclairages", name: "Lustre Leds", size: "100", price: 250 },
  { category: "Éclairages", name: "Lustre Leds", size: "150", price: 300 },
  { category: "Éclairages", name: "Lustre Leds", size: "200", price: 350 },
  { category: "Éclairages", name: "Applique murale", size: "50", price: 40 },
  { category: "Mobilier", name: "Comptoir droit", size: "90", price: 120 },
  { category: "Mobilier", name: "Comptoir droit", size: "100", price: 140 },
  { category: "Mobilier", name: "Comptoir droit", size: "120", price: 160 },
  { category: "Mobilier", name: "Comptoir droit", size: "200", price: 220 },
  { category: "Mobilier", name: "Comptoire courbe", size: "90", price: 120 },
  { category: "Mobilier", name: "Comptoire courbe", size: "100", price: 140 },
  { category: "Mobilier", name: "Comptoire courbe", size: "120", price: 160 },
  { category: "Mobilier", name: "Mange-debout", size: "2 chaises, table 90", price: 80 },
  { category: "Mobilier", name: "Mange-debout", size: "3 chaises, table 100", price: 100 },
  { category: "Mobilier", name: "Mange-debout", size: "3 chaises, table 120", price: 120 },
  { category: "Mobilier", name: "Commode", size: "80", price: 70 },
  { category: "Mobilier", name: "Commode", size: "100", price: 70 },
  { category: "Mobilier", name: "Commode", size: "200", price: 70 },
  { category: "Mobilier", name: "Fauteuil", size: "100", price: 130 },
  { category: "Mobilier", name: "Banquette", size: "100", price: 150 },
  { category: "Mobilier", name: "Banquette", size: "150", price: 200 },
  { category: "Mobilier", name: "Banquette", size: "200", price: 250 },
  { category: "Mobilier", name: "Table basse", size: "100", price: 35 },
  { category: "Mobilier", name: "Table basse", size: "150", price: 35 },
  { category: "Mobilier", name: "Table basse", size: "200", price: 35 },
  { category: "Mobilier", name: "Etagère", size: "80", price: 100 },
  { category: "Mobilier", name: "Etagère", size: "100", price: 120 },
  { category: "Mobilier", name: "Etagère", size: "200", price: 140 },
  { category: "Mobilier", name: "Colonne carrée", size: "80", price: 80 },
  { category: "Mobilier", name: "Colonne carrée", size: "100", price: 100 },
  { category: "Mobilier", name: "Colonne carrée", size: "120", price: 150 },
  { category: "Mobilier", name: "Colonne ronde", size: "80", price: 60 },
  { category: "Mobilier", name: "Colonne ronde", size: "100", price: 80 },
  { category: "Mobilier", name: "Colonne ronde", size: "120", price: 100 },
  { category: "Signaletique", name: "Présentoire à documents", size: "Document A4", price: 80 },
  { category: "Signaletique", name: "Présentoire à documents", size: "50*150", price: 120 },
  { category: "Signaletique", name: "Présentoire à documents", size: "100*150", price: 150 },
  { category: "Signaletique", name: "Oriflamme Arrondie", size: "300", price: 180 },
  { category: "Signaletique", name: "Oriflamme Carrée", size: "300", price: 180 },
  { category: "Signaletique", name: "Kakémono", size: "85*200", price: 100 },
  { category: "Signaletique", name: "Kakémono", size: "100*200", price: 130 },
  { category: "Signaletique", name: "Kakémono", size: "150*200", price: 180 },
  { category: "Signaletique", name: "Cadre portait", size: "25*50", price: 60 },
  { category: "Signaletique", name: "Cadre portait", size: "50*100", price: 100 },
  { category: "Signaletique", name: "Cadre portait", size: "100*200", price: 150 },
  { category: "Signaletique", name: "Cadre Paysage", size: "25*50", price: 60 },
  { category: "Signaletique", name: "Cadre Paysage", size: "50*100", price: 100 },
  { category: "Signaletique", name: "Cadre Paysage", size: "100*200", price: 150 },
  { category: "Signaletique", name: "Stand Parapluie droit", size: "100*250", price: 150 },
  { category: "Signaletique", name: "Stand Parapluie droit", size: "200*250", price: 150 },
  { category: "Signaletique", name: "Stand Parapluie droit", size: "400*250", price: 150 },
  { category: "Signaletique", name: "Stand parapluie courbé", size: "200*250", price: 180 },
  { category: "Signaletique", name: "Stand parapluie courbé", size: "300*250", price: 180 },
  { category: "Signaletique", name: "Stand parapluie courbé", size: "400*250", price: 180 },
  { category: "Structures", name: "Cloison", size: "100*250", price: 300 },
  { category: "Structures", name: "Cloison", size: "200*250", price: 500 },
  { category: "Structures", name: "Cloison", size: "300*250", price: 900 },
  { category: "Structures", name: "Cloison", size: "400*250", price: 550 },
  { category: "Structures", name: "Cloison", size: "500*250", price: 700 },
  { category: "Structures", name: "Cloison", size: "600*250", price: 950 },
  { category: "Structures", name: "Arche", size: "100*250*100", price: 400 },
  { category: "Structures", name: "Arche", size: "100*250*200", price: 600 },
  { category: "Structures", name: "Arche", size: "100*250*300", price: 800 },
  { category: "Structures", name: "Vestiaire", size: "100*100*250", price: 300 },
  { category: "Décoration", name: "Cafetière Nespresso", size: "Standard", price: 50 },
  { category: "Décoration", name: "Plante colonne", size: "100", price: 30 },
  { category: "Décoration", name: "Plante colonne", size: "150", price: 40 },
  { category: "Décoration", name: "Plante colonne", size: "200", price: 50 },
  { category: "Décoration", name: "Arbre", size: "50*100", price: 50 },
  { category: "Décoration", name: "Arbre", size: "100*200", price: 70 },
  { category: "Décoration", name: "Arbre", size: "150*250", price: 100 },
  { category: "Décoration", name: "Mur végétal", size: "100*250", price: 1200 },
  { category: "Décoration", name: "Mur végétal", size: "200*250", price: 1500 },
  { category: "Décoration", name: "Mur végétal", size: "300*250", price: 1700 },
  { category: "Décoration", name: "Panneau mural bois", size: "100*250", price: 600 },
  { category: "Décoration", name: "Panneau mural bois", size: "200*250", price: 1200 },
  { category: "Décoration", name: "Panneau mural bois", size: "300*250", price: 1800 },
  { category: "Digital", name: "StandVR", size: "90*190", price: 990 },
  { category: "Digital", name: "Écran mural", size: "43\" 100*60", price: 100 },
  { category: "Digital", name: "Écran mural", size: "55\" 125*75", price: 135 },
  { category: "Digital", name: "Écran mural", size: "73\" 222*125", price: 150 },
  { category: "Digital", name: "Écran sur pied", size: "43\" Hauteur 190", price: 140 },
  { category: "Digital", name: "Écran sur pied", size: "55\" Hauteur 190", price: 200 },
  { category: "Digital", name: "Écran sur pied", size: "73\" Hauteur 190", price: 400 },
  { category: "Digital", name: "Totem vidéo", size: "43\" Hauteur 190", price: 250 },
  { category: "Digital", name: "Totem vidéo", size: "55\" Hauteur 190", price: 350 },
  { category: "Digital", name: "Totem vidéo", size: "65\" Hauteur 190", price: 450 },
  { category: "Digital", name: "Borne interactive", size: "Tablette", price: 120 },
  { category: "Digital", name: "Borne interactive", size: "Ecran 43\"", price: 300 },
  { category: "Digital", name: "Borne interactive", size: "Ecran 55\"", price: 400 },
  { category: "Digital", name: "Tablette", size: "Standard", price: 60 },
  { category: "Digital", name: "Ordinateur portable", size: "Standard", price: 100 },
  { category: "Enseignes Suspendues", name: "Enseigne Suspendue Ronde", size: "100", price: 300 },
  { category: "Enseignes Suspendues", name: "Enseigne Suspendue Ronde", size: "140", price: 300 },
  { category: "Enseignes Suspendues", name: "Enseigne Suspendue Ronde", size: "200", price: 300 },
  { category: "Enseignes Suspendues", name: "Enseigne Suspendue Carrée", size: "100", price: 280 },
  { category: "Enseignes Suspendues", name: "Enseigne Suspendue Carrée", size: "140", price: 280 },
  { category: "Enseignes Suspendues", name: "Enseigne Suspendue Carrée", size: "200", price: 280 },
  { category: "Éclairages", name: "Lustre Leds", size: "100", price: 250 },
  { category: "Éclairages", name: "Lustre Leds", size: "150", price: 300 },
  { category: "Éclairages", name: "Lustre Leds", size: "200", price: 350 },
  { category: "Éclairages", name: "Applique murale", size: "50", price: 40 },
  { category: "Mobilier", name: "Comptoir droit", size: "90", price: 120 },
  { category: "Mobilier", name: "Comptoir droit", size: "100", price: 140 },
  { category: "Mobilier", name: "Comptoir droit", size: "120", price: 160 },
  { category: "Mobilier", name: "Comptoir droit", size: "200", price: 220 },
  { category: "Mobilier", name: "Comptoire courbe", size: "90", price: 120 },
  { category: "Mobilier", name: "Comptoire courbe", size: "100", price: 140 },
  { category: "Mobilier", name: "Comptoire courbe", size: "120", price: 160 },
  { category: "Mobilier", name: "Mange-debout", size: "2 chaises, table 90", price: 80 },
  { category: "Mobilier", name: "Mange-debout", size: "3 chaises, table 100", price: 100 },
  { category: "Mobilier", name: "Mange-debout", size: "3 chaises, table 120", price: 120 },
  { category: "Mobilier", name: "Commode", size: "80", price: 70 },
  { category: "Mobilier", name: "Commode", size: "100", price: 70 },
  { category: "Mobilier", name: "Commode", size: "200", price: 70 },
  { category: "Mobilier", name: "Fauteuil", size: "100", price: 130 },
  { category: "Mobilier", name: "Banquette", size: "100", price: 150 },
  { category: "Mobilier", name: "Banquette", size: "150", price: 200 },
  { category: "Mobilier", name: "Banquette", size: "200", price: 250 },
  { category: "Mobilier", name: "Table basse", size: "100", price: 35 },
  { category: "Mobilier", name: "Table basse", size: "150", price: 35 },
  { category: "Mobilier", name: "Table basse", size: "200", price: 35 },
  { category: "Mobilier", name: "Etagère", size: "80", price: 100 },
  { category: "Mobilier", name: "Etagère", size: "100", price: 120 },
  { category: "Mobilier", name: "Etagère", size: "200", price: 140 },
  { category: "Mobilier", name: "Colonne carrée", size: "80", price: 80 },
  { category: "Mobilier", name: "Colonne carrée", size: "100", price: 100 },
  { category: "Mobilier", name: "Colonne carrée", size: "120", price: 150 },
  { category: "Mobilier", name: "Colonne ronde", size: "80", price: 60 },
  { category: "Mobilier", name: "Colonne ronde", size: "100", price: 80 },
  { category: "Mobilier", name: "Colonne ronde", size: "120", price: 100 },
  { category: "Signaletique", name: "Présentoire à documents", size: "Document A4", price: 80 },
  { category: "Signaletique", name: "Présentoire à documents", size: "50*150", price: 120 },
  { category: "Signaletique", name: "Présentoire à documents", size: "100*150", price: 150 },
  { category: "Signaletique", name: "Oriflamme Arrondie", size: "300", price: 180 },
  { category: "Signaletique", name: "Oriflamme Carrée", size: "300", price: 180 },
  { category: "Signaletique", name: "Kakémono", size: "85*200", price: 100 },
  { category: "Signaletique", name: "Kakémono", size: "100*200", price: 130 },
  { category: "Signaletique", name: "Kakémono", size: "150*200", price: 180 },
  { category: "Signaletique", name: "Cadre portait", size: "25*50", price: 60 },
  { category: "Signaletique", name: "Cadre portait", size: "50*100", price: 100 },
  { category: "Signaletique", name: "Cadre portait", size: "100*200", price: 150 },
  { category: "Signaletique", name: "Cadre Paysage", size: "25*50", price: 60 },
  { category: "Signaletique", name: "Cadre Paysage", size: "50*100", price: 100 },
  { category: "Signaletique", name: "Cadre Paysage", size: "100*200", price: 150 },
  { category: "Signaletique", name: "Stand Parapluie droit", size: "100*250", price: 150 },
  { category: "Signaletique", name: "Stand Parapluie droit", size: "200*250", price: 150 },
  { category: "Signaletique", name: "Stand Parapluie droit", size: "400*250", price: 150 },
  { category: "Signaletique", name: "Stand parapluie courbé", size: "200*250", price: 180 },
  { category: "Signaletique", name: "Stand parapluie courbé", size: "300*250", price: 180 },
  { category: "Signaletique", name: "Stand parapluie courbé", size: "400*250", price: 180 },
  { category: "Structures", name: "Cloison", size: "100*250", price: 300 },
  { category: "Structures", name: "Cloison", size: "200*250", price: 500 },
  { category: "Structures", name: "Cloison", size: "300*250", price: 900 },
  { category: "Structures", name: "Cloison", size: "400*250", price: 550 },
  { category: "Structures", name: "Cloison", size: "500*250", price: 700 },
  { category: "Structures", name: "Cloison", size: "600*250", price: 950 },
  { category: "Structures", name: "Arche", size: "100*250*100", price: 400 },
  { category: "Structures", name: "Arche", size: "100*250*200", price: 600 },
  { category: "Structures", name: "Arche", size: "100*250*300", price: 800 },
  { category: "Structures", name: "Vestiaire", size: "100*100*250", price: 300 },
  ];

  function Screen5({ standConfig = { elements: [], services: [] }, updateConfig }) {
    const navigate = useNavigate();
    const [elements, setElements] = useState(
      (standConfig.elements || []).map((element) => {
        const matchingPrice = priceList.find(
          (item) => item.name === element.name && (item.size === element.size || item.size === "")
        );
        return {
          ...element,
          price: matchingPrice ? matchingPrice.price : element.price || 0,
        };
      })
    );
  
    const [services, setServices] = useState(standConfig.services || []);
    const previousTotalCost = useRef(null);
  
    const totalCost = useMemo(() => {
      const elementsCost = elements.reduce((sum, element) => sum + (element.price || 0), 0);
      const servicesCost = services.reduce((sum, service) => sum + (service.price || 0), 0);
      return elementsCost + servicesCost;
    }, [elements, services]);
  
    const stableUpdateConfig = useCallback((key, value) => {
      if (typeof updateConfig === 'function') {
        updateConfig(key, value);
      } else {
        console.warn("La fonction updateConfig n'est pas définie ou n'est pas une fonction.");
      }
    }, [updateConfig]);
  
    useEffect(() => {
      if (previousTotalCost.current !== totalCost) {
        previousTotalCost.current = totalCost;
        stableUpdateConfig("totalCost", totalCost);
      }
    }, [totalCost, stableUpdateConfig]);
  
    const handleAddService = useCallback((service) => {
      const updatedServices = [...services, service];
      setServices(updatedServices);
      stableUpdateConfig("services", updatedServices);
    }, [services, stableUpdateConfig]);
  
    const handleRemoveService = useCallback((index) => {
      const updatedServices = services.filter((_, i) => i !== index);
      setServices(updatedServices);
      stableUpdateConfig("services", updatedServices);
    }, [services, stableUpdateConfig]);
  
    const handleRemoveElement = useCallback((index) => {
      const updatedElements = elements.filter((_, i) => i !== index);
      setElements(updatedElements);
      stableUpdateConfig("elements", updatedElements);
    }, [elements, stableUpdateConfig]);
  
    const handleReturn = () => {
      navigate("/espace-exposant");
    };
  
    return (
      <div className="min-h-screen w-full py-8">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <div className="bg-white rounded-2xl shadow-xl p-6">
              <div className="mb-8">
                <h2 className="text-3xl font-bold text-blue-800 mb-2 text-center">
                  Estimation des Coûts du Stand
                </h2>
                <p className="text-gray-500 text-center">
                  Voici une estimation des coûts prévisionnels de votre stand.
                </p>
              </div>
  
              {/* Tableau des éléments */}
              <div className="bg-white rounded-lg shadow mb-6">
                <h3 className="text-lg font-semibold text-blue-700 p-4">Éléments Sélectionnés</h3>
                <div className="overflow-x-auto">
                  <table className="min-w-full">
                    <thead>
                      <tr className="bg-gray-50">
                        <th className="py-3 px-4 text-left text-sm font-semibold text-gray-700 border-b">Élément</th>
                        <th className="py-3 px-4 text-right text-sm font-semibold text-gray-700 border-b">Prix (EUR)</th>
                        <th className="py-3 px-4 text-center text-sm font-semibold text-gray-700 border-b">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {elements.map((element, index) => (
                        <tr key={index} className="border-b hover:bg-gray-50 transition-colors">
                          <td className="py-3 px-4 text-gray-800">{element.name}</td>
                          <td className="py-3 px-4 text-right text-gray-800">
                            {element.price ? element.price.toFixed(2) : 'N/A'}
                          </td>
                          <td className="py-3 px-4 text-center">
                            <button
                              onClick={() => handleRemoveElement(index)}
                              className="text-red-600 hover:text-red-800 transition-colors duration-200 px-3 py-1 rounded-md hover:bg-red-50"
                            >
                              Supprimer
                            </button>
                          </td>
                        </tr>
                      ))}
                      {elements.length === 0 && (
                        <tr>
                          <td colSpan={4} className="py-4 text-center text-gray-500">
                            Aucun élément ajouté au stand
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
  
              {/* Tableau des services */}
              <div className="bg-white rounded-lg shadow mb-6">
                <h3 className="text-lg font-semibold text-blue-700 p-4">Services Sélectionnés</h3>
                <div className="overflow-x-auto">
                  <table className="min-w-full">
                    <thead>
                      <tr className="bg-gray-50">
                        <th className="py-3 px-4 text-left text-sm font-semibold text-gray-700 border-b">Service</th>
                        <th className="py-3 px-4 text-right text-sm font-semibold text-gray-700 border-b">Prix (EUR)</th>
                        <th className="py-3 px-4 text-center text-sm font-semibold text-gray-700 border-b">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {services.map((service, index) => (
                        <tr key={index} className="border-b hover:bg-gray-50 transition-colors">
                          <td className="py-3 px-4 text-gray-800">{service.name}</td>
                          <td className="py-3 px-4 text-right text-gray-800">
                            {service.price ? service.price.toFixed(2) : 'N/A'}
                          </td>
                          <td className="py-3 px-4 text-center">
                            <button
                              onClick={() => handleRemoveService(index)}
                              className="text-red-600 hover:text-red-800 transition-colors duration-200 px-3 py-1 rounded-md hover:bg-red-50"
                            >
                              Supprimer
                            </button>
                          </td>
                        </tr>
                      ))}
                      {services.length === 0 && (
                        <tr>
                          <td colSpan={4} className="py-4 text-center text-gray-500">
                            Aucun service sélectionné
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
  
              {/* Coût Total Estimé */}
              <div className="space-y-6">
                <div className="flex justify-between items-center bg-blue-50 p-4 rounded-lg">
                  <span className="text-lg font-semibold text-blue-900">Coût Total Estimé* :</span>
                  <span className="text-2xl font-bold text-blue-800">
                    {totalCost.toFixed(2)} EUR
                  </span>
                </div>
                <p className="text-xs text-gray-500 text-center italic">
                  * Cette estimation est non contractuelle et nécessite d'être ajustée 
                  par un standiste, dans le cadre d'une demande de devis.
                </p>
                <div className="flex justify-center">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
  
  export default Screen5;