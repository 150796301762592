import React, { useState, useEffect } from "react";
import { FaCalendarAlt, FaBuilding, FaRegFileAlt } from "react-icons/fa";

function Screen6({ updateConfig, standConfig }) {
  const [formValues, setFormValues] = useState({
    standName: standConfig.devis?.standName || "",
    contactName: standConfig.devis?.contactName || "",
    email: standConfig.devis?.email || "",
    phone: standConfig.devis?.phone || "",
    projectDetails: standConfig.devis?.projectDetails || "",
    salonDate: standConfig.devis?.salonDate || "",
  });

  // Mise à jour une fois au montage uniquement si standName est vide
  useEffect(() => {
    if (!formValues.standName) {
      setFormValues((prevValues) => ({
        ...prevValues,
        standName: `Stand${Math.floor(Math.random() * 1000)}`, // Nom aléatoire par défaut
      }));
    }
  }, []); 

  useEffect(() => {
    // Vérifier que `formValues` a changé avant d'appeler `updateConfig`
    if (JSON.stringify(formValues) !== JSON.stringify(standConfig.devis)) {
      updateConfig("devis", formValues);
    }
  }, [formValues, updateConfig, standConfig.devis]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-xl max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-6">
      <h2 className="col-span-1 md:col-span-2 text-3xl font-extrabold mb-2 text-blue-800 text-center">
        Félicitations, votre stand est magnifique !
      </h2>
      
      <form className="col-span-1 md:col-span-2 space-y-6">
        <div className="flex flex-col">
          <label htmlFor="standName" className="text-sm font-medium text-gray-700">
            Donnez un nom à votre stand :
          </label>
          <input
            type="text"
            id="standName"
            name="standName"
            value={formValues.standName}
            onChange={handleChange}
            className="mt-1 p-2 border border-blue-400 rounded-md focus:ring-blue-500 focus:border-blue-500 shadow-sm"
            placeholder={`Stand${formValues.standName}`}
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="salonDate" className="text-sm font-medium text-gray-700 flex items-center gap-2">
            <FaCalendarAlt /> Date de votre événement
          </label>
          <input
            type="date"
            id="salonDate"
            name="salonDate"
            value={formValues.salonDate}
            onChange={handleChange}
            className="mt-1 p-2 border border-blue-400 rounded-md focus:ring-blue-500 focus:border-blue-500 shadow-sm"
            required
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="projectDetails" className="text-sm font-medium text-gray-700 flex items-center gap-2">
            <FaRegFileAlt /> Dites-nous tout sur votre projet!
          </label>
          <textarea
            id="projectDetails"
            name="projectDetails"
            value={formValues.projectDetails}
            onChange={handleChange}
            className="mt-1 p-2 border border-blue-400 rounded-md focus:ring-blue-500 focus:border-blue-500 shadow-sm"
            rows="5"
            placeholder="Décrivez votre projet, les besoins spécifiques, etc."
          />
        </div>
      </form>
    </div>
  );
}

export default Screen6;
