import React, { useState, useContext } from "react";
import { ConfigContext } from "../Contexte";

function Screen4() {
  const { configuration, setConfiguration, updateConfig } = useContext(ConfigContext);
  const categories = [
    { name: "Sols", icon: "fa-square" },
    { name: "Structures", icon: "fa-cubes" },
    { name: "Mobilier", icon: "fa-chair" },
    { name: "Signaletique", icon: "fa-sign" },
    { name: "Enseignes Suspendues", icon: "fa-tag" },
    { name: "Décoration", icon: "fa-paint-brush" },
    { name: "Éclairages", icon: "fa-lightbulb" },
    { name: "Digital", icon: "fa-laptop" },
  ];
  
  const elementCustomizations = {
    "Cafetière Nespresso": { color: false, image: false },
    "Plante colonne": { color: true, image: false },
    "Arbre": { color: true, image: false },
    "Mur végétal": { color: true, image: false },
    "Panneau mural bois": { color: true, image: false },
    "StandVR": { color: false, image: false },
    "Écran mural": { color: false, image: true },
    "Écran sur pied": { color: false, image: true },
    "Totem vidéo": { color: false, image: true },
    "Borne interactive": { color: false, image: true },
    "Tablette": { color: false, image: true },
    "Ordinateur portable": { color: false, image: true },
    "Enseigne Suspendue Ronde": { color: true, image: true },
    "Enseigne Suspendue Carrée": { color: true, image: true },
    "Lustre leds": { color: true, image: false },
    "Applique murale": { color: true, image: false },
    "Comptoir droit": { color: true, image: true },
    "Comptoir courbe": { color: true, image: true },
    "Mange-debout": { color: true, image: false },
    "Commode": { color: true, image: false },
    "Fauteuil design assorti": { color: true, image: false },
    "Banquette design assortie": { color: true, image: false },
    "Table basse": { color: true, image: false },
    "Étagère": { color: true, image: false },
    "Colonne carrée": { color: true, image: false },
    "Colonne ronde": { color: true, image: false },
    "Présentoire à documents": { color: false, image: true },
    "Oriflamme Arrondie": { color: false, image: true },
    "Oriflamme Carrée": { color: false, image: true },
    "Kakémono": { color: false, image: true },
    "Cadre portrait": { color: false, image: true },
    "Cadre paysage": { color: false, image: true },
    "Stand Parapluie droit": { color: false, image: true },
    "Stand parapluie courbé": { color: false, image: true },
    "Cloison": { color: true, image: false },
    "Arche": { color: true, image: false },
    "Vestiaire": { color: true, image: false },
  };
  
  const elementSizes = {
    "Cafetière Nespresso": ["Standard"], 
    "Plante colonne": ["100", "150", "200"],
    "Arbre": ["50*100", "100*200", "150*250"],
    "Mur végétal": ["100*250", "200*250", "300*250"],
    "Panneau mural bois": ["100*250", "200*250", "300*250"],
    "StandVR": ["90*190"],
    "Écran mural": ["43\" 100*60", "55\" 125*75", "73\" 222*125"],
    "Écran sur pied": ["43\" Hauteur 190", "55\" Hauteur 190", "73\" Hauteur 190"],
    "Totem vidéo": ["43\" Hauteur 190", "55\" Hauteur 190", "65\" Hauteur 190"],
    "Borne interactive": ["Tablette", "Ecran 43\"", "Ecran 55\""],
    "Tablette": ["Standard"],
    "Ordinateur portable": ["Standard"],
    "Enseigne Suspendue Ronde": ["100", "140", "200"],
    "Enseigne Suspendue Carrée": ["100", "140", "200"],
    "Lustre Led": ["100", "150", "200"],
    "Applique murale": ["50"],
    "Comptoir droit": ["90", "100", "120", "200"],
    "Comptoir courbe": ["90", "100", "120"],
    "Mange-debout": ["2 chaises, table 90", "3 chaises, table 100", "3 chaises, table 120"],
    "Commode": ["80", "100", "200"],
    "Fauteuil": ["100"],
    "Banquette": ["100", "150", "200"],
    "Table basse": ["100", "150", "200"],
    "Étagère": ["80", "100", "200"],
    "Colonne carrée": ["80", "100", "120"],
    "Colonne ronde": ["80", "100", "120"],
    "Présentoire à documents": ["Document A4", "50*150", "100*150"],
    "Oriflamme Arrondie": ["300"],
    "Oriflamme Carrée": ["300"],
    "Kakémono": ["85*200", "100*200", "150*200"],
    "Cadre portait": ["25*50", "50*100", "100*200"],
    "Cadre paysage": ["25*50", "50*100", "100*200"],
    "Stand Parapluie droit": ["100*250", "200*250", "400*250"],
    "Stand parapluie courbé": ["200*250", "300*250", "400*250"],
    "Cloison": ["100*250", "200*250", "300*250", "400*250", "500*250", "600*250"],
    "Arche": ["100*250*100", "100*250*200", "100*250*300"],
    "Vestiaire": ["100*100*250", "100*200*250", "100*300*250"],
    "Moquette": ["Surface du stand"],
    "Parquet": ["Surface du stand"],
    "Lino": ["Surface du stand"],
    "Sisal": ["Surface du stand"]
  };

  const [selectedCategory, setSelectedCategory] = useState("");
  const [elements, setElements] = useState(configuration.elements || []);
  
  const categoryElements = {
    Sols: ["Moquette", "Parquet", "Lino", "Sisal"],
    Structures: ["Cloison", "Arche", "Vestiaire"],
    Mobilier: ["Comptoir droit", "Comptoir courbe", "Mange-debout", "Commode", "Fauteuil", "Étagère", "Banquette", "Table basse", "Colonne carrée", "Colonne ronde"],
    Signaletique: ["Présentoire à documents", "Oriflamme Carrée", "Oriflamme Arrondie", "Kakémono", "Cadre portrait", "Cadre paysage", "Stand Parapluie droit", "Stand parapluie courbé"],
    Décoration: ["Plante colonne", "Arbre", "Mur végétal", "Panneau mural bois", "Cafetière Nespresso"],
    "Enseignes Suspendues": ["Enseigne Suspendue Ronde", "Enseigne Suspendue Carrée"],
    "Éclairages": ["Lustre leds", "Applique murale"],
    Digital: ["Écran mural", "Écran sur pied", "Totem vidéo", "Borne interactive", "Tablette", "Ordinateur portable", "StandVR"]
  };

  const handleAddElement = (element) => {
    const defaultSize = elementSizes[element]?.[0] || "";
    const newElement = { category: selectedCategory, name: element, size: defaultSize, color: "", image: "" };
    const updatedElements = [...elements, newElement];
    setElements(updatedElements);
    updateConfig("elements", updatedElements);
  };

  const handleSizeChange = (index, size) => {
    const updatedElements = elements.map((el, i) => (i === index ? { ...el, size } : el));
    setElements(updatedElements);
    updateConfig("elements", updatedElements);
  };

  const handleRemoveElement = (index) => {
    const updatedElements = elements.filter((_, i) => i !== index);
    setElements(updatedElements);
    updateConfig("elements", updatedElements);
  };

  const isElementSelected = (element) => {
    return elements.some((el) => el.name === element);
  };

  return (
    <div className="p-4 bg-white shadow-md rounded-lg lg:p-6">
      <h2 className="text-xl lg:text-2xl font-bold mb-4 lg:mb-6 text-blue-800">
        Sélectionnez les éléments du stand
      </h2>
  
      {/* Affichage des catégories */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 mb-6">
        {categories.map((category) => (
          <button
            key={category.name}
            onClick={() => setSelectedCategory(category.name)}
            className={`p-3 border-2 rounded-lg transition-all duration-300 flex flex-col items-center ${
              selectedCategory === category.name ? "bg-blue-600 text-white border-blue-700" : "bg-white text-blue-800 border-blue-300 hover:border-blue-500 hover:bg-blue-50"
            }`}
          >
            <i className={`fas ${category.icon} text-2xl mb-2`}></i>
            <span className="text-sm font-semibold">{category.name}</span>
          </button>
        ))}
      </div>
  
      {/* Affichage des éléments par catégorie */}
      {selectedCategory && (
        <div className="mt-6">
          <h3 className="text-xl font-semibold mb-3 text-blue-700">Éléments pour {selectedCategory}</h3>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
            {categoryElements[selectedCategory].map((elem) => (
              <button
                key={elem}
                onClick={() => handleAddElement(elem)}
                className={`p-3 border-2 rounded-lg transition-all duration-300 flex items-center text-left ${
                  isElementSelected(elem) 
                    ? "bg-blue-100 border-blue-400"
                    : "bg-white border-blue-200 hover:bg-blue-50 hover:border-blue-400"
                }`}
              >
                <div className="w-16 h-16 bg-gray-200 rounded-md flex-shrink-0 mr-4 flex items-center justify-center">
                  <img src={`/images/${elem.replace(/ /g, "_").toLowerCase()}.jpg`} alt={elem} className="object-cover w-full h-full rounded-md" />
                </div>
                <span className="flex-grow text-sm font-medium flex items-center">{elem}</span>
              </button>
            ))}
          </div>
        </div>
      )}
  
      {/* Liste des éléments sélectionnés */}
      {elements.length > 0 && (
        <div className="mt-8">
          <h3 className="text-xl font-semibold mb-3 text-blue-700">Éléments sélectionnés</h3>
          <ul className="space-y-3">
            {elements.map((element, index) => (
              <li key={index} className="flex items-start bg-blue-50 p-4 rounded-lg border border-blue-200">
                <div className="flex-grow">
                  <h4 className="font-bold text-lg text-blue-800 mb-2">{element.name}</h4>
                  <label className="text-xs text-gray-600 mb-1 block">Taille</label>
                  <select value={element.size} onChange={(e) => handleSizeChange(index, e.target.value)} className="p-2 border border-blue-300 rounded-md w-full focus:ring-blue-500 focus:border-blue-500">
                    <option value="">Sélectionnez une taille</option>
                    {elementSizes[element.name]?.map((size, i) => (
                      <option key={i} value={size}>{size}</option>
                    ))}
                  </select>
                </div>
                
                <button onClick={() => handleRemoveElement(index)} className="ml-4 text-red-500 hover:text-red-700 transition-colors duration-300">
                  <i className="fas fa-trash-alt"></i>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );  
}

export default Screen4;