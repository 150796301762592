import React from 'react';
import Header from '../Accueil/Header';
import Footer from '../Accueil/Footer';
import Contact from '../Contact/Contact';

function MainContact() {
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-indigo-50 to-blue-100">
      {/* Header */}
      <Header />

      {/* Main Content */}
      <main className="flex-grow">
        <Contact />
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default MainContact;
