import React, { useState } from 'react';
import { auth, googleProvider } from "../firebase";
import { signInWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth';

function Connexion({ onClose, onOpenInscription }) {
    const [isLoading, setIsLoading] = useState(false);
    const [activeProvider, setActiveProvider] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [resetMessage, setResetMessage] = useState("");

    // Fonction de connexion avec email
    const handleEmailLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setActiveProvider("Email");
        try {
            await signInWithEmailAndPassword(auth, email, password);
            alert(`Connexion réussie !`);
            onClose(); 
        } catch (error) {
            alert(`Erreur lors de la connexion : ${error.message}`);
        }
        setIsLoading(false);
        setActiveProvider(null);
    };

    // Fonction de connexion avec Google
    const handleSocialLogin = async (provider) => {
        setIsLoading(true);
        setActiveProvider(provider);
        try {
            if (provider === "Google") {
                await signInWithPopup(auth, googleProvider);
                alert("Connecté avec Google.");
                onClose();
            }
        } catch (error) {
            alert(`Erreur lors de la connexion : ${error.message}`);
        }
        setIsLoading(false);
        setActiveProvider(null);
    };

    // Fonction pour gérer la réinitialisation du mot de passe
    const handlePasswordReset = async () => {
        if (!email) {
            setResetMessage("Veuillez entrer votre adresse e-mail.");
            return;
        }

        try {
            await sendPasswordResetEmail(auth, email);
            setResetMessage("Un e-mail de réinitialisation a été envoyé.");
        } catch (error) {
            setResetMessage(`Erreur : ${error.message}`);
        }
    };

    // Appelle `onOpenInscription` pour ouvrir l'inscription et ferme la connexion
    const handleCreateAccountClick = () => {
        onClose();
        onOpenInscription();
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-70 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="relative max-w-md w-full bg-white p-10 rounded-3xl shadow-2xl">
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors duration-300"
                >
                    <i className="fas fa-times text-2xl"></i>
                </button>
                <div className="text-center">
                    <h2 className="mt-6 text-5xl font-extrabold text-gray-900 mb-2">
                        Bienvenue !
                    </h2>
                    <p className="text-xl text-gray-600">
                        Connectez-vous pour accéder à votre espace
                    </p>
                </div>
                <form onSubmit={handleEmailLogin} className="mt-8 space-y-6">
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <input
                                id="email-address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Adresse e-mail"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Mot de passe"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="text-right mt-2">
                        <button
                            type="button"
                            onClick={handlePasswordReset}
                            className="text-sm text-indigo-600 hover:text-indigo-500 underline"
                        >
                            Mot de passe oublié ?
                        </button>
                    </div>

                    {resetMessage && (
                        <p className="mt-2 text-center text-sm text-gray-500">{resetMessage}</p>
                    )}

                    <button
                        type="submit"
                        className={`w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none ${isLoading && activeProvider === "Email" ? "opacity-75 cursor-not-allowed" : ""}`}
                        disabled={isLoading}
                    >
                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                            <i className="fas fa-envelope"></i>
                        </span>
                        Se connecter avec l'email
                    </button>
                </form>

                <div className="mt-6">
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-300"></div>
                        </div>
                        <div className="relative flex justify-center text-sm">
                            <span className="px-2 bg-white text-gray-500">
                                Ou continuer avec
                            </span>
                        </div>
                    </div>

                    <button
                        onClick={() => handleSocialLogin("Google")}
                        className={`mt-6 w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 ${isLoading && activeProvider === "Google" ? "opacity-75 cursor-not-allowed" : ""}`}
                        disabled={isLoading}
                    >
                        <i className="fab fa-google mr-2"></i> Continuer avec Google
                    </button>
                </div>

                {isLoading && (
                    <div className="mt-6 text-center">
                        <div className="inline-block animate-spin rounded-full h-10 w-10 border-t-4 border-b-4 border-indigo-500"></div>
                        <p className="mt-3 text-base text-gray-600 animate-pulse">
                            Connexion en cours...
                        </p>
                    </div>
                )}

                <div className="mt-8 text-center">
                    <p className="text-sm text-gray-500">
                        Vous n'avez pas de compte ?{" "}
                        <span
                            onClick={handleCreateAccountClick}
                            className="font-medium text-indigo-600 hover:text-indigo-500 underline cursor-pointer"
                        >
                            Créez-en un ici
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Connexion;
