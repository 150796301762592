import React from 'react';

function AnnuaireAccueil() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [radius, setRadius] = React.useState(50);
  const categories = [
    { id: 1, name: "Conception de Stand", icon: "fa-pencil-ruler", description: "Design et création de stands sur mesure" },
    { id: 2, name: "Audiovisuel", icon: "fa-video", description: "Équipements et solutions audiovisuelles" },
    { id: 3, name: "Logistique", icon: "fa-truck", description: "Transport, montage, stockage et démontage" },
    { id: 4, name: "Animation", icon: "fa-users", description: "Hôtesses, animateurs et démonstrateurs" },
    { id: 5, name: "E-Marketing", icon: "fa-bullhorn", description: "Campagnes publicitaires et suivi post-salon" },
    { id: 6, name: "Traiteurs", icon: "fa-utensils", description: "Service traiteur et bars VIP" },
    { id: 7, name: "Communication", icon: "fa-print", description: "Supports imprimés et signalétique" },
    { id: 8, name: "Digital", icon: "fa-tablet-alt", description: "Collecte de données et solutions interactives" },
  ];

  const providers = [
    { id: 1, name: "StandExpo Pro", location: "Paris", rating: 4.8, image: "/stands-demo/stand-demo-1.jpeg", description: "Expert en stands modulaires écologiques" },
    { id: 2, name: "AudioVisuel Plus", location: "Lyon", rating: 4.6, image: "/stands-demo/stand-demo-2.png", description: "Solutions audiovisuelles innovantes" },
  ];  

  return (
    <div className="min-h-screen bg-white font-roboto">
      
      {/* Hero Section */}
      <section className="relative h-[700px] bg-[#030B2E] overflow-hidden">
        <div className="absolute inset-0 bg-[image:'/stands-demo/stand-demo-1.jpeg')] bg-cover bg-center opacity-20"></div>
        <div className="absolute inset-0 bg-gradient-to-br from-[#030B2E] via-[#0A1959] to-[#030B2E] opacity-90"></div>
        <div className="relative container mx-auto px-4 h-full flex items-center justify-center">
          <div className="max-w-4xl text-center">
            <h1 className="text-5xl md:text-7xl font-bold text-white mb-8 leading-tight">
              Trouvez les meilleurs prestataires pour votre stand près de chez vous
            </h1>
            <div className="bg-white/95 backdrop-blur-md rounded-2xl p-6 shadow-2xl">
              <div className="relative mb-4">
                <i className="fas fa-search absolute left-4 top-1/2 -translate-y-1/2 text-gray-400"></i>
                <input
                  type="text"
                  className="w-full px-12 py-4 rounded-xl text-lg border-2 border-gray-100 focus:border-[#030B2E] focus:ring-2 focus:ring-[#030B2E]/20 transition-all"
                  placeholder="Rechercher un service (ex: conception de stand à Paris)"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div className="flex gap-4">
                <button className="flex-1 bg-[#030B2E] text-white px-8 py-4 rounded-xl hover:bg-[#0A1959] transition-all shadow-lg">
                  <i className="fas fa-search mr-2"></i>Lancer ma recherche
                </button>
                <button className="flex-1 border-2 border-[#030B2E] text-[#030B2E] px-8 py-4 rounded-xl hover:bg-[#030B2E] hover:text-white transition-all">
                  <i className="fas fa-th-large mr-2"></i>Découvrir les catégories
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Service Categories Section */}
      <section className="py-24 bg-gradient-to-b from-gray-50 to-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-[#030B2E] mb-12 text-center">Catégories de Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {categories.map((category) => (
              <div key={category.id} className="group bg-white p-8 rounded-2xl shadow-lg hover:shadow-2xl transition-all border border-gray-100">
                <div className="text-[#030B2E] text-5xl mb-6 flex justify-center transform group-hover:scale-110 transition-transform">
                  <i className={`fas ${category.icon}`}></i>
                </div>
                <h3 className="text-2xl font-bold text-[#030B2E] mb-3 text-center">{category.name}</h3>
                <p className="text-gray-600 text-center mb-6">{category.description}</p>
                <button className="w-full text-[#030B2E] border-2 border-[#030B2E] px-6 py-3 rounded-xl hover:bg-[#030B2E] hover:text-white transition-all">
                  <i className="fas fa-arrow-right mr-2"></i>Voir les prestataires
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Providers Section */}
      <section className="py-24 bg-white">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row gap-8 mb-8">
            <input
              type="range"
              min="20"
              max="100"
              value={radius}
              onChange={(e) => setRadius(e.target.value)}
              className="w-full md:w-64"
            />
            <span className="text-[#030B2E]">Rayon: {radius} km</span>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {providers.map((provider) => (
              <div key={provider.id} className="bg-white rounded-2xl shadow-xl hover:shadow-2xl transition-all border border-gray-100">
                <div className="relative">
                  <img src={provider.image} alt={provider.name} className="w-full h-56 object-cover" />
                  <div className="absolute bottom-0 left-0 right-0 h-20 bg-gradient-to-t from-[#030B2E] to-transparent"></div>
                </div>
                <div className="p-8">
                  <div className="flex justify-between items-start mb-6">
                    <h3 className="text-2xl font-bold text-[#030B2E]">{provider.name}</h3>
                    <div className="flex items-center bg-[#030B2E] text-white px-3 py-1 rounded-full">
                      <span className="text-yellow-400 mr-2"><i className="fas fa-star"></i></span>
                      <span className="font-semibold">{provider.rating}</span>
                    </div>
                  </div>
                  <p className="text-gray-600 mb-6 text-lg">{provider.description}</p>
                  <div className="flex gap-4">
                    <button className="flex-1 bg-[#030B2E] text-white px-6 py-3 rounded-xl hover:bg-[#0A1959] transition-all shadow-lg">
                      <i className="fas fa-user mr-2"></i>Voir profil
                    </button>
                    <button className="flex-1 border-2 border-[#030B2E] text-[#030B2E] px-6 py-3 rounded-xl hover:bg-[#030B2E] hover:text-white transition-all">
                      <i className="fas fa-file-invoice mr-2"></i>Devis
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default AnnuaireAccueil;
