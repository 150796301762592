// src/Formules/SuccessPage/SuccessPage.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function SuccessPage() {
  const [counter, setCounter] = useState(15); // Compte à rebours en secondes
  const navigate = useNavigate();

  // Compte à rebours et redirection
  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => setCounter((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    } else {
      navigate("/espace-prestataire"); // Redirection à la fin du compte à rebours
    }
  }, [counter, navigate]);

  return (
    <div className="relative min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-white via-blue-50 to-white overflow-hidden text-center">
      <div className="relative z-10 flex flex-col items-center space-y-6 max-w-lg w-full mx-auto px-6 py-8 bg-white bg-opacity-90 rounded-xl shadow-lg backdrop-blur-md mt-24 md:px-10 md:py-12">
        <i className="fas fa-check-circle text-6xl md:text-7xl text-blue-500 mb-4 animate-bounce"></i>
        <h1 className="text-3xl md:text-5xl font-extrabold text-blue-700 leading-tight drop-shadow-md">
          Paiement Confirmé !
        </h1>

        <h2 className="text-lg md:text-2xl font-semibold text-blue-600">
          Merci pour votre confiance !
        </h2>

        <p className="text-sm md:text-lg text-gray-700 leading-relaxed mb-4">
          Votre paiement a été traité avec succès. Vous serez redirigé vers votre espace prestataire dans <span className="font-semibold text-blue-800">{counter} secondes</span>.
        </p>

        <div className="w-full h-3 md:h-4 bg-gray-200 rounded-full overflow-hidden">
          <div
            className="h-full bg-gradient-to-r from-blue-500 to-blue-700 rounded-full transition-all duration-1000 ease-linear"
            style={{ width: `${(counter / 15) * 100}%` }}
          ></div>
        </div>

        <button
          onClick={() => navigate("/espace-prestataire")}
          className="w-full md:w-auto bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-700 hover:to-blue-600 text-white px-6 py-3 md:px-10 md:py-3 rounded-full text-lg font-semibold shadow-lg hover:shadow-xl transition-transform transform hover:scale-105 focus:outline-none"
        >
          Accéder à mon espace prestataire
        </button>
      </div>
    </div>
  );
}

export default SuccessPage;
