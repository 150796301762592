import React, { useState } from "react"; 
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore"; 
import { auth, db } from "../firebase";

function OeInscription({ nextStep, setFormData, formData }) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeProvider, setActiveProvider] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  const provider = new GoogleAuthProvider();

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    setActiveProvider("Google");
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Enregistrement de l'utilisateur en tant qu'exposant dans Firestore
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        displayName: user.displayName,
        createdAt: new Date(),
        role: "exposant",
      });

      nextStep();
    } catch (error) {
      setError("Erreur lors de la connexion avec Google. Veuillez réessayer.");
    }
    setIsLoading(false);
    setActiveProvider(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    setActiveProvider("Email");

    const { contactEmail, contactPassword, contactPhone } = formData;

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, contactEmail, contactPassword);
      await sendEmailVerification(userCredential.user);

      // Enregistrement de l'utilisateur en tant qu'exposant dans Firestore
      await setDoc(doc(db, "users", userCredential.user.uid), {
        email: contactEmail,
        contactPhone: contactPhone,
        createdAt: new Date(),
        role: "exposant",
      });

      nextStep(); 
    } catch (error) {
      setError(error.message);
    }
    setIsLoading(false);
    setActiveProvider(null);
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    const { contactEmail, contactPassword } = formData;

    try {
      await signInWithEmailAndPassword(auth, contactEmail, contactPassword);
      nextStep();
    } catch (error) {
      setError("Erreur lors de la connexion. Veuillez vérifier vos identifiants.");
    }
    setIsLoading(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-100 via-purple-100 to-pink-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white rounded-3xl shadow-2xl overflow-hidden">
        <div className="md:flex">
          <div className="md:w-1/2 bg-gradient-to-br from-indigo-600 to-purple-700 p-12 text-white flex flex-col justify-center">
            <h2 className="text-4xl font-extrabold mb-6 leading-tight">
              {showLogin ? "Connexion" : "Inscription Exposant"}
            </h2>
            <p className="text-xl mb-8 leading-relaxed">
              {showLogin 
                ? "Connectez-vous pour accéder à votre espace exposant."
                : "Connectez-vous via Google ou inscrivez-vous avec votre adresse email pour créer gratuitement votre Stand en 3D."
              }
            </p>
          </div>
          <div className="md:w-1/2 p-6 bg-white">
            <h2 className="text-3xl font-bold text-gray-900 mt-4 text-center">
              {showLogin ? "Connexion" : "Inscription"}
            </h2>

            {error && <p className="text-red-500 text-center mb-4">{error}</p>}

            {!showLogin && (
              <button
                onClick={handleGoogleSignIn}
                className="w-full mt-8 mb-6 bg-red-500 text-white py-3 px-6 rounded-lg hover:bg-red-600 transition-all duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center shadow-md"
                disabled={isLoading && activeProvider === "Google"}
              >
                <i className="fab fa-google mr-3"></i>
                Connexion avec Google
              </button>
            )}

            <div className="relative flex justify-center text-sm mb-4">
              <span className="px-2 bg-white text-gray-500">{showLogin ? "ou connectez-vous avec votre email" : "Ou"}</span>
            </div>

            {showLogin ? (
              <form onSubmit={handleLoginSubmit} className="space-y-6">
                <input
                  type="email"
                  name="contactEmail"
                  className="appearance-none block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-t-md sm:text-sm"
                  placeholder="Adresse e-mail"
                  value={formData.contactEmail}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="password"
                  name="contactPassword"
                  className="appearance-none block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-b-md sm:text-sm"
                  placeholder="Mot de passe"
                  value={formData.contactPassword}
                  onChange={handleInputChange}
                  required
                />
                <button
                  type="submit"
                  className="w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300"
                >
                  Connexion
                </button>
              </form>
            ) : (
              <form onSubmit={handleSignUpSubmit} className="space-y-6">
                <input
                  type="email"
                  name="contactEmail"
                  className="appearance-none block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Adresse e-mail"
                  value={formData.contactEmail}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="password"
                  name="contactPassword"
                  className="appearance-none block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-b-md sm:text-sm"
                  placeholder="Mot de passe"
                  value={formData.contactPassword}
                  onChange={handleInputChange}
                  required
                />
                <button
                  type="submit"
                  className="w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-300"
                >
                  S'inscrire
                </button>
              </form>
            )}

            <div className="mt-8 text-center">
              <p className="text-sm text-gray-500">
                {showLogin ? "Pas encore de compte ?" : "Vous avez déjà un compte ?"}{" "}
                <button
                  type="button"
                  onClick={() => setShowLogin(!showLogin)}
                  className="font-medium text-indigo-600 hover:text-indigo-500 underline transition-colors duration-300"
                >
                  {showLogin ? "Inscrivez-vous" : "Connectez-vous"}
                </button>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OeInscription;
