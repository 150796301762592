import React, { useState } from 'react';
import { FaUser, FaEnvelope, FaRegEdit, FaPaperPlane, FaPhoneAlt, FaGlobe, FaMapMarkerAlt, FaInfoCircle } from 'react-icons/fa';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  // Validation des champs
  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Nom requis";
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Email valide requis";
    if (!formData.subject) newErrors.subject = "Sujet requis";
    if (!formData.message) newErrors.message = "Message requis";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setIsSubmitted(true);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center py-12 px-6 sm:px-10 md:px-16 lg:px-24 bg-gradient-to-br from-gray-50 to-gray-200 rounded-lg shadow-lg mx-auto max-w-4xl mt-12 relative">
      <div className="relative z-10 w-full">
        <h2 className="text-4xl md:text-5xl font-semibold text-gray-800 mb-5 text-center">
          Contactez-nous
        </h2>
        <p className="text-lg md:text-xl text-gray-600 text-center mb-10">
          Vous avez des questions ou des remarques ? Envoyez-nous un message.
        </p>

        {!isSubmitted ? (
          <form onSubmit={handleSubmit} className="w-full space-y-6 sm:space-y-8">
            <div className="flex flex-col md:flex-row gap-6">
              <div className="w-full relative">
                <FaUser className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="text"
                  name="name"
                  placeholder="Nom complet"
                  value={formData.name}
                  onChange={handleChange}
                  className={`w-full pl-12 py-3 rounded-lg border ${errors.name ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-gray-500 text-gray-800`}
                />
                {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
              </div>
              <div className="w-full relative">
                <FaEnvelope className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  type="email"
                  name="email"
                  placeholder="Adresse e-mail"
                  value={formData.email}
                  onChange={handleChange}
                  className={`w-full pl-12 py-3 rounded-lg border ${errors.email ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-gray-500 text-gray-800`}
                />
                {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
              </div>
            </div>
            <div className="relative">
              <FaRegEdit className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                name="subject"
                placeholder="Sujet"
                value={formData.subject}
                onChange={handleChange}
                className={`w-full pl-12 py-3 rounded-lg border ${errors.subject ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-gray-500 text-gray-800`}
              />
              {errors.subject && <p className="text-red-500 text-sm mt-1">{errors.subject}</p>}
            </div>
            <div className="relative">
              <textarea
                name="message"
                placeholder="Votre message"
                value={formData.message}
                onChange={handleChange}
                rows="5"
                className={`w-full px-4 py-3 rounded-lg border ${errors.message ? 'border-red-500' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-gray-500 text-gray-800`}
              />
              {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
            </div>
            <button
              type="submit"
              className="flex items-center justify-center gap-2 w-full py-4 px-6 rounded-lg bg-indigo-600 text-white font-bold text-lg hover:bg-indigo-700 transition duration-300 shadow-md transform hover:scale-105"
            >
              <FaPaperPlane /> Envoyer
            </button>
          </form>
        ) : (
          <div className="text-center">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">Merci pour votre message !</h3>
            <p className="text-lg text-gray-600">
              Nous avons bien reçu votre message et vous répondrons dans les plus brefs délais.
            </p>
          </div>
        )}

        {/* Coordonnées de contact */}
        <div className="mt-12 border-t border-gray-300 pt-8">
          <h3 className="text-2xl font-semibold text-gray-800 text-center mb-4">Coordonnées de REALITIM, l'éditeur de Stand-3D</h3>
          <div className="flex flex-col sm:flex-row justify-around text-gray-700">
            <div className="flex items-center gap-3 mb-4 sm:mb-0">
              <FaMapMarkerAlt className="text-gray-500" />
              <span>34 rue Joncours, 44100 Nantes, France</span>
            </div>
            <div className="flex items-center gap-3 mb-4 sm:mb-0">
              <FaPhoneAlt className="text-gray-500" />
              <a href="tel:+33123456789" className="hover:text-gray-900 transition">+33 6 61 18 01 15</a>
            </div>
            <div className="flex items-center gap-3">
              <FaGlobe className="text-gray-500" />
              <a href="https://www.realitim.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-900 transition">www.realitim.com</a>
            </div>
          </div>
          <div className="text-center mt-6">
            <a href="/qui-sommes-nous" className="inline-flex items-center gap-2 text-gray-700 font-semibold hover:text-gray-900 transition text-lg">
              <FaInfoCircle /> En savoir plus sur REALITIM
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
