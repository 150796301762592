import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function OsSuccess() {
  const navigate = useNavigate();

  // Redirection vers le tableau de bord après quelques secondes
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/espace-prestataire');
    }, 30000); // Redirige après 30 secondes

    return () => clearTimeout(timer); // Nettoyage du timer au démontage du composant
  }, [navigate]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-100 via-purple-100 to-pink-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-3xl shadow-2xl overflow-hidden">
        <div className="p-10 text-center">
          <div className="mb-8">
            <svg
              className="mx-auto h-24 w-24 text-green-500 animate-bounce"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <h2 className="text-4xl font-bold text-gray-800 mb-4">
            Bravo, votre inscription est réussie !
          </h2>
          <p className="text-lg text-gray-600 mb-8">
            Votre profil de standiste a été créé avec succès.
          </p>
          <p className="text-lg text-gray-500 mb-4">
            Vous serez redirigé vers votre tableau de bord dans quelques secondes...
          </p>
          <button
            onClick={() => navigate('/espace-prestataire')}
            className="mt-6 bg-indigo-600 text-white py-3 px-6 rounded-lg hover:bg-indigo-700 transition-colors duration-600 shadow-md"
          >
            Accéder immédiatement au tableau de bord
          </button>
        </div>
      </div>
    </div>
  );
}

export default OsSuccess;
