import React, { useState, useEffect } from 'react';
import OsStart from './OsStart';
import OsInscription from './OsInscription';
import OsVerif from './OsVerif';
import OsProfil from './OsProfil'; // Composant pour la personnalisation du profil
import OsCategories from './OsCategories'; // Composant pour la sélection des catégories
import OsSuccess from './OsSuccess'; // Composant pour l'étape de succès
import { useNavigate } from 'react-router-dom'; // Utilisé pour la redirection vers MainBos
import { auth } from '../firebase';

const MainOs = () => {
  const [step, setStep] = useState(1); // Gestion des étapes du parcours d'onboarding
  const [formData, setFormData] = useState({
    companyName: "",
    siret: "",
    contactEmail: "",
    contactPhone: "",
    verificationCode: "",
    logo: null,
    website: "",
    address: "",
    categories: [], // Nouvelle propriété pour stocker les catégories sélectionnées
  });

  const navigate = useNavigate(); // Utilisation de la navigation pour la redirection

  useEffect(() => {
    const checkUserAuth = async () => {
      const user = auth.currentUser;
      if (user) {
        setStep(4); // Passer directement à l'étape 4 (OsProfil) si l'utilisateur est connecté
      }
    };

    checkUserAuth();
  }, []);

  // Fonction pour passer à l'étape suivante
  const nextStep = () => setStep(step + 1);

  // Fonction pour revenir à l'étape précédente
  const prevStep = () => setStep(step - 1);

  // Redirection automatique vers le dashboard après l'étape de succès
  useEffect(() => {
    if (step === 6) {
      const timer = setTimeout(() => {
        navigate('/espace-prestataire'); // Rediriger vers le tableau de bord
      }, 3000);
      return () => clearTimeout(timer); // Nettoyer le timer
    }
  }, [step, navigate]);

  // Gestion des étapes du formulaire
  switch (step) {
    case 1:
      return <OsStart nextStep={nextStep} />;
    case 2:
      return (
        <OsInscription 
          nextStep={nextStep} 
          prevStep={prevStep} 
          formData={formData} 
          setFormData={setFormData} 
        />
      );
    case 3:
      return (
        <OsVerif 
          nextStep={nextStep} 
          prevStep={prevStep} 
          formData={formData} 
          setFormData={setFormData} 
        />
      );
    case 4: 
      return (
        <OsProfil 
          formData={formData} 
          setFormData={setFormData}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      );
    case 5: 
      return (
        <OsCategories 
          formData={formData} 
          setFormData={setFormData}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      );
    case 6:
      return <OsSuccess />; // Afficher l'écran de succès
    default:
      return <OsStart nextStep={nextStep} />;
  }
};

export default MainOs;
