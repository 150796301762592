import React from "react";
import Header from "../../Site/Accueil/Header";
import Footer from "../../Site/Accueil/Footer";
import FormulePro from "./FormulePro";

export default function MainFormulePro() {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      
      <main className="flex-grow mt-24"> {/* Ajoute un margin-top de 100px */}
        <FormulePro />
      </main>
      
      <Footer />
    </div>
  );
}
