import React from 'react';

function BosProfilMenu({ currentScreen, setCurrentScreen }) {
  return (
    <div className="bg-white shadow-md py-4 sticky top-0 z-50 rounded-lg">
      {/* Conteneur ajusté avec largeur maximale et marges */}
      <div className="max-w-5xl mx-auto px-4 flex justify-between space-x-4 w-full">
        {/* Bouton Mon Entreprise */}
        <button
          className={`flex-1 py-3 px-6 rounded-lg transition-all duration-300 shadow-sm focus:outline-none ${
            currentScreen === 'entreprise'
              ? 'bg-blue-500 text-white shadow-md'
              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
          }`}
          onClick={() => setCurrentScreen('entreprise')}
        >
          <i className="fas fa-building mr-2"></i>
          Mon Entreprise
        </button>

                {/* Bouton Mon Expertise */}
        <button
          className={`flex-1 py-3 px-6 rounded-lg transition-all duration-300 shadow-sm focus:outline-none ${
            currentScreen === 'categories'
              ? 'bg-blue-500 text-white shadow-md'
              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
          }`}
          onClick={() => setCurrentScreen('categories')}
        >
          <i className="fas fa-award mr-2"></i>
          Mon Expertise
        </button>
        
        {/* Bouton Mes Produits */}
        <button
          className={`flex-1 py-3 px-6 rounded-lg transition-all duration-300 shadow-sm focus:outline-none ${
            currentScreen === 'produits'
              ? 'bg-blue-500 text-white shadow-md'
              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
          }`}
          onClick={() => setCurrentScreen('produits')}
        >
          <i className="fas fa-box mr-2"></i>
          Mes Produits
        </button>

        {/* Bouton Mes Réalisations */}
        <button
          className={`flex-1 py-3 px-6 rounded-lg transition-all duration-300 shadow-sm focus:outline-none ${
            currentScreen === 'realisations'
              ? 'bg-blue-500 text-white shadow-md'
              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
          }`}
          onClick={() => setCurrentScreen('realisations')}
        >
          <i className="fas fa-trophy mr-2"></i>
          Mes Réalisations
        </button>
      </div>
    </div>
  );
}

export default BosProfilMenu;
