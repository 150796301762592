// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; 

// Configuration Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDucnqrlesfzFZWEBc8Z9_3dptpReVMTzo",
  authDomain: "stand3d-builder.firebaseapp.com",
  projectId: "stand3d-builder",
  storageBucket: "stand3d-builder.appspot.com",
  messagingSenderId: "792768198617",
  appId: "1:792768198617:web:11cd4c5aa9e21ee50f4bc8",
  measurementId: "G-5MB4TMLZHM",
};

// Initialisation de Firebase
const app = initializeApp(firebaseConfig);

// Services Firebase
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const db = getFirestore(app);
const storage = getStorage(app); // Initialisez Firebase Storage

// Export des services Firebase
export { auth, googleProvider, db, storage, onAuthStateChanged };
