import React from 'react';

function BosBoard({ showNotifications, setShowNotifications }) {
  return (
    <div className="p-6 pt-0"> {/* Ajout de pt-20 pour décaler sous le menu */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-gray-800">Tableau de Bord</h2>
        <button
          onClick={() => setShowNotifications(!showNotifications)}
          className="relative bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full transition duration-300"
        >
          <i className="fas fa-bell mr-2"></i>
          Notifications
          <span className="absolute -top-1 -right-1 bg-red-500 text-xs rounded-full w-5 h-5 flex items-center justify-center">
            3
          </span>
        </button>
      </div>

      {/* Notifications avec transition */}
      {showNotifications && (
        <div className="bg-white p-4 rounded-lg shadow-md mb-6 transition-opacity duration-500 ease-in-out opacity-100">
          <h3 className="font-bold mb-2">Notifications récentes</h3>
          <ul className="space-y-2">
            <li className="flex items-center text-sm">
              <i className="fas fa-envelope text-blue-500 mr-2"></i>Nouveau message de support
            </li>
            <li className="flex items-center text-sm">
              <i className="fas fa-star text-yellow-500 mr-2"></i>Nouvel avis client reçu
            </li>
            <li className="flex items-center text-sm">
              <i className="fas fa-file-invoice-dollar text-green-500 mr-2"></i>Nouveau devis en attente
            </li>
          </ul>
        </div>
      )}

      {/* Section avec des statistiques résumées */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Carte Statistique: Résumé rapide */}
        <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 relative">
          <h3 className="font-bold mb-4 text-xl text-blue-600">Résumé rapide</h3>
          <div className="space-y-3">
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Visites:</span>
              <span className="font-semibold text-lg">1,234</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Devis reçus:</span>
              <span className="font-semibold text-lg">56</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Avis récents:</span>
              <span className="font-semibold text-lg">12</span>
            </div>
          </div>
          <div className="mt-4">
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: '70%' }}></div>
            </div>
            <p className="text-sm text-gray-600 mt-2">70% d'augmentation ce mois-ci</p>
          </div>
        </div>

        {/* Carte Statistique: Statistiques générales */}
        <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 relative">
          <h3 className="font-bold mb-4 text-xl text-green-600">Statistiques</h3>
          <div className="space-y-3">
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Vues de profil:</span>
              <span className="font-semibold text-lg">3,456</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Clics sur contact:</span>
              <span className="font-semibold text-lg">789</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Taux de conversion:</span>
              <span className="font-semibold text-lg text-green-500">23%</span>
            </div>
          </div>
          <div className="mt-4">
            <canvas id="conversionChart" width="100%" height="100"></canvas>
          </div>
        </div>

        {/* Carte Statistique: Alertes */}
        <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 relative">
          <h3 className="font-bold mb-4 text-xl text-red-600">Alertes</h3>
          <div className="space-y-3">
            <div className="flex items-center justify-between bg-blue-100 p-2 rounded">
              <div className="flex items-center">
                <i className="fas fa-envelope text-blue-500 mr-2"></i>
                <span>2 nouveaux messages</span>
              </div>
              <button className="text-blue-500 hover:text-blue-600">Voir</button>
            </div>
            <div className="flex items-center justify-between bg-yellow-100 p-2 rounded">
              <div className="flex items-center">
                <i className="fas fa-file-invoice-dollar text-yellow-500 mr-2"></i>
                <span>5 devis en attente</span>
              </div>
              <button className="text-yellow-500 hover:text-yellow-600">Traiter</button>
            </div>
            <div className="flex items-center justify-between bg-purple-100 p-2 rounded">
              <div className="flex items-center">
                <i className="fas fa-star text-purple-500 mr-2"></i>
                <span>1 nouvel avis client</span>
              </div>
              <button className="text-purple-500 hover:text-purple-600">Lire</button>
            </div>
          </div>
        </div>
      </div>

      {/* Activité récente */}
      <div className="mt-8 bg-white p-6 rounded-lg shadow-md">
        <h3 className="font-bold mb-4 text-xl text-gray-800">Activité récente</h3>
        <ul className="space-y-3">
          <li className="flex items-center justify-between border-b pb-2 hover:bg-gray-50 transition-colors duration-200">
            <div className="flex items-center">
              <i className="fas fa-user-circle text-gray-500 mr-2"></i>
              <span>Nouveau client inscrit</span>
            </div>
            <span className="text-sm text-gray-500">Il y a 2 heures</span>
          </li>
          <li className="flex items-center justify-between border-b pb-2 hover:bg-gray-50 transition-colors duration-200">
            <div className="flex items-center">
              <i className="fas fa-file-signature text-green-500 mr-2"></i>
              <span>Devis accepté - Entreprise XYZ</span>
            </div>
            <span className="text-sm text-gray-500">Il y a 1 jour</span>
          </li>
          <li className="flex items-center justify-between hover:bg-gray-50 transition-colors duration-200">
            <div className="flex items-center">
              <i className="fas fa-comment-alt text-blue-500 mr-2"></i>
              <span>Nouveau message de support</span>
            </div>
            <span className="text-sm text-gray-500">Il y a 3 jours</span>
          </li>
        </ul>
        <button className="mt-4 text-blue-500 hover:text-blue-600 transition-colors duration-200">
          Voir toutes les activités <i className="fas fa-arrow-right ml-2"></i>
        </button>
      </div>
    </div>
  );
}

export default BosBoard;
