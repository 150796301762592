import React from "react";
import Header from "../../Site/Accueil/Header";
import Footer from "../../Site/Accueil/Footer";
import FormuleProPlus from "./FormuleProPlus";

function MainFormuleProPlus() {
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-blue-50 via-white to-blue-50">
      {/* Header */}
      <Header />

      {/* Main content */}
      <main className="flex-grow mt-24"> {/* Ajoute un margin-top de 100px */}
        <FormuleProPlus />
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default MainFormuleProPlus;
