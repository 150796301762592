import React, { useState } from 'react';

function PopupInscription({ isOpen, onClose }) {
  const [activeTooltip, setActiveTooltip] = useState(null);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-70 z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-2xl shadow-2xl w-full max-w-lg relative transform transition-all duration-300"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute right-5 top-5 text-gray-400 hover:text-gray-600 transition-colors duration-300"
        >
          <i className="fas fa-times text-2xl"></i>
        </button>

        <div className="p-8">
          <h2 className="text-3xl font-bold mb-8 text-center text-[#2c3e50]">
            Choisissez votre profil
          </h2>

          <div className="space-y-6">
            {/* Bouton Profil Exposant */}
            <div
              className="relative group"
              onMouseEnter={() => setActiveTooltip("exposant")}
              onMouseLeave={() => setActiveTooltip(null)}
            >
              <button
                onClick={() => {
                  window.location.href = "/Onboarding-exposant";
                  onClose();
                }}
                className="w-full bg-gradient-to-r from-[#007bff] to-[#0056b3] text-white p-5 rounded-xl hover:shadow-lg transition-all duration-300 transform hover:scale-[1.02] flex items-center justify-center text-lg font-semibold"
              >
                <i className="fas fa-building mr-3 text-xl"></i>
                Créer un profil Exposant
              </button>
              {activeTooltip === "exposant" && (
                <div className="absolute top-0 left-full ml-4 bg-white/95 p-4 rounded-xl shadow-xl z-50 w-72 border border-blue-200">
                  <div className="relative flex items-center space-x-3">
                    <div className="bg-blue-100 p-2 rounded-full shrink-0">
                      <i className="fas fa-building text-blue-600 text-xl"></i>
                    </div>
                    <div>
                      <h3 className="text-base font-bold text-blue-900">
                        Profil Exposant
                      </h3>
                      <p className="text-sm text-blue-700">
                        Créez votre stand virtuel 3D et calculez son coût de fabrication.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Bouton Profil Prestataire */}
            <div
              className="relative group"
              onMouseEnter={() => setActiveTooltip("presta")}
              onMouseLeave={() => setActiveTooltip(null)}
            >
              <button
                onClick={() => {
                  window.location.href = "/Onboarding";
                  onClose();
                }}
                className="w-full bg-gradient-to-r from-[#28a745] to-[#218838] text-white p-5 rounded-xl hover:shadow-lg transition-all duration-300 transform hover:scale-[1.02] flex items-center justify-center text-lg font-semibold"
              >
                <i className="fas fa-handshake mr-3 text-xl"></i>
                Créer un profil Prestataire
              </button>
              {activeTooltip === "presta" && (
                <div className="absolute top-0 left-full ml-4 bg-white/95 p-4 rounded-xl shadow-xl z-50 w-72 border border-green-200">
                  <div className="relative flex items-center space-x-3">
                    <div className="bg-green-100 p-2 rounded-full shrink-0">
                      <i className="fas fa-handshake text-green-600 text-xl"></i>
                    </div>
                    <div>
                      <h3 className="text-base font-bold text-green-900">
                        Profil Prestataire
                      </h3>
                      <p className="text-sm text-green-700">
                        Référencez votre entreprise gratuitement et accédez aux demandes de devis des Exposants.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <p className="text-gray-500 text-sm text-center mt-6">
            Rejoignez notre communauté et accédez à des fonctionnalités exclusives uniques au monde.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PopupInscription;
