// src/BO-Standiste/BosScreens/BosProfilEntreprise.js

import React, { useState } from "react";

function BosProfilEntreprise() {
  const [logo, setLogo] = useState(null);
  const [formData, setFormData] = useState({
    logo: null,
    companyName: "",
    website: "",
    address: "",
  });

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLogo(e.target.result);
        setFormData((prev) => ({ ...prev, logo: e.target.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    console.log("Saving data:", formData);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-6 text-gray-800 border-b pb-2">
        Informations sur votre Entreprise
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <div className="mb-6">
            <label className="block mb-2 font-semibold text-gray-700">
              Logo de l'entreprise
            </label>
            <div
              className="w-40 h-40 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-blue-500 transition-colors duration-300"
              onClick={() => document.getElementById("logo-upload").click()}
            >
              {logo ? (
                <img
                  src={logo}
                  alt="Logo de l'entreprise"
                  className="w-full h-full object-cover rounded-lg"
                />
              ) : (
                <div className="text-center">
                  <i className="fas fa-cloud-upload-alt text-gray-400 text-4xl mb-2"></i>
                  <p className="text-sm text-gray-500">
                    Cliquez pour ajouter un logo
                  </p>
                </div>
              )}
            </div>
            <input
              type="file"
              id="logo-upload"
              className="hidden"
              onChange={handleLogoUpload}
              accept="image/*"
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="companyName"
              className="block mb-2 font-semibold text-gray-700"
            >
              Nom de l'entreprise
            </label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
              value={formData.companyName}
              onChange={handleInputChange}
              placeholder="Entrez le nom de votre entreprise"
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="website"
              className="block mb-2 font-semibold text-gray-700"
            >
              Site web
            </label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <i className="fas fa-globe text-gray-400"></i>
              </span>
              <input
                type="url"
                id="website"
                name="website"
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
                value={formData.website}
                onChange={handleInputChange}
                placeholder="https://www.example.com"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="mb-6">
            <label
              htmlFor="address"
              className="block mb-2 font-semibold text-gray-700"
            >
              Adresse
            </label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <i className="fas fa-map-marker-alt text-gray-400"></i>
              </span>
              <input
                type="text"
                id="address"
                name="address"
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 mb-2"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="Entrez l'adresse de votre entreprise"
              />
            </div>
            <div className="w-full h-64 bg-gray-200 rounded-lg overflow-hidden">
              <div className="w-full h-full flex items-center justify-center text-gray-500">
                Carte Mapbox ici
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="mt-6 bg-blue-500 text-white py-3 px-6 rounded-lg hover:bg-blue-600 transition-colors duration-300 shadow-md flex items-center justify-center w-full md:w-auto"
        onClick={handleSave}
      >
        <i className="fas fa-save mr-2"></i>
        Enregistrer les modifications
      </button>
    </div>
  );
}

export default BosProfilEntreprise;
