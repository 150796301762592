function MainPageStandiste() {
    const [showModal, setShowModal] = React.useState(false);
    const [selectedImage, setSelectedImage] = React.useState(null);
    const services = [
      {
        title: "Conception de Stand sur Mesure",
        description: "Création de stands personnalisés avec matériaux durables",
        icon: "fas fa-pencil-ruler",
      },
      {
        title: "Location de Mobilier",
        description: "Large gamme de mobilier événementiel haut de gamme",
        icon: "fas fa-couch",
      },
      {
        title: "Solutions Audiovisuelles",
        description: "Équipements audiovisuels de dernière génération",
        icon: "fas fa-tv",
      },
    ];
  
    const portfolio = [
      {
        image: "/stand1.jpg",
        title: "Stand Salon du Livre 2023",
        description: "300m² sur 2 étages",
      },
      {
        image: "/stand2.jpg",
        title: "Stand Tech Expo 2023",
        description: "Design minimaliste 150m²",
      },
      {
        image: "/stand3.jpg",
        title: "Stand Bio Fair 2023",
        description: "Concept écologique 200m²",
      },
    ];
  
    const testimonials = [
      {
        name: "Jean Dupont",
        company: "TechCorp",
        rating: 5,
        comment: "Excellent service, très professionnel",
        date: "2024-01-15",
      },
      {
        name: "Marie Martin",
        company: "EcoSolutions",
        rating: 4,
        comment: "Très satisfait du résultat final",
        date: "2024-01-10",
      },
    ];
    const clients = [
      { logo: "/client1.png", name: "TechCorp" },
      { logo: "/client2.png", name: "EcoSolutions" },
      { logo: "/client3.png", name: "InnovGroup" },
    ];
  
    const [activeTab, setActiveTab] = React.useState("all");
    const [formData, setFormData] = React.useState({
      service: "",
      description: "",
      budget: "",
      event_date: "",
      name: "",
      company: "",
      email: "",
      phone: "",
    });
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      console.log(formData);
      setShowModal(false);
    };
  
    return (
      <div className="font-roboto bg-white">
        <header className="relative h-[500px] md:h-[600px]">
          <img
            src="/banner.jpg"
            alt="Stand impressive showcase"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-black/40 to-black/70">
            <div className="container mx-auto px-4 h-full flex items-end pb-12">
              <div className="text-white">
                <div className="flex items-center gap-6 mb-6">
                  <img
                    src="/logo.png"
                    alt="Company logo"
                    className="w-20 h-20 rounded-full shadow-lg"
                  />
                  <h1 className="text-5xl font-bold">StandCraft Pro</h1>
                </div>
                <div className="flex items-center gap-6 text-lg">
                  <p className="flex items-center gap-2">
                    <i className="fas fa-map-marker-alt text-blue-400"></i> Paris,
                    France
                  </p>
                  <div className="flex items-center gap-2">
                    <div className="text-yellow-400 text-xl">★★★★☆</div>
                    <span className="text-white/90">(45 avis)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
  
        <main className="container mx-auto px-4 py-12">
          <section className="mb-16">
            <p className="text-xl mb-6 max-w-3xl">
              Experts en conception de stands sur mesure, logistique d'événements
              et solutions audiovisuelles
            </p>
            <div className="flex flex-wrap gap-4">
              <span className="bg-blue-100 text-blue-800 px-4 py-2 rounded-full font-medium flex items-center gap-2 hover:bg-blue-200 transition-colors">
                <i className="fas fa-globe-europe"></i> International
              </span>
              <span className="bg-green-100 text-green-800 px-4 py-2 rounded-full font-medium flex items-center gap-2 hover:bg-green-200 transition-colors">
                <i className="fas fa-certificate"></i> ISO 9001
              </span>
            </div>
          </section>
  
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6">Nos Services et Solutions</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {services.map((service, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-lg p-8 hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 border border-gray-100"
                >
                  <div className="bg-blue-50 w-16 h-16 rounded-full flex items-center justify-center mb-6">
                    <i className={`${service.icon} text-3xl text-blue-600`}></i>
                  </div>
                  <h3 className="text-xl font-semibold mb-3">{service.title}</h3>
                  <p className="text-gray-600 mb-6">{service.description}</p>
                  <button className="text-blue-600 hover:text-blue-800 font-medium flex items-center gap-2 group">
                    Voir plus
                    <i className="fas fa-arrow-right transform group-hover:translate-x-1 transition-transform"></i>
                  </button>
                </div>
              ))}
            </div>
          </section>
  
          <section className="mb-16">
            <div className="flex justify-between items-center mb-8">
              <h2 className="text-2xl font-bold">Nos Réalisations</h2>
              <div className="flex gap-2">
                <button
                  onClick={() => setActiveTab("all")}
                  className={`px-4 py-2 rounded-full ${activeTab === "all" ? "bg-blue-600 text-white" : "bg-gray-100"}`}
                >
                  Tous
                </button>
                <button
                  onClick={() => setActiveTab("stands")}
                  className={`px-4 py-2 rounded-full ${activeTab === "stands" ? "bg-blue-600 text-white" : "bg-gray-100"}`}
                >
                  Stands
                </button>
                <button
                  onClick={() => setActiveTab("events")}
                  className={`px-4 py-2 rounded-full ${activeTab === "events" ? "bg-blue-600 text-white" : "bg-gray-100"}`}
                >
                  Événements
                </button>
              </div>
            </div>
            <h2 className="text-2xl font-bold mb-6">Nos Réalisations</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {portfolio.map((item, index) => (
                <div
                  key={index}
                  className="cursor-pointer"
                  onClick={() => setSelectedImage(item)}
                >
                  <div className="relative aspect-video">
                    <img
                      src={item.image}
                      alt={item.title}
                      className="w-full h-full object-cover rounded-lg"
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-20 transition-opacity rounded-lg"></div>
                  </div>
                  <h3 className="font-semibold mt-2">{item.title}</h3>
                  <p className="text-gray-600">{item.description}</p>
                </div>
              ))}
            </div>
          </section>
  
          <section className="mb-12">
            <h2 className="text-2xl font-bold mb-6">
              Ils nous ont fait confiance
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-6 gap-8">
              {clients.map((client, index) => (
                <div key={index} className="flex items-center justify-center">
                  <img
                    src={client.logo}
                    alt={client.name}
                    className="h-12 object-contain grayscale hover:grayscale-0 transition-all"
                  />
                </div>
              ))}
            </div>
          </section>
  
          <section className="mb-16 bg-gray-50 rounded-2xl p-8">
            <h2 className="text-3xl font-bold mb-8">Avis de nos clients</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {testimonials.map((testimonial, index) => (
                <div
                  key={index}
                  className="bg-white shadow-lg rounded-xl p-8 hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
                >
                  <div className="flex items-center gap-4 mb-4">
                    <div className="w-14 h-14 bg-blue-50 rounded-full flex items-center justify-center">
                      <i className="fas fa-user text-blue-600 text-xl"></i>
                    </div>
                    <div>
                      <h3 className="font-semibold text-lg">
                        {testimonial.name}
                      </h3>
                      <p className="text-gray-600">{testimonial.company}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-1 text-yellow-400 text-xl mb-3">
                    {[...Array.from({length: 5})].map((_, i) => (
                      <i
                        key={i}
                        className={`fas fa-star ${i < testimonial.rating ? "text-yellow-400" : "text-gray-200"}`}
                      ></i>
                    ))}
                  </div>
                  <p className="text-gray-700 text-lg mb-3 italic">
                    "{testimonial.comment}"
                  </p>
                  <p className="text-gray-400 text-sm">{testimonial.date}</p>
                </div>
              ))}
            </div>
          </section>
  
          <div className="fixed bottom-8 right-8 z-40">
            <button
              onClick={() => setShowModal(true)}
              className="bg-blue-600 text-white px-8 py-4 rounded-full shadow-xl hover:bg-blue-700 transition-all hover:scale-105 flex items-center gap-3 text-lg font-medium"
            >
              <i className="fas fa-paper-plane"></i>
              Demander un devis
            </button>
          </div>
  
          {showModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50 modal-animation">
              <div className="bg-white rounded-xl p-8 max-w-md w-full">
                <div className="flex justify-between items-center mb-6">
                  <h3 className="text-2xl font-bold">Demander un devis</h3>
                  <button
                    onClick={() => setShowModal(false)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
                <form onSubmit={handleSubmit} className="space-y-5">
                  <select
                    name="service"
                    className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  >
                    <option value="">Type de service</option>
                    <option value="stand">Stand seul</option>
                    <option value="services">Services seuls</option>
                    <option value="both">Stand + services</option>
                  </select>
                  <textarea
                    name="description"
                    placeholder="Description du projet"
                    className="w-full p-3 border rounded-lg h-32 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none resize-none"
                  ></textarea>
                  <input
                    type="number"
                    name="budget"
                    value={formData.budget}
                    onChange={handleInputChange}
                    placeholder="Budget estimé (€)"
                    className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  />
                  <input
                    type="date"
                    name="event_date"
                    value={formData.event_date}
                    onChange={handleInputChange}
                    className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  />
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <input
                      type="text"
                      name="name"
                      placeholder="Nom"
                      className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                    />
                    <input
                      type="text"
                      name="company"
                      placeholder="Entreprise"
                      className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                    />
                  </div>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                    className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  />
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    placeholder="Téléphone"
                    className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
                  />
                  <button
                    type="submit"
                    className="w-full bg-blue-600 text-white py-4 rounded-lg hover:bg-blue-700 transition-colors text-lg font-medium hover:shadow-lg"
                  >
                    Envoyer ma demande
                  </button>
                </form>
              </div>
            </div>
          )}
  
          {selectedImage && (
            <div
              className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center p-4"
              onClick={() => setSelectedImage(null)}
            >
              <div className="relative max-w-4xl w-full">
                <img
                  src={selectedImage.image}
                  alt={selectedImage.title}
                  className="w-full"
                />
                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4">
                  <h3 className="text-xl font-bold">{selectedImage.title}</h3>
                  <p>{selectedImage.description}</p>
                </div>
              </div>
            </div>
          )}
        </main>
        <style jsx global>{`
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
          .modal-animation {
            animation: fadeIn 0.3s ease-out;
          }
        `}</style>
      </div>
    );
  }
  
  
export default MainPageStandiste;  