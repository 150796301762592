import React from "react";
import { useNavigate } from "react-router-dom";

function LesFormules() {
  const navigate = useNavigate();

  const pricingData = [
    {
      title: "Free",
      price: "0€ HT",
      description: "Pour débuter",
      badge: "Gratuit",
      icon: "fa-seedling",
      features: {
        "Présence dans l'annuaire": true,
        "Coordonnées visibles": false,
        "Alertes nouvelle demande de devis exposant": true,
        "Accès aux détails des demandes de devis des exposants": true,
        "Accès aux coordonnées des exposants": false,
        "Apparition en haut des résultats": false,
        "Mise en avant supplémentaire": false,
        "Support client basic": true,
      },
    },
    {
      title: "Pro",
      price: "29€ HT",
      description: "Pour les professionnels",
      badge: "Populaire",
      icon: "fa-rocket",
      features: {
        "Présence dans l'annuaire": true,
        "Coordonnées visibles": true,
        "Alertes nouvelle demande de devis exposant": true,
        "Accès aux détails des demandes de devis des exposants": true,
        "Réponses à 5 demandes de devis d'exposants par mois": true,
        "Apparition en haut des résultats": false,
        "Mise en avant supplémentaire": false,
        "Support client premium": true,
      },
      redirectTo: "/formules/formule-pro",
    },
    {
      title: "Pro+",
      price: "99€ HT",
      description: "Pour une visibilité maximale",
      badge: "Premium",
      icon: "fa-crown",
      features: {
        "Présence dans l'annuaire": true,
        "Coordonnées visibles": true,
        "Alertes nouvelle demande de devis exposant": true,
        "Accès aux détails des demandes de devis des exposants": true,
        "Accès aux coordonnées des exposants sans limite mensuelle": true,
        "Apparition en haut des résultats": true,
        "Mise en avant supplémentaire": true,
        "Support client premium": true,
      },
      redirectTo: "/formules/formule-pro-plus",
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-blue-50 py-16 px-4">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <span className="text-blue-600 font-semibold mb-2 inline-block">
            Trouvez de nouveaux clients
          </span>
          <h1 className="text-5xl font-bold text-gray-900 font-roboto mb-4">
            Formules et Tarifs
          </h1>
          <p className="text-xl text-gray-600 font-roboto mb-2">
            Choisissez la Formule adaptée à votre entreprise
          </p>
          <div className="flex items-center justify-center gap-2 text-sm text-gray-500 font-roboto">
            <i className="fas fa-check-circle text-green-500"></i>
            <p>Résiliable à tout moment sans engagement</p>
            <span className="mx-2">•</span>
            <i className="fas fa-shield-alt text-blue-500"></i>
            <p>Paiement 100% sécurisé</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {pricingData.map((plan, index) => (
            <div
              key={index}
              className={`bg-white rounded-2xl shadow-xl p-8 flex flex-col justify-between h-full transition-transform duration-300 ${
                index === 2 ? "border-4 border-blue-500" : ""
              } relative overflow-hidden`}
            >
              {plan.badge && (
                <div className="absolute top-4 right-4">
                  <span
                    className={`px-3 py-1 rounded-full text-xs font-semibold ${
                      index === 0
                        ? "bg-gray-100 text-gray-600"
                        : index === 1
                        ? "bg-blue-100 text-blue-600"
                        : "bg-blue-500 text-white"
                    }`}
                  >
                    {plan.badge}
                  </span>
                </div>
              )}
              <div className="text-center mb-8">
                <i
                  className={`fas ${plan.icon} text-4xl text-blue-500 mb-4`}
                ></i>
                <h2 className="text-3xl font-bold text-gray-900 font-roboto mb-2">
                  {plan.title}
                </h2>
                <div className="text-4xl font-bold text-blue-600 mb-2">
                  {plan.price}
                  <span className="text-sm font-normal text-gray-500 block">
                    par mois
                  </span>
                </div>
                <p className="text-gray-600 mb-4">{plan.description}</p>
              </div>

              <div className="space-y-4 flex-grow">
                {Object.entries(plan.features).map(
                  ([feature, value], featureIndex) => (
                    <div key={featureIndex} className="flex items-start">
                      {typeof value === "boolean" ? (
                        <i
                          className={`fas ${
                            value
                              ? "fa-check text-green-500"
                              : "fa-times text-red-500"
                          } mt-1`}
                        ></i>
                      ) : (
                        <i className="fas fa-check text-green-500 mt-1"></i>
                      )}
                      <span className="ml-3 text-gray-700">{feature}</span>
                      {typeof value === "string" && value !== "true" && (
                        <span className="ml-1 text-sm text-gray-500">
                          {value}
                        </span>
                      )}
                    </div>
                  )
                )}
              </div>

              <div className="mt-8 text-center">
                <button
                  onClick={() => {
                    if (index === 0) {
                      navigate('/configurateur'); 
                    } else if (plan.redirectTo) {
                      navigate(plan.redirectTo);
                    }
                  }}
                  className={`w-full py-4 px-6 rounded-xl font-bold text-center transition-all duration-200 transform hover:scale-105 ${
                    index === 2
                      ? "bg-gradient-to-r from-blue-500 to-blue-600 text-white hover:from-blue-600 hover:to-blue-700 shadow-lg"
                      : "bg-gray-50 text-gray-800 hover:bg-gray-100 border border-gray-200"
                  }`}
                >
                  {index === 0 ? "Essayer gratuitement" : "Commencer maintenant"}
                </button>
                <p className="text-xs text-center text-gray-500 mt-2">
                  {index === 0
                    ? "Aucune carte bancaire requise"
                    : "Satisfait ou remboursé pendant 30 jours"}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LesFormules;
