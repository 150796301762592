import React from 'react';
import Header from '../../Site/Accueil/Header';
import Footer from '../../Site/Accueil/Footer';
import LesFormules from './LesFormules';

function MainLesFormules() {
  return (
    <div>
      <Header />
      <main className="main-content">
        <LesFormules />
      </main>
      <Footer />
    </div>
  );
}

export default MainLesFormules;
