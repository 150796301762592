import React, { useState } from "react";

function OsProfil({ nextStep, prevStep, formData, setFormData }) {
  const [logo, setLogo] = useState(formData.logo || null);
  const [descriptionLength, setDescriptionLength] = useState(
    formData.description ? formData.description.length : 0
  );
  const maxDescriptionLength = 500;

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLogo(e.target.result);
        setFormData({ ...formData, logo: e.target.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "description") {
      setDescriptionLength(value.length);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-100 via-purple-100 to-pink-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto bg-white rounded-3xl shadow-2xl overflow-hidden">
        <div className="md:flex">
          <div className="md:w-1/2 bg-gradient-to-br from-indigo-600 to-purple-700 p-12 text-white flex flex-col justify-center">
            <h2 className="text-4xl font-extrabold mb-6 leading-tight">
              Inscrivez votre entreprise dans l'Annuaire
            </h2>
            <p className="text-xl mb-8 leading-relaxed">
              Complétez les informations ci-dessous pour créer un profil unique
              et attractif pour les Exposants.
            </p>
          </div>
          <div className="md:w-1/2 p-6">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="mb-6">
                <label className="block mb-2 font-semibold text-gray-700">
                  Logo de l'entreprise
                </label>
                <div
                  className="w-40 h-40 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-blue-500 transition-colors duration-300"
                  onClick={() => document.getElementById("logo-upload").click()}
                >
                  {logo ? (
                    <img
                      src={logo}
                      alt="Logo"
                      className="w-full h-full object-cover rounded-lg"
                    />
                  ) : (
                    <div className="text-center">
                      <i className="fas fa-cloud-upload-alt text-gray-400 text-4xl mb-2"></i>
                      <p className="text-sm text-gray-500">
                        Cliquez pour ajouter un logo
                      </p>
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  id="logo-upload"
                  className="hidden"
                  onChange={handleLogoUpload}
                  accept="image/*"
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="companyName"
                  className="block mb-2 font-semibold text-gray-700"
                >
                  Nom de l'entreprise
                </label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  placeholder="Entrez le nom de votre entreprise"
                  required
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="website"
                  className="block mb-2 font-semibold text-gray-700"
                >
                  Site web
                </label>
                <input
                  type="url"
                  id="website"
                  name="website"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
                  value={formData.website}
                  onChange={handleInputChange}
                  placeholder="https://www.votresite.com"
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="description"
                  className="block mb-2 font-semibold text-gray-700"
                >
                  Description de l'entreprise
                </label>
                <textarea
                  id="description"
                  name="description"
                  rows="4"
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
                  value={formData.description}
                  onChange={handleInputChange}
                  maxLength={maxDescriptionLength}
                  placeholder="Décrivez votre entreprise et les services que vous proposez"
                  required
                />
                <div className="text-right text-sm text-gray-500">
                  {descriptionLength}/{maxDescriptionLength} caractères
                </div>
              </div>

              <div className="mt-6 flex justify-between">
                <button
                  type="button"
                  onClick={prevStep}
                  className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 transition duration-200"
                >
                  Retour
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Suivant
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OsProfil;
