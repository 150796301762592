import React, { useState } from "react";

function BosProfilRealisations() {
  const [showRealisationPopup, setShowRealisationPopup] = useState(false);
  const [newRealisation, setNewRealisation] = useState({
    image: null,
    title: "",
    description: "",
    published: false,
  });
  const [realisations, setRealisations] = useState([]);

  const handleNewRealisationChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image" && files[0]) {
      const reader = new FileReader();
      reader.onload = (e) =>
        setNewRealisation((prev) => ({ ...prev, image: e.target.result }));
      reader.readAsDataURL(files[0]);
    } else {
      setNewRealisation((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAddRealisation = () => {
    setRealisations([...realisations, newRealisation]);
    setShowRealisationPopup(false);
    setNewRealisation({
      image: null,
      title: "",
      description: "",
      published: false,
    });
  };

  const toggleRealisationPublished = (index) => {
    const updatedRealisations = [...realisations];
    updatedRealisations[index].published = !updatedRealisations[index].published;
    setRealisations(updatedRealisations);
  };

  const deleteRealisation = (index) => {
    const updatedRealisations = realisations.filter((_, i) => i !== index);
    setRealisations(updatedRealisations);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-2 text-gray-800 border-b pb-2">
        Mes Réalisations
      </h2>
      {/* Phrase incitative sous le titre */}
      <div className="mb-6 font-bold text-gray-700 font-semibold">
        Publiez vos plus belles références sur votre Page Standiste pour inciter les exposants à vous contacter.
      </div>
      
      <button
        className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-300 shadow-md mb-4"
        onClick={() => setShowRealisationPopup(true)}
      >
        <i className="fas fa-plus mr-2"></i>
        Ajouter une réalisation
      </button>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {realisations.map((realisation, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-md p-4 flex flex-col"
          >
            <img
              src={realisation.image}
              alt={realisation.title}
              className="w-full h-40 object-cover rounded-lg mb-4"
            />
            <h3 className="font-semibold text-lg mb-2">{realisation.title}</h3>
            <p className="text-gray-600 mb-4">{realisation.description}</p>
            <div className="flex items-center mt-auto">
              <button
                className={`mr-2 px-3 py-1 rounded-full text-sm ${
                  realisation.published
                    ? "bg-green-500 text-white"
                    : "bg-gray-300 text-gray-700"
                }`}
                onClick={() => toggleRealisationPublished(index)}
              >
                {realisation.published ? "Publié" : "Dépublié"}
              </button>
              <button
                className="text-red-500 hover:text-red-700 ml-auto"
                onClick={() => deleteRealisation(index)}
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
        ))}
      </div>

      {showRealisationPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Ajouter une nouvelle réalisation</h2>
            <div className="mb-4">
              <label className="block mb-2 font-semibold text-gray-700">
                Image de la réalisation
              </label>
              <input
                type="file"
                name="image"
                onChange={handleNewRealisationChange}
                accept="image/*"
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-semibold text-gray-700">
                Titre de la réalisation
              </label>
              <input
                type="text"
                name="title"
                value={newRealisation.title}
                onChange={handleNewRealisationChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2 font-semibold text-gray-700">
                Description de la réalisation
              </label>
              <textarea
                name="description"
                value={newRealisation.description}
                onChange={handleNewRealisationChange}
                className="w-full p-2 border border-gray-300 rounded-lg"
                rows="3"
              ></textarea>
            </div>
            <div className="flex justify-end">
              <button
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400 transition-colors duration-300 mr-2"
                onClick={() => setShowRealisationPopup(false)}
              >
                Annuler
              </button>
              <button
                className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-300"
                onClick={handleAddRealisation}
              >
                Ajouter
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BosProfilRealisations;
