import React, { useState, useEffect } from "react";

function StandCard({ standId, onDelete, onView }) {
  const [isEditing, setIsEditing] = useState(false);
  const [titleValue, setTitleValue] = useState("Nom du stand");
  const [projectData, setProjectData] = useState({
    standName: "Nom du stand",
    type: "stand d'angle",
    totalCost: 1500,
    createdAt: new Date(),
    status: "Recherche en cours",
    suppliers: [
      { logo: "/logos-clients/logo Realitim large.png", name: "Supplier 1" },
      { logo: "/logos-clients/Logo_de_Fayat.jpg", name: "Supplier 2" },
    ],
  });
  const [elapsedTime, setElapsedTime] = useState(0); // Chronomètre en secondes
  const [intervalId, setIntervalId] = useState(null);

  const typeToImageMap = {
    "stand d'angle": "/stands-demo/stand-demo-1.jpeg",
    "stand ilot": "/types-de-stands/stand-ilot.png",
    "stand linéaire": "/types-de-stands/stand-lineaire.png",
    "standvr visio": "/types-de-stands/stand-vr.png",
  };

  useEffect(() => {
    setTitleValue(projectData.standName);
  }, [projectData]);

  useEffect(() => {
    if (projectData.status === "Recherche en cours") {
      // Démarrer le chronomètre si non déjà en cours
      if (!intervalId) {
        const id = setInterval(() => {
          setElapsedTime((prevTime) => prevTime + 1);
        }, 1000);
        setIntervalId(id);
      }
    } else {
      // Arrêter le chronomètre si la recherche est mise en pause
      clearInterval(intervalId);
      setIntervalId(null);
    }
    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [projectData.status, intervalId]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const changeStatus = (newStatus) => {
    setProjectData((prevData) => ({
      ...prevData,
      status: newStatus,
    }));
  };

  const renderStatusButton = () => {
    switch (projectData.status) {
      case "Recherche en cours":
        return (
          <div className="relative w-full">
            <button
              onClick={() => changeStatus("Recherche en pause")}
              className="w-full bg-yellow-500 text-white py-2 rounded-xl font-medium hover:bg-yellow-600 transition-all duration-200 shadow-md flex items-center justify-center gap-3 relative"
            >
              <i className="fas fa-pause"></i>
              Mettre en pause
              <i
                className="fas fa-info-circle text-sm ml-2 cursor-pointer relative"
                onMouseEnter={() => setProjectData({ ...projectData, showPauseInfo: true })}
                onMouseLeave={() => setProjectData({ ...projectData, showPauseInfo: false })}
              ></i>
              {projectData.showPauseInfo && (
                <div className="absolute bg-gray-800 text-white text-xs rounded-lg py-2 px-3 w-64 shadow-lg top-[-70px] left-1/2 transform -translate-x-1/2 text-center z-50 overflow-visible">
                  Cliquez pour interrompre votre recherche
                  de Prestataires.
                </div>
              )}
            </button>
          </div>
        );

      case "Recherche en pause":
        return (
          <div className="relative w-full">
            <button
              onClick={() => changeStatus("Recherche en cours")}
              className="w-full bg-blue-500 text-white py-2 rounded-xl font-medium hover:bg-blue-600 transition-all duration-200 shadow-md flex items-center justify-center gap-3 relative"
            >
              <i className="fas fa-play"></i>
              Lancer la recherche
              <i
                className="fas fa-info-circle text-sm ml-2 cursor-pointer relative"
                onMouseEnter={() => setProjectData({ ...projectData, showStartInfo: true })}
                onMouseLeave={() => setProjectData({ ...projectData, showStartInfo: false })}
              ></i>
              {projectData.showStartInfo && (
                <div className="absolute bg-gray-800 text-white text-xs rounded-lg py-2 px-3 w-64 shadow-lg top-[-70px] left-1/2 transform -translate-x-1/2 text-center z-50 overflow-visible">
                  Cliquez pour recevoir jusqu'à 5 devis
                  pour ce Projet.
                </div>
              )}
            </button>
          </div>
        );

      default:
        return null;
    }
  };

  const standImage = typeToImageMap[projectData.type?.toLowerCase()] || "/types-de-stands/default.png";

  return (
    <div className="w-[360px] bg-white rounded-3xl shadow-lg overflow-visible transform transition-transform duration-300 hover:scale-105 relative">
      {/* Image Section */}
      <div className="relative group">
        <div className="w-full h-[260px] overflow-hidden">
          <img
            src={standImage}
            alt={`Type de stand : ${projectData.type}`}
            className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
          />
        </div>
        <button
          onClick={onDelete}
          className="absolute top-4 right-4 bg-white p-3 rounded-full shadow-md hover:bg-red-50 transition-all duration-200"
          title="Supprimer ce stand"
        >
          <i className="fas fa-trash text-red-500 hover:text-red-600"></i>
        </button>
      </div>

      {/* Details Section */}
      <div className="p-6 space-y-6">
        {/* Title */}
        {isEditing ? (
          <input
            type="text"
            value={titleValue}
            onChange={(e) => setTitleValue(e.target.value)}
            onBlur={() => setIsEditing(false)}
            className="w-full border-2 border-blue-200 rounded-xl px-4 py-2 text-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        ) : (
          <h3
            onClick={() => setIsEditing(true)}
            className="font-bold text-xl cursor-pointer hover:text-blue-600 transition-colors duration-200"
            title="Cliquez pour modifier"
          >
            {titleValue}
          </h3>
        )}

        {/* Status & Chronometer */}
        <div className="flex items-center justify-between bg-gray-50 p-3 rounded-lg shadow-sm border border-gray-200">
          <div className="flex items-center gap-2">
            <div
              className={`flex items-center justify-center w-8 h-8 rounded-full ${
                projectData.status === "Recherche en cours"
                  ? "bg-blue-200"
                  : projectData.status === "Recherche en pause"
                  ? "bg-yellow-200"
                  : "bg-green-200"
              }`}
            >
              <i
                className={`fas ${
                  projectData.status === "Recherche en cours"
                    ? "fa-search text-blue-800"
                    : projectData.status === "Recherche en pause"
                    ? "fa-pause text-yellow-800"
                    : "fa-check text-green-800"
                }`}
              ></i>
            </div>
            <span
              className={`text-sm font-semibold ${
                projectData.status === "Recherche en cours"
                  ? "text-blue-800"
                  : projectData.status === "Recherche en pause"
                  ? "text-yellow-800"
                  : "text-green-800"
              }`}
            >
              {projectData.status}
            </span>
          </div>
          {projectData.status === "Recherche en cours" && (
            <div className="flex items-center gap-2 text-sm font-medium text-gray-700">
              <i className="far fa-clock text-gray-500"></i>
              <span className="text-blue-600 font-semibold">{formatTime(elapsedTime)}</span>
            </div>
          )}
        </div>

        {/* Prestataires */}
        <div className="flex -space-x-2">
          {projectData.suppliers.map((supplier, index) => (
            <img
              key={index}
              src={supplier.logo}
              alt={supplier.name}
              className="w-10 h-10 rounded-full border-2 border-white shadow-sm"
              title={supplier.name}
            />
          ))}
          {Array(5 - projectData.suppliers.length)
            .fill(0)
            .map((_, index) => (
              <div
                key={`empty-${index}`}
                className="w-10 h-10 rounded-full border-2 border-white bg-gray-100 flex items-center justify-center"
              >
                <i className="fas fa-user text-gray-300"></i>
              </div>
            ))}
        </div>

        {/* Price */}
        <div className="text-2xl font-bold text-green-600">
          {projectData.totalCost.toLocaleString("fr-FR")} €
        </div>

        {/* Status Button */}
        {renderStatusButton()}

        {/* View Button */}
        <button
          onClick={onView}
          className="w-full bg-blue-600 text-white py-3 rounded-xl font-medium hover:bg-blue-700 transition-colors duration-200 shadow-md flex items-center justify-center gap-3"
          title="Voir le stand"
        >
          <i className="fas fa-eye"></i>
          Voir le stand
        </button>
      </div>
    </div>
  );
}

export default StandCard;
