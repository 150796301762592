import React from "react";
import { loadStripe } from "@stripe/stripe-js";

// Chargez Stripe avec votre clé publique
const stripePromise = loadStripe("pk_test_51QJE7CG6krrj8EjUlhPv7TqZgzfCG1mnmOrnCDnrdlEpYOFHfXjVfbyLE6i9ziPzAeerc0DI9HmAN5TwpGI2J5Lh00GnfMl9DP");

function FormulePro() {
  const [selectedPlan, setSelectedPlan] = React.useState("monthly");
  const [isHovered, setIsHovered] = React.useState(false);

  // Fonction pour rediriger vers Stripe Checkout
  const handleSubscription = async () => {
    const stripe = await stripePromise;

    // Définissez l'identifiant de prix en fonction du plan sélectionné
    const priceId = selectedPlan === "monthly" ? "price_1QJEUmG6krrj8EjU1Dpz4wy9" : "price_1QJWtrG6krrj8EjUIpufwKI2";

    // Redirige vers la page de paiement de Stripe
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: priceId, quantity: 1 }],
      mode: "subscription",
      successUrl: `${window.location.origin}/success`,
      cancelUrl: `${window.location.origin}/cancel`,
    });

    if (error) {
      console.error("Erreur de redirection vers Stripe:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-blue-50 py-16 px-4">
      <div className="max-w-5xl mx-auto">
        <div className="text-center mb-16">
          <span className="text-blue-600 font-semibold mb-2 inline-block">
            Boostez votre visibilité
          </span>
          <h1 className="text-5xl font-bold text-gray-900 mb-4 flex items-center justify-center gap-3">
            <i className="fas fa-rocket text-blue-500"></i> {/* Icône ajoutée */}
            Formule Pro
          </h1>
          <p className="text-xl text-gray-600 mb-4 max-w-3xl mx-auto leading-relaxed">
            Accédez à notre annuaire en tant que prestataire Pro. Avec cette
            formule, obtenez un accès exclusif aux coordonnées des exposants,
            des alertes en temps réel, et plus encore pour développer vos
            opportunités.
          </p>
        </div>

        <div className="bg-white rounded-3xl shadow-xl p-8 mb-12 border-2 border-blue-200 hover:border-blue-300 transition-colors">
          <div className="grid md:grid-cols-2 gap-12 mb-8">
            <div className="space-y-6">
              <h3 className="text-3xl font-bold text-gray-900 mb-8">
                Avantages Inclus
              </h3>
              {[
                "Accès aux coordonnées des exposants (5/mois)",
                "Visibilité complète dans l'annuaire",
                "Alertes en temps réel",
                "Support client prioritaire",
              ].map((benefit, index) => (
                <div
                  key={index}
                  className="flex items-center bg-blue-50 p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 hover:bg-blue-100"
                >
                  <i className="fas fa-check-circle text-blue-600 text-2xl mr-4"></i>
                  <span className="font-medium text-gray-900">{benefit}</span>
                </div>
              ))}
            </div>

            <div className="bg-gradient-to-br from-blue-50 to-white rounded-2xl p-8 border border-blue-200">
              <div className="flex justify-center space-x-4 mb-8">
                <button
                  className={`px-8 py-4 rounded-xl font-medium transition-all duration-300 ${
                    selectedPlan === "monthly"
                      ? "bg-blue-600 text-white shadow-lg scale-105"
                      : "bg-white text-blue-600 hover:bg-blue-50 border-2 border-blue-200"
                  }`}
                  onClick={() => setSelectedPlan("monthly")}
                >
                  Mensuel
                </button>
                <button
                  className={`px-8 py-4 rounded-xl font-medium transition-all duration-300 ${
                    selectedPlan === "annual"
                      ? "bg-blue-600 text-white shadow-lg scale-105"
                      : "bg-white text-blue-600 hover:bg-blue-50 border-2 border-blue-200"
                  }`}
                  onClick={() => setSelectedPlan("annual")}
                >
                  Annuel
                </button>
              </div>

              <div className="text-center">
                <div className="text-5xl font-bold mb-4 text-gray-900">
                  {selectedPlan === "monthly" ? "30€ HT" : "313,20€ HT"}
                  <span className="text-lg font-normal text-blue-600 ml-2">
                    {selectedPlan === "monthly" ? "/mois" : "/an"}
                  </span>
                </div>
                <p className="text-gray-700 mb-8 font-medium text-lg">
                  {selectedPlan === "monthly"
                    ? "Sans engagement, résiliable à tout moment"
                    : "Économisez 10% avec l'engagement annuel"}
                </p>
                <button
                  className="w-full bg-gradient-to-r from-blue-600 to-blue-700 text-white py-5 px-8 rounded-xl font-bold text-lg transition-all duration-300 hover:shadow-lg hover:shadow-blue-200 transform hover:-translate-y-1"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={handleSubscription}
                >
                  Souscrire maintenant
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center bg-blue-50 rounded-2xl p-8 border border-blue-200">
          <h3 className="font-bold text-2xl mb-4 text-gray-900">
            Résumé des Bénéfices
          </h3>
          <p className="text-blue-700 mb-3 text-lg">
            Formule Pro : accédez aux avantages de la visibilité Pro et des
            demandes de devis ciblées.
          </p>
          <p className="text-blue-700 text-lg">
            Flexibilité de Paiement : abonnement mensuel ou annuel avec 10%
            d'économie.
          </p>
        </div>
      </div>
    </div>
  );
}

export default FormulePro;
