import React, { useState } from "react";

function ColorPicker({ colors, selectedColor = "#000dbd", onChange }) {
  const [tempColor, setTempColor] = useState(selectedColor); // État temporaire pour la couleur sélectionnée
  const [customColor, setCustomColor] = useState(selectedColor); // État pour la couleur appliquée

  // Fonction de mise à jour de la couleur temporaire sans appliquer immédiatement
  const handleColorSelect = (color) => {
    setTempColor(color);
  };

  const handleCustomColorChange = (e) => {
    const color = e.target.value;
    setTempColor(color);
  };

  // Fonction de validation pour appliquer la couleur
  const applyColor = () => {
    setCustomColor(tempColor);
    onChange(tempColor);
  };

  return (
    <div className="flex items-center space-x-3 relative">
      {/* Couleurs préconfigurées affichées en ligne à gauche */}
      <div className="flex space-x-2">
        {colors.map((color) => (
          <button
            key={color.hex}
            onClick={() => handleColorSelect(color.hex)}
            className={`w-8 h-8 rounded-full border transition-transform transform hover:scale-110 ${
              color.hex === customColor ? "ring-2 ring-blue-500 border-blue-500" : "border-gray-300"
            }`}
            style={{ backgroundColor: color.hex }}
            title={color.name}
            aria-label={`Sélectionner la couleur ${color.name}`}
          ></button>
        ))}
      </div>

      {/* Champ hexadécimal et roue chromatique */}
      <div className="flex items-center space-x-2">
        {/* Champ hexadécimal */}
        <input
          type="text"
          value={tempColor}
          onChange={handleCustomColorChange}
          className="w-24 text-center rounded-md border border-gray-300 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm transition-colors"
          placeholder="#000000"
          aria-label="Couleur personnalisée hexadécimale"
        />
        
        {/* Roue chromatique intégrée */}
        <input
          type="color"
          value={tempColor}
          onChange={handleCustomColorChange}
          className="w-8 h-8 cursor-pointer border border-gray-300 rounded-full shadow-sm"
          aria-label="Roue de sélection de couleurs personnalisée"
          title="Choisir une couleur personnalisée"
        />
      </div>

      {/* Bouton de validation */}
      <button
        onClick={applyColor}
        className="px-4 py-2 text-white bg-blue-500 rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors"
        aria-label="Appliquer la couleur sélectionnée"
      >
        Appliquer
      </button>
    </div>
  );
}

export default ColorPicker;
