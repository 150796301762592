import React from 'react';

function FAQAll() {
  const [activeSection, setActiveSection] = React.useState('generalites');
  const [expandedQuestions, setExpandedQuestions] = React.useState({});

  const faqData = {
    generalites: [
      {
        question: "Qu'est-ce que Stand3D ?",
        answer:
          "Stand3D est une plateforme en ligne innovante qui permet de concevoir des stands d'exposition en 3D de manière simple et intuitive. Elle propose également un annuaire complet de standistes, permettant aux utilisateurs de trouver les meilleurs professionnels pour réaliser leurs projets.",
      },
      {
        question: "À qui s'adresse Stand3D ?",
        answer:
          "Stand3D s'adresse à tous les professionnels du secteur événementiel, aux exposants, et aux entreprises qui participent à des salons ou des foires. Que vous soyez une petite entreprise ou une grande organisation, vous pouvez utiliser notre plateforme pour créer, configurer et trouver des solutions adaptées à vos besoins en exposition.",
      },
      {
        question: "Est-il nécessaire de créer un compte pour utiliser Stand3D ?",
        answer:
          "Vous pouvez explorer certaines fonctionnalités de Stand3D sans créer de compte, mais pour sauvegarder vos configurations, demander un devis ou contacter un standiste, vous devrez créer un compte.",
      },
    ],
    configurateur3d: [
      {
        question: "Comment fonctionne le configurateur 3D ?",
        answer:
          "Le configurateur 3D de Stand3D vous permet de concevoir votre stand d'exposition en personnalisant plusieurs éléments : taille et dimensions du stand, matériaux et finitions, mobilier, accessoires et visuels, éclairage et signalétique. Vous pouvez visualiser les modifications en temps réel et obtenir une représentation en 3D du stand que vous êtes en train de concevoir.",
      },
      {
        question: "Puis-je enregistrer mes configurations ?",
        answer:
          "Oui, après avoir créé un compte, vous pouvez sauvegarder vos configurations et y revenir à tout moment pour les modifier ou les finaliser.",
      },
      {
        question: "Puis-je obtenir un devis pour mon stand configuré ?",
        answer:
          'Absolument. Une fois que vous avez terminé de configurer votre stand, vous pouvez cliquer sur le bouton "Obtenir un devis". Un devis instantané sera généré en fonction des matériaux, du mobilier et des autres options sélectionnées. Vous pourrez également le recevoir par e-mail pour l\'imprimer ou le partager.',
      },
    ],
    devisEtTarification: [
      {
        question: "Comment le devis est-il calculé ?",
        answer:
          "Le devis est calculé en fonction de plusieurs critères, dont : les dimensions du stand, les matériaux utilisés, le mobilier et les accessoires sélectionnés, les options de transport et d'installation (si choisies). Tout est transparent et détaillé dans le devis généré automatiquement.",
      },
      {
        question: "Y a-t-il des frais supplémentaires non inclus dans le devis automatique ?",
        answer:
          "Les devis automatiques incluent la plupart des frais, mais certaines options spécifiques (comme le transport international, des demandes de personnalisation très avancées, ou des services de montage/démontage spécialisés) peuvent entraîner des coûts supplémentaires. Dans ce cas, vous serez contacté par un professionnel pour un devis final.",
      },
      {
        question: "Est-ce que le devis est gratuit ?",
        answer:
          "Oui, la génération d'un devis via notre configurateur est entièrement gratuite et sans engagement.",
      },
    ],
    annuaireStandistes: [
      {
        question: "Qu'est-ce que l'annuaire des standistes ?",
        answer:
          "L'annuaire des standistes est un répertoire complet des professionnels du secteur qui peuvent vous aider à concevoir, fabriquer, installer ou personnaliser votre stand. Il permet de rechercher des prestataires par localisation, spécialisation ou budget.",
      },
      {
        question: "Comment puis-je trouver un standiste ?",
        answer:
          'Sur notre page "Annuaire des Standistes", vous pouvez filtrer par : région ou ville, type de service (stand modulaire, sur-mesure, écologique, etc.), budget, évaluation des utilisateurs. Cela vous permet de trouver le professionnel qui correspond le mieux à vos besoins.',
      },
      {
        question: "Comment contacter un standiste ?",
        answer:
          "Une fois que vous avez trouvé un standiste qui vous intéresse, vous pouvez consulter son profil et le contacter directement via un formulaire intégré ou en utilisant ses coordonnées. Certains standistes peuvent également proposer des devis directement sur la plateforme.",
      },
    ],
  };

  const toggleQuestion = (section, index) => {
    setExpandedQuestions((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [index]: !prev[section]?.[index],
      },
    }));
  };

  return (
    <div className="container mx-auto px-4 py-12 bg-white">
      <h1 className="text-4xl font-bold mb-8 text-center text-black-600">FAQ - Stand3D</h1>
      <div className="flex flex-col md:flex-row">
        {/* Sidebar for Categories */}
        <div className="w-full md:w-1/4 mb-4 md:mb-0">
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4 text-blue-600">Catégories</h2>
            <ul>
              {Object.keys(faqData).map((section) => (
                <li key={section} className="mb-2">
                  <button
                    onClick={() => setActiveSection(section)}
                    className={`w-full text-left py-2 px-4 rounded transition-colors duration-200 ${
                      activeSection === section
                        ? 'bg-blue-500 text-white'
                        : 'hover:bg-blue-100 text-blue-600'
                    }`}
                  >
                    {section.charAt(0).toUpperCase() +
                      section.slice(1).replace(/([A-Z])/g, ' $1')}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* FAQ Content */}
        <div className="w-full md:w-3/4 md:pl-8">
          <h2 className="text-2xl font-semibold mb-6 text-blue-600">
            {activeSection.charAt(0).toUpperCase() +
              activeSection.slice(1).replace(/([A-Z])/g, ' $1')}
          </h2>
          {faqData[activeSection].map((item, index) => (
            <div key={index} className="mb-6 bg-white rounded-lg shadow-md overflow-hidden">
              <button
                onClick={() => toggleQuestion(activeSection, index)}
                className="w-full text-left p-4 flex justify-between items-center focus:outline-none"
              >
                <h3 className="text-lg font-semibold text-gray-800">{item.question}</h3>
                <svg
                  className={`w-6 h-6 text-blue-500 transform transition-transform duration-200 ${
                    expandedQuestions[activeSection]?.[index] ? 'rotate-180' : ''
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </button>
              {expandedQuestions[activeSection]?.[index] && (
                <div className="p-4 bg-blue-50 border-t border-blue-100">
                  <p className="text-gray-700">{item.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQAll;
