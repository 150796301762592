import React from 'react'; 
import { Link } from 'react-router-dom';
import Header from "./Accueil/Header";
import Footer from "./Accueil/Footer";

function PageNotFound() {
  return (
    <div className="min-h-screen flex flex-col justify-between bg-gradient-to-br from-indigo-50 to-blue-100 text-gray-800">
      {/* Utilisation de Header */}
      <Header />

      {/* Main Content */}
      <main className="flex-grow flex flex-col items-center justify-center text-center px-4 md:px-6 py-12 md:py-16 space-y-8 mt-24">
        <div className="animate-fade-in-down">
          <h1 className="text-7xl md:text-9xl font-extrabold text-indigo-600 mb-4 animate-bounce">404</h1>
          <h2 className="text-2xl md:text-4xl font-semibold text-gray-800">Page introuvable</h2>
        </div>

        <p className="text-base md:text-lg text-gray-600 max-w-md md:max-w-lg mx-auto">
          La page que vous cherchez semble ne plus exister. Nous vous prions de bien vouloir accepter toutes nos excuses pour le probleme rencontré. Explorez les liens ci-dessous pour retrouver votre chemin !
        </p>

        {/* CTA Buttons */}
        <div className="flex flex-col md:flex-row justify-center gap-4 w-full max-w-xs md:max-w-none">
          <Link
            to="/"
            className="w-full md:w-auto px-6 py-3 md:px-8 md:py-3 bg-indigo-600 text-white rounded-full shadow-md hover:shadow-lg transform hover:scale-105 transition-all duration-300 text-center"
          >
            Retour à l'accueil
          </Link>
          <Link
            to="/annuaire"
            className="w-full md:w-auto px-6 py-3 md:px-8 md:py-3 bg-blue-600 text-white rounded-full shadow-md hover:shadow-lg transform hover:scale-105 transition-all duration-300 text-center"
          >
            Explorer l'annuaire
          </Link>
          <Link
            to="/configurateur"
            className="w-full md:w-auto px-6 py-3 md:px-8 md:py-3 bg-green-600 text-white rounded-full shadow-md hover:shadow-lg transform hover:scale-105 transition-all duration-300 text-center"
          >
            Créer un Stand en 3D
          </Link>
        </div>

        <div className="mt-8 text-gray-600">
          <p className="text-lg">Pour signaler le probleme, Contactez notre équipe :</p>
          <Link
            to="/contact"
            className="text-indigo-600 text-lg font-semibold hover:text-indigo-700 transition"
          >
            <i className="fas fa-envelope mr-2"></i>Contactez-nous
          </Link>
        </div>
      </main>

      {/* Utilisation de Footer */}
      <Footer />
    </div>
  );
}

export default PageNotFound;
