// src/Site/Legal/PolitiqueCookies/MainPolitiqueCookies.js
import React, { useEffect, useRef, useState } from "react";
import Header from "../../Accueil/Header";
import Footer from "../../Accueil/Footer";
import PolitiqueCookies from "./PolitiqueCookies"; 

function MainPolitiqueCookies() {
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    // Met à jour la hauteur du header pour ajuster l'espacement du contenu
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight + 100); // Ajoute 100px d'espace supplémentaire
    }
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-[#f8fafc]">
      <div ref={headerRef}>
        <Header />
      </div>
      <main
        className="flex-grow py-8 px-4 sm:px-6 lg:px-8"
        style={{ paddingTop: `${headerHeight}px`, minHeight: "100%" }}
      >
        <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8 border-t-4 border-[#1e3a8a]">
          <PolitiqueCookies />
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default MainPolitiqueCookies;
