"use client";
import React from "react";
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

function DemandeAccueil() {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [dateFilter, setDateFilter] = React.useState("all");
  const [categoryFilter, setCategoryFilter] = React.useState("all");
  const [serviceFilter, setServiceFilter] = React.useState("all");
  const [statusFilter, setStatusFilter] = React.useState("all");
  const [viewMode, setViewMode] = React.useState("grid");

  const getServiceIcon = (service) => {
    const icons = {
      Logistique: "fa-truck",
      Audiovisuel: "fa-video",
      Conception: "fa-pencil-ruler",
      Mobilier: "fa-chair",
      Animation: "fa-users",
      Restauration: "fa-utensils",
      Digital: "fa-laptop-code",
    };
    return icons[service] || "fa-cog";
  };

  const leads = [
    {
      id: 1,
      company: "ExpoTech Solutions",
      event: "Salon du Digital 2024",
      description: "Stand 30m² + mobilier + logistique",
      category: "Stand + service",
      date: "2024-01-15",
      status: "new",
      services: ["Logistique", "Mobilier", "Conception"],
      available: true,
      budget: "15000€ - 20000€",
      urgency: "haute",
      matchScore: "95%",
      potentialRevenue: "3000€ - 4000€",
      emailVerified: true,
      lastActive: "Il y a 2 heures",
    },
    {
      id: 2,
      company: "Digital Innovators",
      event: "Tech Expo Paris",
      description: "Services audiovisuels pour présentation",
      category: "Service seul",
      date: "2024-01-14",
      status: "viewed",
      services: ["Audiovisuel", "Digital"],
      available: false,
      budget: "5000€ - 8000€",
      urgency: "moyenne",
      matchScore: "85%",
      potentialRevenue: "1000€ - 1600€",
      emailVerified: true,
      lastActive: "Il y a 1 jour",
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-white to-indigo-50">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-6 font-roboto tracking-tight">
          Leads qualifiés en attente de devis
        </h1>
        <p className="text-lg text-gray-600 mb-8 font-crimson-text leading-relaxed max-w-3xl">
          Accédez aux demandes de devis des exposants et proposez vos services.
          Vous pouvez filtrer les leads par catégorie, date ou type de demande
          pour trouver les opportunités les plus pertinentes. Seuls les 5
          premiers prestataires ayant manifesté un intérêt peuvent accéder aux
          coordonnées des exposants.
        </p>

        <div className="bg-white/90 backdrop-blur-sm rounded-3xl shadow-2xl p-8 mb-8 border border-blue-100">
          <div className="flex flex-col lg:flex-row gap-6 items-center mb-8">
            <div className="relative flex-1 w-full">
              <span className="absolute inset-y-0 left-4 flex items-center">
                <i className="fas fa-search text-blue-400 text-lg"></i>
              </span>
              <input
                type="text"
                name="search"
                placeholder="Rechercher un lead..."
                className="w-full pl-12 p-4 border-2 border-blue-100 rounded-2xl focus:ring-2 focus:ring-blue-400 focus:border-blue-200 text-lg bg-white/70 backdrop-blur-sm transition-all"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="flex gap-4">
              <button
                onClick={() => setViewMode("grid")}
                className={`p-3 rounded-xl transition-all ${
                  viewMode === "grid"
                    ? "bg-blue-100 text-blue-600"
                    : "bg-gray-100 text-gray-600"
                }`}
              >
                <i className="fas fa-th-large"></i>
              </button>
              <button
                onClick={() => setViewMode("list")}
                className={`p-3 rounded-xl transition-all ${
                  viewMode === "list"
                    ? "bg-blue-100 text-blue-600"
                    : "bg-gray-100 text-gray-600"
                }`}
              >
                <i className="fas fa-list"></i>
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
            <select
              name="date"
              className="p-4 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-600 cursor-pointer hover:border-gray-300 transition-colors"
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
            >
              <option value="all">Toutes les dates</option>
              <option value="today">Aujourd'hui</option>
              <option value="week">Cette semaine</option>
              <option value="month">Ce mois</option>
            </select>
            <select
              name="category"
              className="p-4 border border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-600 cursor-pointer hover:border-gray-300 transition-colors"
              value={categoryFilter}
              onChange={(e) => setCategoryFilter(e.target.value)}
            >
              <option value="all">Toutes les catégories</option>
              <option value="stand">Stand seul</option>
              <option value="stand_service">Stand + service</option>
              <option value="service">Service seul</option>
            </select>
            <select
              name="service"
              className="p-2 border rounded-lg"
              value={serviceFilter}
              onChange={(e) => setServiceFilter(e.target.value)}
            >
              <option value="all">Tous les services</option>
              <option value="conception">Conception de stand</option>
              <option value="logistique">Logistique</option>
              <option value="mobilier">Mobilier</option>
              <option value="audiovisuel">Audiovisuel</option>
              <option value="animation">Animation</option>
              <option value="restauration">Restauration</option>
              <option value="digital">Solutions digitales</option>
            </select>
            <select
              name="status"
              className="p-2 border rounded-lg"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="all">Tous les statuts</option>
              <option value="new">Nouveaux leads</option>
              <option value="viewed">Leads consultés</option>
              <option value="offered">Offres envoyées</option>
            </select>
          </div>
        </div>

        <div
          className={`${
            viewMode === "grid"
              ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
              : "flex flex-col gap-6"
          }`}
        >
          {leads.map((lead) => (
            <div
              key={lead.id}
              className={`group bg-white/95 backdrop-blur-sm rounded-3xl shadow-lg p-8 hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2 border-2 border-transparent hover:border-blue-200 relative overflow-hidden ${
                viewMode === "list"
                  ? "flex flex-col md:flex-row md:items-center md:gap-8"
                  : ""
              }`}
            >
              {/* Content of each lead item */}
              <button className="w-full mt-6 bg-gradient-to-r from-blue-600 to-indigo-500 text-white py-4 rounded-2xl hover:from-blue-700 hover:to-indigo-600 transition-all duration-300 flex items-center justify-center font-medium text-lg shadow-lg hover:shadow-xl group relative overflow-hidden">
                <div className="absolute inset-0 bg-white/20 transform -skew-x-12 -translate-x-full group-hover:translate-x-full transition-transform duration-700"></div>
                <i className="fas fa-eye mr-3 group-hover:scale-110 transition-transform"></i>
                <span className="relative z-10">Voir les détails</span>
                <div className="absolute right-4">
                  <i className="fas fa-arrow-right group-hover:translate-x-2 transition-transform"></i>
                </div>
              </button>
            </div>
          ))}
        </div>
      </div>
      <style jsx global>{`
        @keyframes pulse {
          0%, 100% { transform: scale(1); }
          50% { transform: scale(1.05); }
        }
        .pulse-animation {
          animation: pulse 2s infinite;
        }
      `}</style>
    </div>
  );
}

export default DemandeAccueil;
