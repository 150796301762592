import React, { useState, useContext, useEffect } from "react";
import { ConfigContext } from "../Contexte";

function Screen4Bis() {
  const { configuration, updateConfig } = useContext(ConfigContext);
  const categories = [
    {
      id: 1,
      title: "Logistique",
      icon: "fa-truck",
      description: "Transport, montage, stockage post-salon",
      services: [
        { name: "Transport et installation", price: 1200 },
        { name: "Stockage post-salon", price: 500 },
        { name: "Assistance technique sur place", price: 800 },
        { name: "Démontage et retour logistique", price: 1000 },
        { name: "Coordination avec les organisateurs", price: 400 },
      ],
    },
    {
      id: 2,
      title: "Animation",
      icon: "fa-users",
      description: "Hôtesses, Animateurs, Démonstrateurs",
      services: [
        { name: "Hôtesses d'accueil", price: 450 },
        { name: "Animateurs spécialisés", price: 600 },
        { name: "Présentateurs/ conférenciers", price: 800 },
        { name: "Démonstrateurs de produits", price: 550 },
        { name: "Animations interactives", price: 700 },
      ],
    },
    {
      id: 3,
      title: "E-Marketing",
      icon: "fa-bullhorn",
      description: "Stratégies digitales pour attirer du trafic",
      services: [
        { name: "Campagnes publicitaires sur réseaux sociaux", price: 900 },
        { name: "Landing page événementielle personnalisée", price: 1200 },
        { name: "Emailing ciblé", price: 400 },
        { name: "Notifications push", price: 300 },
        { name: "Publicité sur place", price: 600 },
        { name: "Suivi post-salon", price: 500 },
      ],
    },
    {
      id: 4,
      title: "Traiteurs",
      icon: "fa-glass-martini-alt",
      description: "Service traiteur, mixologie et animations",
      services: [
        { name: "Plateau traiteur et champagne", price: 800 },
        { name: "Barman mixologue", price: 600 },
        { name: "Bar VIP sur le stand", price: 1200 },
        { name: "Atelier de découpe de jambon ibérique", price: 900 },
        { name: "Snacking sur stand", price: 400 },
      ],
    },
    {
      id: 5,
      title: "Communication",
      icon: "fa-paint-brush",
      description: "Supports imprimés et objets publicitaires",
      services: [
        { name: "Impression de brochures et flyers", price: 300 },
        { name: "Cartes de visite personnalisées", price: 200 },
        { name: "Kakémonos et roll-ups", price: 400 },
        { name: "Objets publicitaires", price: 600 },
        { name: "Signalétique directionnelle", price: 300 },
        { name: "Stand d'information numérique", price: 800 },
      ],
    },
    {
      id: 6,
      title: "Digital",
      icon: "fa-laptop",
      description: "Solutions pour collecter et gérer vos leads facilement.",
      services: [
        { name: "Collecte de données via QR codes", price: 400 },
        { name: "CRM et gestion des leads", price: 800 },
        { name: "Analyse de fréquentation", price: 600 },
        { name: "Tablettes interactives pour collecte d'avis", price: 700 },
        { name: "Rapports de performance post-salon", price: 500 },
      ],
    },
  ];

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedServices, setSelectedServices] = useState(configuration.services || []);

  // Synchronise `selectedServices` avec `configuration.services` au premier chargement uniquement
  useEffect(() => {
    if (configuration.services) {
      setSelectedServices(configuration.services);
    }
  }, [configuration.services]);

  // Gestion de l’ajout d’un service
  const handleAddService = (service) => {
    if (!selectedServices.some((s) => s.name === service.name)) {
      const updatedServices = [...selectedServices, service];
      setSelectedServices(updatedServices);
      updateConfig("services", updatedServices);
    }
  };

  // Gestion de la suppression d’un service
  const handleRemoveService = (index) => {
    const updatedServices = selectedServices.filter((_, i) => i !== index);
    setSelectedServices(updatedServices);
    updateConfig("services", updatedServices);
  };

  return (
    <div className="p-4 bg-white shadow-md rounded-lg lg:p-6 min-h-screen">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-6">
          Ajoutez des services additionnels à votre stand
        </h1>
        <p className="text-lg text-gray-600 text-center mb-12">
          Complétez votre stand avec des services personnalisés pour attirer davantage de visiteurs et améliorer votre expérience.
        </p>
  
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
          {categories.map((category) => (
            <div
              key={category.id}
              onClick={() => setSelectedCategory(category)}
              className={`p-6 bg-white rounded-lg shadow-md cursor-pointer border ${
                selectedCategory?.id === category.id ? "border-blue-500" : "border-gray-200"
              } hover:shadow-lg transition-all duration-300`}
            >
              <div className="flex items-center space-x-4">
                <i className={`fas ${category.icon} text-2xl text-blue-600`}></i>
                <div>
                  <h3 className="text-xl font-semibold">{category.title}</h3>
                  <p className="text-gray-500 text-sm">{category.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
  
        {selectedCategory && (
          <div className="mt-8">
            <h2 className="text-2xl font-semibold mb-4">
              Services disponibles pour {selectedCategory.title}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {selectedCategory.services.map((service, index) => (
                <div
                  key={index}
                  className="p-4 bg-gray-100 rounded-lg shadow flex justify-between items-center"
                >
                  <div>
                    <h4 className="text-lg font-medium">{service.name}</h4>
                    <p className="text-gray-600">Prix : {service.price} €</p>
                  </div>
                  <button
                    onClick={() => handleAddService(service)}
                    className="text-white bg-blue-600 px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
                  >
                    Ajouter
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
  
        {selectedServices.length > 0 && (
          <div className="mt-12">
            <h2 className="text-2xl font-semibold mb-4">Services sélectionnés</h2>
            <ul className="space-y-3">
              {selectedServices.map((service, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center p-4 bg-white rounded-lg shadow"
                >
                  <span>{service.name} - {service.price} €</span>
                  <button
                    onClick={() => handleRemoveService(index)}
                    className="text-red-600 hover:text-red-800"
                  >
                    Supprimer
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );  
}

export default Screen4Bis;