import React from "react";
import PrestatairesHero from "./PrestatairesHero";
import PrestatairesCategories from "./PrestatairesCategories";
import PrestatairesAnnuaire from "./PrestatairesAnnuaire";
import PrestatairesDemandes from "./PrestatairesDemandes";
import PrestatairesAvantages from "./PrestatairesAvantages";
import PrestatairesCta from "./PrestatairesCta";
import Header from "../Accueil/Header"; // Chemin corrigé pour Header
import Footer from "../Accueil/Footer"; // Chemin corrigé pour Footer

const MainPrestataires = () => {
  return (
    <div className="font-roboto">
      {/* Header Section */}
      <Header />

      {/* Hero Section */}
      <PrestatairesHero />

      {/* Catégories Section */}
      <PrestatairesCategories />

      {/* Annuaire Section */}
      <PrestatairesAnnuaire />

      {/* Demandes Section */}
      <PrestatairesDemandes />

      {/* Avantages Section */}
      <PrestatairesAvantages />

      {/* CTA Section */}
      <PrestatairesCta />

      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default MainPrestataires;
