import React, { useState, useEffect, useRef } from "react";

function Screen7() {
  const [counter, setCounter] = useState(10);
  const canvasRef = useRef(null);

  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => setCounter((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    } else {
      window.location.href = "/espace-exposant";
    }
  }, [counter]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const confettis = [];

    // Fonction pour ajuster les dimensions du canvas
    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    
    // Initialiser les dimensions du canvas avant l'animation
    handleResize();

    // Génération des confettis
    for (let i = 0; i < 150; i++) {
      confettis.push({
        x: Math.random() * canvas.width,
        y: Math.random() * -canvas.height,
        width: Math.random() * 6 + 4,
        height: Math.random() * 8 + 4,
        speed: Math.random() * 1.5 + 0.5,
        angle: Math.random() * 360,
        rotationSpeed: Math.random() * 3 + 0.5,
        color: Math.random() < 0.15
          ? `hsl(50, 100%, ${50 + Math.random() * 20}%)`
          : `hsl(${200 + Math.random() * 60}, 100%, ${50 + Math.random() * 10}%)`,
        drift: Math.random() * 0.5 - 0.25,
      });
    }

    // Fonction d'animation des confettis
    function animateConfetti() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      confettis.forEach((c) => {
        c.angle += c.rotationSpeed;
        const radians = (c.angle * Math.PI) / 180;

        ctx.save();
        ctx.translate(c.x + c.width / 2, c.y + c.height / 2);
        ctx.rotate(radians);

        ctx.fillStyle = c.color;
        ctx.fillRect(-c.width / 2, -c.height / 2, c.width, c.height);
        ctx.restore();

        c.y += c.speed;
        c.x += c.drift;
        if (c.y > canvas.height) {
          c.y = -10;
          c.x = Math.random() * canvas.width;
        }
      });

      requestAnimationFrame(animateConfetti);
    }

    // Commencer l'animation après une courte pause pour stabiliser le canvas
    setTimeout(() => animateConfetti(), 50);

    // Ajuster la taille du canvas lors du redimensionnement
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const redirect = () => {
    window.location.href = "/espace-exposant";
  };

  return (
    <div className="p-6 bg-gradient-to-b from-blue-50 to-blue-100 shadow-xl rounded-lg min-h-screen flex flex-col items-center justify-center relative overflow-hidden text-center">
      <canvas ref={canvasRef} className="absolute top-0 left-0 w-full h-full pointer-events-none"></canvas>

      <div className="z-10 flex flex-col items-center space-y-6 max-w-lg mx-auto px-8 py-12">
        <i className="fas fa-trophy text-6xl text-blue-500 trophy-shine mb-4"></i>
        <h1 className="text-4xl font-extrabold text-blue-700">Félicitations !</h1>

        <h2 className="text-xl font-semibold text-blue-600">
          Votre stand est prêt à être sauvegardé !
        </h2>

        <p className="text-base text-gray-700 mb-4 leading-relaxed">
          Nous finalisons la configuration de votre stand. Vous serez redirigé
          vers votre espace exposant dans <span className="font-semibold">{counter} secondes</span>.
        </p>

        <div className="w-full h-3 bg-blue-300 rounded-full overflow-hidden">
          <div
            className="h-full bg-blue-600 rounded-full transition-all duration-1000 ease-linear"
            style={{ width: `${(counter / 10) * 100}%` }}
          ></div>
        </div>

        <button
          onClick={redirect}
          className="bg-blue-600 hover:bg-blue-700 text-white px-10 py-3 rounded-full text-lg font-semibold shadow-lg hover:shadow-xl transition-transform transform hover:scale-105 focus:outline-none"
        >
          Accéder à mon espace exposant
        </button>
      </div>
    </div>
  );
}

export default Screen7;
