import React from "react";

const PrestatairesCategories = () => {
  const advantages = [
    { icon: "fa-pencil-ruler", title: "Conception de stands" },
    { icon: "fa-truck-loading", title: "Logistique" },
    { icon: "fa-video", title: "Audiovisuel" },
    { icon: "fa-couch", title: "Mobilier" },
    { icon: "fa-laptop-code", title: "Solutions digitales" },
    { icon: "fa-users", title: "Animation" },
    { icon: "fa-utensils", title: "Restauration" },
  ];

  return (
    <section className="py-20 bg-white relative z-20 -mt-24">
      <div className="max-w-7xl mx-auto px-4">
        <div className="bg-white rounded-[2rem] shadow-2xl p-8 md:p-12 transform hover:shadow-3xl transition-all duration-500">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-[#1a1a1a] leading-tight">
            Catégories recherchées par les exposants
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7 gap-8">
            {advantages.map((item, index) => (
              <div
                key={index}
                className="flex flex-col items-center group cursor-pointer transform hover:-translate-y-1 transition-all duration-300"
              >
                <div className="w-20 h-20 bg-[#007bff]/5 rounded-2xl flex items-center justify-center mb-4 group-hover:bg-[#007bff]/10 transition-all duration-300 shadow-lg group-hover:shadow-xl">
                  <i className={`fas ${item.icon} text-3xl text-[#007bff]`}></i>
                </div>
                <p className="text-sm md:text-base text-center font-medium text-[#666] group-hover:text-[#007bff] transition-colors duration-300">
                  {item.title}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrestatairesCategories;
