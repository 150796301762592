// src/Site/Legal/MentionsLegales/MentionsLegales.js
import React from "react";

function MentionsLegales() {
  return (
    <div>
      <h1 className="text-4xl font-bold mb-12 text-[#1e3a8a] font-roboto text-center">
        Mentions Légales
      </h1>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Éditeur du Site
        </h2>
        <div className="text-gray-700 space-y-3">
          <p className="font-semibold">REALITIM</p>
          <p>SARL au capital de 50 000 €</p>
          <p>RCS Nantes : 838 763 902 00049</p>
          <p>Siège social : 34 rue Joncours, 44100 Nantes</p>
          <p>Téléphone : +33 06 61 18 01 15</p>
          <p>Email : contact@realitim.com</p>
          <p>Directeur de la publication et DPO : Arthur Devouge</p>
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Hébergement du Site
        </h2>
        <div className="text-gray-700 space-y-3">
          <p>Nom de l'hébergeur : Vercel, Firebase</p>
          <p>Site internet : <a href="https://vercel.com" className="text-blue-600 hover:underline">https://vercel.com</a>, <a href="https://firebase.google.com" className="text-blue-600 hover:underline">https://firebase.google.com</a></p>
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Propriété intellectuelle
        </h2>
        <p className="text-gray-700 leading-relaxed">
          Tous les éléments graphiques, textuels et de conception présents sur le site Stand3D Builder sont la propriété exclusive de REALITIM, sauf indication contraire. Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite sans l'autorisation écrite préalable de REALITIM.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Données personnelles
        </h2>
        <p className="text-gray-700 leading-relaxed">
          REALITIM s'engage à protéger la vie privée des utilisateurs de la plateforme Stand3D Builder. Conformément à la réglementation en vigueur, notamment au Règlement Général sur la Protection des Données (RGPD), les informations collectées sont destinées exclusivement à REALITIM et ne seront en aucun cas communiquées à des tiers sans consentement préalable. Pour toute question relative au traitement de vos données personnelles, veuillez nous contacter à l'adresse suivante : contact@realitim.com.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Cookies
        </h2>
        <p className="text-gray-700 leading-relaxed">
          Le site Stand3D Builder utilise des cookies pour améliorer la navigation et l'expérience utilisateur. Vous pouvez consulter notre <a href="/cookies" className="text-blue-600 hover:underline">Politique de Cookies</a> pour plus d'informations sur l'utilisation des cookies et sur les moyens de gérer vos préférences.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Limitation de responsabilité
        </h2>
        <p className="text-gray-700 leading-relaxed">
          REALITIM ne saurait être tenue pour responsable de tout dommage direct ou indirect résultant de l'utilisation du site Stand3D Builder. Les informations fournies sur le site sont susceptibles d'évoluer et sont données à titre indicatif.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Contact
        </h2>
        <p className="text-gray-700 leading-relaxed">
          Pour toute question ou demande d'information concernant le site, vous pouvez contacter REALITIM à l'adresse suivante : contact@realitim.com.
        </p>
      </section>
    </div>
  );
}

export default MentionsLegales;
