// src/Site/Legal/CGU/CGU.js
import React from "react";

function CGU() {
  return (
    <div>
      <h1 className="text-4xl font-bold mb-12 text-[#1e3a8a] font-roboto text-center">
        Conditions Générales d'Utilisation
      </h1>
      <section className="mb-12">
        <p className="text-gray-700 mb-6">
          <strong>Date de mise à jour :</strong> 08 novembre 2024
        </p>
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Préambule
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Stand3D est une plateforme éditée par REALITIM, permettant aux
          utilisateurs de créer, configurer et demander des devis pour des
          stands d'exposition en 3D. En accédant et en utilisant la plateforme,
          l'Utilisateur accepte sans réserve les présentes Conditions Générales
          d'Utilisation (CGU).
        </p>
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          1. Définitions
        </h2>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mb-6">
          <li>
            <strong>Plateforme :</strong> Le site internet Stand3D accessible à
            l'adresse <a href="https://stand-3d.com">https://stand-3d.com</a>.
          </li>
          <li>
            <strong>Utilisateur :</strong> Toute personne physique ou morale
            accédant à la plateforme Stand3D.
          </li>
          <li>
            <strong>Prestataire :</strong> Professionnel inscrit sur la
            plateforme proposant des services liés aux stands d'exposition.
          </li>
          <li>
            <strong>REALITIM :</strong> Société éditrice de la plateforme
            Stand3D.
          </li>
        </ul>
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          2. Services proposés
        </h2>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mb-6">
          <li>
            <strong>Création de stands en 3D :</strong> Outil permettant aux
            utilisateurs de configurer des stands d'exposition personnalisés.
          </li>
          <li>
            <strong>Annuaire de prestataires :</strong> Accès à une base de
            données de prestataires spécialisés.
          </li>
          <li>
            <strong>Demande de devis :</strong> Possibilité de solliciter des
            devis auprès des prestataires inscrits.
          </li>
        </ul>
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          3. Accès et utilisation
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          L'accès au Site est libre et gratuit pour tous les Utilisateurs. En
          créant un compte, l'Utilisateur s'engage à fournir des informations
          exactes et à maintenir la confidentialité de ses identifiants. Toute
          activité effectuée depuis son compte sera réputée réalisée par
          l'Utilisateur.
        </p>
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          4. Propriété intellectuelle
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Tous les éléments graphiques, textuels et de conception présents sur
          le site Stand3D sont la propriété exclusive de REALITIM, sauf
          indication contraire. Toute reproduction, modification ou exploitation
          sans l'autorisation de REALITIM est interdite.
        </p>
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          5. Protection des données personnelles
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          REALITIM s'engage à protéger la vie privée des utilisateurs. Les
          informations collectées sont traitées conformément au RGPD et notre{" "}
          <a href="/politique-confidentialite" className="text-blue-700 underline">
            Politique de Confidentialité
          </a>
          .
        </p>
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          6. Cookies
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Le site utilise des cookies pour améliorer l'expérience utilisateur.
          Pour plus de détails, consultez notre{" "}
          <a href="/cookies" className="text-blue-700 underline">
            Politique de Cookies
          </a>
          .
        </p>
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          7. Limitation de responsabilité
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          REALITIM ne saurait être tenue pour responsable de tout dommage
          résultant de l'utilisation ou de l'impossibilité d'utiliser le Site.
          Le Site peut contenir des liens vers des sites tiers, dont REALITIM ne
          peut être tenue responsable du contenu.
        </p>
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          8. Modification des CGU
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          REALITIM se réserve le droit de modifier les présentes CGU à tout
          moment. Les modifications entreront en vigueur dès leur publication
          sur le Site.
        </p>
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          9. Droit applicable et juridiction compétente
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Les présentes CGU sont régies par le droit français. Tout litige
          relatif à l’utilisation du Site sera soumis aux tribunaux compétents
          de Nantes.
        </p>
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          10. Contact
        </h2>
        <p className="text-gray-700 leading-relaxed">
          Pour toute question relative aux CGU, l'Utilisateur peut contacter
          REALITIM :
        </p>
        <ul className="text-gray-700 space-y-2">
          <li>Email : <a href="mailto:contact@realitim.com" className="text-blue-700 underline">contact@realitim.com</a></li>
          <li>Téléphone : +33 06 61 18 01 15</li>
          <li>Adresse : 34 rue Joncours, 44100 Nantes, France</li>
        </ul>
      </section>
    </div>
  );
}

export default CGU;
