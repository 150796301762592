import React, { useState, useEffect } from 'react';
import { auth , db} from '../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore'; 
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCamera } from "@fortawesome/free-solid-svg-icons";
import Header from '../Accueil/Header';
import Footer from '../Accueil/Footer';

function MonProfilDetails() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    role: "",
    companyName: "Entreprise Demo",
    contactPhone: "",
    email: "",
    preferredContact: { call: true, email: false, sms: true },
    address: "",
    city: "",
    postalCode: "",
    country: "",
    newsletter: false,
    createdAt: "",
    profileImage: "/default-profile.jpg",
  });
  const [notification, setNotification] = useState("");
  const [user] = useState(auth.currentUser);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const userDoc = doc(db, "users", user.uid);
        const docSnap = await getDoc(userDoc);
        if (docSnap.exists()) {
          setFormData({ ...formData, ...docSnap.data() });
        }
      }
    };
    fetchUserData();
  }, [user]);

  const handleChange = async (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    showNotification("Modifications enregistrées");

    if (user) {
        const userDocRef = doc(db, "users", user.uid);
        
        try {
            // Vérifiez si le document existe
            const docSnap = await getDoc(userDocRef);
            
            if (docSnap.exists()) {
            // Si le document existe, mettez à jour le champ
            await updateDoc(userDocRef, { [field]: value });
            } else {
            // Si le document n'existe pas, créez-le avec les informations actuelles
            await setDoc(userDocRef, { ...formData, [field]: value });
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour du document:", error);
            showNotification("Erreur lors de la mise à jour du profil");
        }
        }
    };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `profileImages/${user.uid}`);
    await uploadBytes(storageRef, file);

    const downloadURL = await getDownloadURL(storageRef);
    setFormData((prev) => ({ ...prev, profileImage: downloadURL }));

    if (user) {
      const userDoc = doc(db, "users", user.uid);
      await updateDoc(userDoc, { profileImage: downloadURL });
    }
    showNotification("Photo de profil mise à jour");
  };

  const togglePreferredContact = async (type) => {
    const updatedContact = {
      ...formData.preferredContact,
      [type]: !formData.preferredContact[type],
    };
    setFormData((prev) => ({
      ...prev,
      preferredContact: updatedContact,
    }));
    showNotification("Préférences de contact mises à jour");

    if (user) {
      const userDoc = doc(db, "users", user.uid);
      await updateDoc(userDoc, { preferredContact: updatedContact });
    }
  };

  const handleDeleteAccount = async () => {
    if (user) {
      try {
        // Supprime les données de l'utilisateur dans Firestore
        const userDoc = doc(db, "users", user.uid);
        await deleteDoc(userDoc);

        // Déconnecte l'utilisateur
        await signOut(auth);
        console.log("Compte supprimé");

        // Redirige vers la page d'accueil ou une autre page de confirmation de suppression si nécessaire
        window.location.href = '/';
      } catch (error) {
        console.error("Erreur lors de la suppression du compte :", error);
        showNotification("Une erreur est survenue lors de la suppression du compte.");
      }
    }
    setConfirmModalOpen(false); // Ferme la modale après la suppression
  };

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => setNotification(""), 3000);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow p-6 bg-gray-100 text-gray-800">
        <div className="max-w-3xl mx-auto mt-16">
          <h1 className="text-3xl font-bold text-blue-700 mb-2">Mon Profil</h1>
          <p className="text-lg text-gray-600 mb-6">
            Gérez vos informations personnelles et les préférences de votre compte.
          </p>
        </div>

        <div className="max-w-3xl mx-auto space-y-6">
            
        <section className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold text-blue-700 mb-4">Photo de profil</h2>
            <div className="flex items-center gap-6">
                <div className="relative group w-28 h-28">
                    <img
                        src={formData.profileImage || "/default-profile.jpg"}
                        alt="Profile"
                        className="w-full h-full rounded-full object-cover border-2 border-gray-200 shadow-sm transition-transform transform group-hover:scale-105"
                    />
                    <label className="absolute bottom-0 right-0 bg-blue-500 text-white p-2 rounded-full cursor-pointer shadow-md hover:bg-blue-600 transition-colors">
                        <FontAwesomeIcon icon={faCamera} />
                        <input type="file" accept="image/*" className="hidden" onChange={handleImageChange} />
                    </label>
                </div>
            </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold text-blue-700 mb-4">Mon Profil</h2>
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Prénom</label>
                    <input
                        type="text"
                        value={formData.firstName}
                        onChange={(e) => handleChange("firstName", e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Nom</label>
                    <input
                        type="text"
                        value={formData.lastName}
                        onChange={(e) => handleChange("lastName", e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">Fonction</label>
                <input
                    type="text"
                    value={formData.role}
                    onChange={(e) => handleChange("role", e.target.value)}
                    className="w-full p-3 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">Téléphone</label>
                <input
                    type="tel"
                    value={formData.contactPhone}
                    onChange={(e) => handleChange("contactPhone", e.target.value)}
                    className="w-full p-3 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
            </div>
        </section>


        <section className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold text-blue-700 mb-4">Ma Structure</h2>
            <div className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Nom de l'entreprise</label>
                    <input
                        type="text"
                        value={formData.companyName}
                        onChange={(e) => handleChange("companyName", e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Adresse</label>
                    <input
                        type="text"
                        value={formData.address}
                        onChange={(e) => handleChange("address", e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
            </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold text-blue-700 mb-4">Préférences</h2>
            <div>
                <label className="block text-sm font-medium text-gray-700">Moyens de contact préférés</label>
                <div className="flex gap-4 mt-2">
                    {Object.entries(formData.preferredContact).map(([type, checked]) => (
                        <label key={type} className="flex items-center gap-2">
                            <input
                                type="checkbox"
                                checked={checked}
                                onChange={() => togglePreferredContact(type)}
                                className="form-checkbox text-blue-700"
                            />
                            {type === "call" ? "Téléphone" : type === "email" ? "Email" : "SMS"}
                        </label>
                    ))}
                </div>
            </div>
        </section>
          <button
            onClick={() => setConfirmModalOpen(true)}
            className="mt-4 w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 text-sm font-medium"
          >
            <FontAwesomeIcon icon={faTrash} className="mr-2" /> Supprimer mon compte
          </button>
        </div>

        {/* Notification */}
        {notification && (
          <div className="fixed bottom-4 right-4 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg">
            {notification}
          </div>
        )}

        {/* Modale de confirmation */}
        {isConfirmModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-6 shadow-lg max-w-sm w-full">
              <h2 className="text-xl font-semibold mb-4">Confirmer la suppression</h2>
              <p className="mb-6 text-gray-600">Êtes-vous sûr de vouloir supprimer votre compte ? Cette action est irréversible.</p>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => setConfirmModalOpen(false)}
                  className="px-4 py-2 rounded-md text-sm bg-gray-300 hover:bg-gray-400"
                >
                  Annuler
                </button>
                <button
                  onClick={handleDeleteAccount}
                  className="px-4 py-2 rounded-md text-sm bg-blue-500 text-white hover:bg-blue-600"
                >
                  Confirmer
                </button>
              </div>
            </div>
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
}

export default MonProfilDetails;