import React from 'react';
import { LesFormules } from '../../Formules/LesFormules/LesFormules'; // Import du composant LesFormules

function BosMenu({ activeTab, setActiveTab, newQuotesCount }) { // Ajout du paramètre newQuotesCount
  return (
    <nav className="w-64 bg-gray-800 text-white p-4 fixed top-16 left-0 h-full">
      <ul className="space-y-2">
        {/* Onglet Tableau de Bord */}
        <li>
          <button
            onClick={() => setActiveTab('dashboard')}
            className={`w-full text-left p-2 rounded flex items-center ${
              activeTab === 'dashboard' ? 'bg-blue-600 text-white' : 'hover:bg-gray-700'
            }`}
          >
            <i className="fas fa-tachometer-alt mr-2"></i>
            Tableau de Bord
          </button>
        </li>
        
        {/* Onglet Gestion du Profil */}
        <li>
          <button
            onClick={() => setActiveTab('profile')}
            className={`w-full text-left p-2 rounded flex items-center ${
              activeTab === 'profile' ? 'bg-blue-600 text-white' : 'hover:bg-gray-700'
            }`}
          >
            <i className="fas fa-user-circle mr-2"></i>
            Gestion du Profil
          </button>
        </li>

        {/* Onglet Statistiques */}
        <li>
          <button
            onClick={() => setActiveTab('stats')}
            className={`w-full text-left p-2 rounded flex items-center ${
              activeTab === 'stats' ? 'bg-blue-600 text-white' : 'hover:bg-gray-700'
            }`}
          >
            <i className="fas fa-chart-bar mr-2"></i>
            Statistiques Avancées
          </button>
        </li>

        {/* Onglet Mise en Avant Payante */}
        <li>
          <button
            onClick={() => setActiveTab('LesFormules')}
            className={`w-full text-left p-2 rounded flex items-center relative ${
              activeTab === 'LesFormules' ? 'bg-blue-600 text-white' : 'hover:bg-gray-700'
            }`}
          >
            <i className="fas fa-rocket mr-2"></i>
            Mise en Avant Payante
            <span className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-yellow-400 text-xs font-bold px-2 py-1 rounded-full animate-pulse"></span>
          </button>
        </li>

        {/* Onglet Demandes de Devis */}
        <li>
          <button
            onClick={() => setActiveTab('quotes')}
            className={`w-full text-left p-2 rounded flex items-center relative ${
              activeTab === 'quotes' ? 'bg-blue-600 text-white' : 'hover:bg-gray-700'
            }`}
          >
            <i className="fas fa-file-invoice-dollar mr-2"></i>
            Demandes de Devis
            {newQuotesCount > 0 && (
              <span className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-red-500 text-xs font-bold px-2 py-1 rounded-full">
                {newQuotesCount}
              </span>
            )}
          </button>
        </li>

        {/* Onglet Support et Assistance */}
        <li>
          <button
            onClick={() => setActiveTab('support')}
            className={`w-full text-left p-2 rounded flex items-center ${
              activeTab === 'support' ? 'bg-blue-600 text-white' : 'hover:bg-gray-700'
            }`}
          >
            <i className="fas fa-life-ring mr-2"></i>
            Support et Assistance
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default BosMenu;
