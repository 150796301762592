import React, { useContext, useTransition } from "react";
import standConfigurations from "./Composants/ConfigurationStands"; // Assurez-vous que ce fichier exporte les configurations nécessaires
import { ConfigContext } from '../Contexte'; // Chemin ajusté vers Contexte.js

function Screen2() {
  const [isPending, startTransition] = useTransition();
  
  // Accéder à la configuration globale du stand via ConfigContext
  const { configuration: standConfig, setConfiguration } = useContext(ConfigContext);

  const types = [
    { name: "Stand linéaire", icon: "/types-de-stands/stand-lineaire.png", description: "Un stand avec un seul côté ouvert, idéal pour les petits espaces." },
    { name: "Stand d'angle", icon: "/types-de-stands/stand-d-angle.png", description: "Un stand avec deux côtés ouverts, offrant une meilleure visibilité." },
    { name: "Stand Ilot", icon: "/types-de-stands/stand-ilot.png", description: "Un stand ouvert sur tous les côtés, parfait pour une grande visibilité." },
    { name: "StandVR Visio", icon: "/types-de-stands/stand-vr.png", description: "Un stand virtuel pour une expérience immersive à distance." },
  ];

  // Fonction pour gérer la sélection du type de stand et mettre à jour le contexte
  const handleTypeSelection = (typeKey) => {
    const config = standConfigurations[typeKey];
    
    startTransition(() => {
      // Mise à jour du contexte avec le type de stand sélectionné
      setConfiguration((prevConfig) => ({
        ...prevConfig,
        type: typeKey,
        selectedStandConfig: config,
      }));
    });
  };

  return (
    <div className="p-4 bg-white shadow-md rounded-lg lg:p-6">
      <h2 className="text-xl lg:text-2xl font-bold mb-4 lg:mb-6 text-blue-800">
        Sélectionnez le type de stand
      </h2>
  
      {/* Affiche les options de types de stand */}
      <div className="grid grid-cols-2 gap-6">
        {types.map((type) => (
          <button
            key={type.name}
            onClick={() => handleTypeSelection(type.name)}
            className={`p-6 border-2 rounded-lg flex flex-col items-center justify-center transition-all duration-300 ${
              standConfig.type === type.name
                ? "bg-blue-600 text-white border-blue-700 shadow-lg"
                : "bg-white text-blue-800 border-blue-300 hover:border-blue-500 hover:bg-blue-50"
            }`}
            title={type.description}
          >
            <img src={type.icon} alt={type.name} className="w-16 h-16 mb-3" />
            <span className="text-lg font-semibold">{type.name}</span>
            <span className="text-sm mt-2 text-center">{type.description}</span>
          </button>
        ))}
      </div>
    </div>
  );
  
}

export default Screen2;
