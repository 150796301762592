import React from "react";

function Actus() {
  const [articles] = React.useState([
    {
      title: "Maximisez l'Impact de Votre Stand",
      excerpt: "Découvrez nos conseils experts pour créer un stand qui capte l'attention.",
      image: "/Ressources/Ressource1.jpeg"
    },
    {
      title: "Tendances 2024 des Salons Professionnels",
      excerpt: "Les dernières innovations qui façonnent l'industrie des expositions.",
      image: "/Ressources/Ressource2.jpeg"
    },
    {
      title: "StandVR : Exposez sans vous déplacer",
      excerpt: "Comment la réalité virtuelle transforme l'art d'exposer'.",
      image: "/Ressources/Ressource3.jpeg"
    }
  ]);

  const [tutorials] = React.useState([
    {
      title: "Guide du Configurateur 3D",
      excerpt: "Apprenez à utiliser notre outil 3D pour concevoir votre stand parfait.",
      image: "/Ressources/Ressource4.jpeg"
    },
    {
      title: "Planifier une Exposition Réussie",
      excerpt: "Étapes clés pour organiser une exposition mémorable.",
      image: "/Ressources/Ressource5.jpeg"
    }
  ]);

  return (
    <div className="min-h-screen bg-white p-12">
      <header className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-800 mb-4 font-montserrat">Tendances & Astuces pour Vos Expositions</h1>
        <p className="text-xl text-gray-600 font-roboto">Votre source d'inspiration pour des stands d'exposition exceptionnels</p>
      </header>

      <main>
        {/* Section Articles */}
        <section className="mb-16">
          <h2 className="text-3xl font-semibold mb-8 text-gray-700 font-montserrat">Articles Récents</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {articles.map((article, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
                <img src={article.image} alt={article.title} className="w-full h-48 object-cover" />
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2 font-roboto">{article.title}</h3>
                  <p className="text-gray-600 font-roboto">{article.excerpt}</p>
                  <a href={`/articles/${index}`} className="mt-4 inline-block text-blue-600 hover:text-blue-800 font-roboto">Lire la suite</a>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Section Tutoriels */}
        <section>
          <h2 className="text-3xl font-semibold mb-8 text-gray-700 font-montserrat">Tutoriels</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {tutorials.map((tutorial, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden flex">
                <img src={tutorial.image} alt={tutorial.title} className="w-1/3 object-cover" />
                <div className="p-6 w-2/3">
                  <h3 className="text-xl font-semibold mb-2 font-roboto">{tutorial.title}</h3>
                  <p className="text-gray-600 font-roboto">{tutorial.excerpt}</p>
                  <a href={`/tutorials/${index}`} className="mt-4 inline-block text-green-600 hover:text-green-800 font-roboto">Voir le tutoriel</a>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
}

export default Actus;
