import React, { useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { FaUser, FaEnvelope, FaMobileAlt, FaBriefcase, FaPhoneAlt } from "react-icons/fa";

function Screen6Ter({ updateConfig }) {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    position: "",
    preferredContact: {
      email: true,
      call: true,
      sms: true,
    },
    contactEmail: "",
    mobileNumber: "",
  });

  // Récupérer l'email de l'utilisateur
  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const userId = auth.currentUser.uid;
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          setFormValues((prevValues) => ({
            ...prevValues,
            contactEmail: userDoc.data().email,
          }));
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de l'email de l'utilisateur :", error);
      }
    };
    fetchUserEmail();
  }, []);

  // Mettre à jour les informations de contact avec filtrage des champs vides
  useEffect(() => {
    const filteredFormValues = Object.fromEntries(
      Object.entries(formValues).filter(([_, value]) => value)
    );
    updateConfig("contactInfo", filteredFormValues);
  }, [formValues, updateConfig]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormValues((prevValues) => ({
        ...prevValues,
        preferredContact: {
          ...prevValues.preferredContact,
          [name]: checked,
        },
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-xl max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-6">
      <h2 className="col-span-1 md:col-span-2 text-3xl font-extrabold mb-2 text-blue-800 text-center">
        Informations de Contact
      </h2>
      <form className="col-span-1 md:col-span-2 space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label htmlFor="firstName" className="text-sm font-medium text-gray-700 flex items-center gap-2">
              <FaUser /> Prénom
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formValues.firstName}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 shadow-sm"
              required
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="lastName" className="text-sm font-medium text-gray-700 flex items-center gap-2">
              <FaUser /> Nom
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formValues.lastName}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 shadow-sm"
              required
            />
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="position" className="text-sm font-medium text-gray-700 flex items-center gap-2">
            <FaBriefcase /> Fonction
          </label>
          <input
            type="text"
            id="position"
            name="position"
            value={formValues.position}
            onChange={handleChange}
            className="mt-1 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 shadow-sm"
          />
        </div>

        <div className="flex flex-col md:col-span-2">
          <label className="text-sm font-medium text-gray-700 flex items-center gap-2">
            <FaPhoneAlt /> Mode de contact privilégié
          </label>
          <div className="flex items-center mt-2 space-x-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="email"
                checked={formValues.preferredContact.email || false}
                onChange={handleChange}
                className="mr-2"
              />
              Email
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="call"
                checked={formValues.preferredContact.call || false}
                onChange={handleChange}
                className="mr-2"
              />
              Appel Mobile
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="sms"
                checked={formValues.preferredContact.sms || false}
                onChange={handleChange}
                className="mr-2"
              />
              SMS
            </label>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label htmlFor="contactEmail" className="text-sm font-medium text-gray-700 flex items-center gap-2">
              <FaEnvelope /> Email de contact
            </label>
            <input
              type="email"
              id="contactEmail"
              name="contactEmail"
              value={formValues.contactEmail}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 shadow-sm"
              required
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="mobileNumber" className="text-sm font-medium text-gray-700 flex items-center gap-2">
              <FaMobileAlt /> Numéro de mobile
            </label>
            <input
              type="tel"
              id="mobileNumber"
              name="mobileNumber"
              value={formValues.mobileNumber}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 shadow-sm"
              required
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default Screen6Ter;
