import React from "react";
import { useNavigate } from "react-router-dom";

const PrestatairesCta = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/onboarding");
  };

  return (
    <section className="py-32 px-4 bg-gradient-to-br from-[#007bff] via-[#0056b3] to-[#004094] text-white text-center relative overflow-hidden">
      <div
        className="absolute inset-0 opacity-5"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/stands-demo/stand-demo-1.jpeg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div className="max-w-5xl mx-auto relative z-10">
        <h2 className="text-4xl md:text-5xl font-bold mb-8 leading-tight">
          Rejoignez l’annuaire leader et connectez-vous dès aujourd’hui avec des exposants qualifiés
        </h2>
        <p className="text-lg md:text-xl mb-12">
          Générez plus de visibilité et recevez des demandes de devis qualifiées en rejoignant notre réseau de prestataires de stands.
        </p>
        <div className="flex flex-col md:flex-row gap-6 justify-center">
          <button 
            onClick={handleButtonClick}
            className="bg-white text-[#007bff] hover:bg-[#f8f9fa] font-bold py-4 px-12 rounded-2xl text-2xl transition-all duration-300 transform hover:scale-105 hover:shadow-2xl"
          >
            Inscription gratuite
          </button>
        </div>
      </div>
    </section>
  );
};

export default PrestatairesCta;
