import React, { useState } from 'react';
import BosProfilMenu from './BosProfilMenu'; // Menu supérieur
import BosProfilEntreprise from './BosProfilEntreprise'; // Composant pour "Mon Entreprise"
import BosProfilCategories from './BosProfilCategories';
import BosProfilProduits from './BosProfilProduits'; // Composant pour "Mes Produits"
import BosProfilRealisations from './BosProfilRealisations'; // Composant pour "Mes Réalisations"

function MainBosProfil() {
  const [currentScreen, setCurrentScreen] = useState('entreprise'); // Onglet par défaut

  // Fonction pour rendre le contenu en fonction de l'onglet sélectionné
  const renderContent = () => {
    switch (currentScreen) {
      case 'entreprise':
        return <BosProfilEntreprise />;
      case 'categories':
        return <BosProfilCategories />;
      case 'produits':
        return <BosProfilProduits />;
      case 'realisations':
        return <BosProfilRealisations />;
      default:
        return <BosProfilEntreprise />;
    }
  };

  return (
    <div className="font-roboto bg-gray-100 min-h-screen">
      {/* Menu supérieur épinglé */}
      <div className="sticky top-0 z-10 bg-white shadow-md">
        <BosProfilMenu currentScreen={currentScreen} setCurrentScreen={setCurrentScreen} />
      </div>

      {/* Contenu principal */}
      <div className="container mx-auto px-4 py-8">
        {renderContent()}
      </div>
    </div>
  );
}

export default MainBosProfil;
