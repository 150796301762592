const StandsElements = {
Decoration: [
    { name: "arbre-50x100", path: "/public/assets3d/arbre-50x100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#228B22" }, // Vert pour les plantes
    { name: "arbre-100x200", path: "/public/assets3d/arbre-100x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#228B22" },
    { name: "arbre-150x250", path: "/public/assets3d/arbre-150x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#228B22" },
    { name: "cafetiere", path: "/public/assets3d/cafetiere.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#A9A9A9" }, // Gris neutre pour la cafetière
    { name: "mur-vegetal-100x250", path: "/public/assets3d/mur-vegetal-100x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#228B22" }, // Vert pour le mur végétal
    { name: "mur-vegetal-200x250", path: "/public/assets3d/mur-vegetal-200x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#228B22" },
    { name: "mur-vegetal-300x250", path: "/public/assets3d/mur-vegetal-300x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#228B22" },
    { name: "panneau-mural-bois-100x250", path: "/public/assets3d/panneau-mural-bois-100x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#8B4513" }, // Marron pour le bois
    { name: "panneau-mural-bois-200x250", path: "/public/assets3d/panneau-mural-bois-200x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#8B4513" },
    { name: "panneau-mural-bois-300x250", path: "/public/assets3d/panneau-mural-bois-300x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#8B4513" },
    { name: "plante-colonne-100", path: "/public/assets3d/plante-colonne-100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#228B22" }, // Vert pour les plantes
    { name: "plante-colonne-150", path: "/public/assets3d/plante-colonne-150.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#228B22" },
    { name: "plante-colonne-200", path: "/public/assets3d/plante-colonne-200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#228B22" }
],

Digital: [
    { name: "standvr-90x190", path: "/public/assets3d/standvr-90x190.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#1E90FF" }, // Bleu pour l'expérience VR
    { name: "borne-interactive-tablette", path: "/public/assets3d/borne-interactive-tablette.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#1E90FF" }, // Bleu pour les bornes interactives
    { name: "borne-interactive-43", path: "/public/assets3d/borne-interactive-43.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#1E90FF" },
    { name: "borne-interactive-55", path: "/public/assets3d/borne-interactive-55.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#1E90FF" },
    { name: "ecran-mural-100x60", path: "/public/assets3d/ecran-mural-100x60.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#000000" }, // Noir pour les écrans muraux
    { name: "ecran-mural-125x75", path: "/public/assets3d/ecran-mural-125x75.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#000000" },
    { name: "ecran-mural-222x125", path: "/public/assets3d/ecran-mural-222x125.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#000000" },
    { name: "ecran-sur-pied-43", path: "/public/assets3d/ecran-sur-pied-43.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#1E90FF" }, // Bleu pour les écrans sur pied
    { name: "ecran-sur-pied-55", path: "/public/assets3d/ecran-sur-pied-55.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#1E90FF" },
    { name: "ecran-sur-pied-73", path: "/public/assets3d/ecran-sur-pied-73.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#1E90FF" },
    { name: "ordinateur-portable", path: "/public/assets3d/ordinateur-portable.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#1E90FF" }, // Bleu pour les appareils numériques
    { name: "tablette", path: "/public/assets3d/tablette.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#1E90FF" },
    { name: "totem-video-43", path: "/public/assets3d/totem-video-43.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#1E90FF" }, // Bleu pour les totems
    { name: "totem-video-55", path: "/public/assets3d/totem-video-55.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#1E90FF" },
    { name: "totem-video-65", path: "/public/assets3d/totem-video-65.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#1E90FF" }
  ],
Lighting: [
    { name: "applique-murale-50", path: "/public/assets3d/applique-murale-50.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" }, // Blanc pour l'applique murale
    { name: "lustre-leds-100", path: "/public/assets3d/lustre-led-100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFD700" }, // Jaune clair pour les lustres LED
    { name: "lustre-leds-150", path: "/public/assets3d/lustre-led-150.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFD700" },
    { name: "lustre-leds-200", path: "/public/assets3d/lustre-led-200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFD700" }
  ],
  
Mobilier: [
    { name: "comptoir-droit-90", path: "/public/assets3d/comptoir-droit-90.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" }, // Gris foncé pour les comptoirs droits
    { name: "comptoir-droit-100", path: "/public/assets3d/comptoir-droit-100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" },
    { name: "comptoir-droit-120", path: "/public/assets3d/comptoir-droit-120.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" },
    { name: "comptoir-droit-200", path: "/public/assets3d/comptoir-droit-200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" },
    { name: "comptoir-courbe-90", path: "/public/assets3d/comptoir-courbe-90.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" }, // Gris foncé pour les comptoirs courbes
    { name: "comptoir-courbe-100", path: "/public/assets3d/comptoir-courbe-100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" },
    { name: "comptoir-courbe-120", path: "/public/assets3d/comptoir-courbe-120.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" },
    { name: "commode-80", path: "/public/assets3d/commode-80.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#A52A2A" }, // Brun clair pour les commodes
    { name: "commode-100", path: "/public/assets3d/commode-100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#A52A2A" },
    { name: "commode-200", path: "/public/assets3d/commode-200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#A52A2A" },
    { name: "mange-debout-90", path: "/public/assets3d/mange-debout-90.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" }, // Gris pour les tables mange-debout
    { name: "mange-debout-100", path: "/public/assets3d/mange-debout-100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" },
    { name: "mange-debout-120", path: "/public/assets3d/mange-debout-120.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" },
    { name: "Fauteuil-100", path: "/public/assets3d/Fauteuil-100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#F5F5DC" }, // Beige pour les fauteuils
    { name: "banquette-100", path: "/public/assets3d/banquette-100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#F5F5DC" }, // Beige pour les banquettes
    { name: "banquette-150", path: "/public/assets3d/banquette-150.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#F5F5DC" },
    { name: "banquette-200", path: "/public/assets3d/banquette-200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#F5F5DC" },
    { name: "table-basse-100", path: "/public/assets3d/table-basse-100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" }, // Gris pour les tables basses
    { name: "table-basse-150", path: "/public/assets3d/table-basse-150.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" },
    { name: "table-basse-200", path: "/public/assets3d/table-basse-200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" },
    { name: "colonne-carree-80", path: "/public/assets3d/colonne-carree-80.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" }, // Gris pour les colonnes carrées
    { name: "colonne-carree-100", path: "/public/assets3d/colonne-carree-100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" },
    { name: "colonne-carree-120", path: "/public/assets3d/colonne-carree-120.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" },
    { name: "colonne-ronde-80", path: "/public/assets3d/colonne-ronde-80.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" }, // Gris pour les colonnes rondes
    { name: "colonne-ronde-100", path: "/public/assets3d/colonne-ronde-100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" },
    { name: "colonne-ronde-120", path: "/public/assets3d/colonne-ronde-120.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#808080" },
    { name: "etagere-80", path: "/public/assets3d/etagere-80.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#A52A2A" }, // Brun clair pour les étagères
    { name: "etagere-100", path: "/public/assets3d/etagere-100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#A52A2A" },
    { name: "etagere-200", path: "/public/assets3d/etagere-200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#A52A2A" }
  ],

Signaletique: [
    { name: "cadre-paysage-25x50", path: "/public/assets3d/cadre-paysage-25x50.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#87CEEB" }, // Bleu clair pour les cadres paysage
    { name: "cadre-paysage-50x100", path: "/public/assets3d/cadre-paysage-50x100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#87CEEB" },
    { name: "cadre-paysage-100x200", path: "/public/assets3d/cadre-paysage-100x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#87CEEB" },
    { name: "cadre-portrait-25x50", path: "/public/assets3d/cadre-portrait-25x50.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#87CEEB" }, // Bleu clair pour les cadres portrait
    { name: "cadre-portrait-50x100", path: "/public/assets3d/cadre-portrait-50x100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#87CEEB" },
    { name: "cadre-portrait-100x200", path: "/public/assets3d/cadre-portrait-100x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#87CEEB" },
    { name: "kakemono-85x200", path: "/public/assets3d/kakemono-85x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#87CEEB" }, // Bleu clair pour les kakemonos
    { name: "kakemono-100x200", path: "/public/assets3d/kakemono-100x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#87CEEB" },
    { name: "kakemono-150x200", path: "/public/assets3d/kakemono-150x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#87CEEB" },
    { name: "oriflamme-arrondie-300", path: "/public/assets3d/oriflamme-arrondie-300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#8B0000" }, // Rouge foncé pour les oriflammes arrondies
    { name: "oriflamme-carree-300", path: "/public/assets3d/oriflamme-carree-300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#8B0000" },
    { name: "presentoir-a4", path: "/public/assets3d/presentoir-a4.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#D3D3D3" }, // Gris clair pour les présentoirs
    { name: "presentoir-100x150", path: "/public/assets3d/presentoir-100x150.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#D3D3D3" },
    { name: "presentoir-50x150", path: "/public/assets3d/presentoir-50x150.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#D3D3D3" },
    { name: "enseigne-suspendue-carree-100", path: "/public/assets3d/enseigne-suspendue-carree-100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" }, // Blanc pour les enseignes suspendues carrées
    { name: "enseigne-suspendue-carree-140", path: "/public/assets3d/enseigne-suspendue-carree-140.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "enseigne-suspendue-carree-200", path: "/public/assets3d/enseigne-suspendue-carree-200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "enseigne-suspendue-ronde-100", path: "/public/assets3d/enseigne-suspendue-ronde-100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" }, // Blanc pour les enseignes suspendues rondes
    { name: "enseigne-suspendue-ronde-140", path: "/public/assets3d/enseigne-suspendue-ronde-140.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "enseigne-suspendue-ronde-200", path: "/public/assets3d/enseigne-suspendue-ronde-200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" }
  ],

floors: [
    { name: "sol-300x200", path: "/public/assets3d/sol-300x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "sol-200x100", path: "/public/assets3d/sol-200x100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "sol-200x200", path: "/public/assets3d/sol-200x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "sol-300x300", path: "/public/assets3d/sol-300x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "sol-400x300", path: "/public/assets3d/sol-400x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "sol-400x400", path: "/public/assets3d/sol-400x400.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "sol-500x300", path: "/public/assets3d/sol-500x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "sol-500x500", path: "/public/assets3d/sol-500x500.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "sol-600x300", path: "/public/assets3d/sol-600x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "sol-600x600", path: "/public/assets3d/sol-600x600.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "sol-900x600", path: "/public/assets3d/sol-900x600.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" }
  ],

"Stand d'angle": [
    { name: "stand-angle-200x100", path: "/public/assets3d/stand-angle-200x100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-angle-200x200", path: "/public/assets3d/stand-angle-200x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-angle-300x200", path: "/public/assets3d/stand-angle-300x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-angle-300x300", path: "/public/assets3d/stand-angle-300x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-angle-400x300", path: "/public/assets3d/stand-angle-400x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-angle-400x400", path: "/public/assets3d/stand-angle-400x400.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-angle-500x300", path: "/public/assets3d/stand-angle-500x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-angle-500x500", path: "/public/assets3d/stand-angle-500x500.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-angle-600x300", path: "/public/assets3d/stand-angle-600x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-angle-600x600", path: "/public/assets3d/stand-angle-600x600.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-angle-900x600", path: "/public/assets3d/stand-angle-900x600.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" }
  ],

"Stand linéaire": [
    { name: "stand-lineaire-200x100", path: "/public/assets3d/stand-lineaire-200x100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-lineaire-200x200", path: "/public/assets3d/stand-lineaire-200x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-lineaire-300x200", path: "/public/assets3d/stand-lineaire-300x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-lineaire-300x300", path: "/public/assets3d/stand-lineaire-300x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-lineaire-400x300", path: "/public/assets3d/stand-lineaire-400x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-lineaire-400x400", path: "/public/assets3d/stand-lineaire-400x400.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-lineaire-500x300", path: "/public/assets3d/stand-lineaire-500x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-lineaire-500x500", path: "/public/assets3d/stand-lineaire-500x500.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-lineaire-600x300", path: "/public/assets3d/stand-lineaire-600x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-lineaire-600x600", path: "/public/assets3d/stand-lineaire-600x600.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-lineaire-900x600", path: "/public/assets3d/stand-lineaire-900x600.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" }
  ],

Structures: [
    { name: "cloison-100x250", path: "/public/assets3d/cloison-100x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "cloison-200x250", path: "/public/assets3d/cloison-200x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "cloison-300x250", path: "/public/assets3d/cloison-300x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "cloison-400x250", path: "/public/assets3d/cloison-400x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "cloison-500x250", path: "/public/assets3d/cloison-500x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "cloison-600x250", path: "/public/assets3d/cloison-600x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "arche-100x250x100", path: "/public/assets3d/arche-100x250x100.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "arche-100x250x200", path: "/public/assets3d/arche-100x250x200.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "arche-100x250x300", path: "/public/assets3d/arche-100x250x300.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "vestiare-100x100x250", path: "/public/assets3d/vestiare-100x100x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "vestiare-100x200x250", path: "/public/assets3d/vestiare-100x200x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-parapluie-courbe-200x250", path: "/public/assets3d/stand-parapluie-courbe-200x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-parapluie-courbe-300x250", path: "/public/assets3d/stand-parapluie-courbe-300x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-parapluie-courbe-400x250", path: "/public/assets3d/stand-parapluie-courbe-400x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-parapluie-droit-100x250", path: "/public/assets3d/stand-parapluie-droit-100x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-parapluie-droit-200x250", path: "/public/assets3d/stand-parapluie-droit-200x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" },
    { name: "stand-parapluie-droit-400x250", path: "/public/assets3d/stand-parapluie-droit-400x250.glb", position: [0, 0, 0], rotation: [0, 0, 0], color: "#FFFFFF" }
  ]
};

export default StandsElements;