import React, { createContext, useState } from "react";

// Création du contexte de configuration pour le stand
export const ConfigContext = createContext();

// Fournisseur de contexte pour partager la configuration globale du stand dans l'application
export const ConfigProvider = ({ children }) => {
  // Initialisation de la configuration avec une structure par défaut
  const [configuration, setConfiguration] = useState({
    floor: null,                  // Modèle de sol sélectionné
    floorSize: "300x300",         // Taille par défaut du stand (floorSize utilisé pour correspondre aux modèles 3D)
    elements: [],                 // Autres éléments sélectionnés pour le stand
    services: [],                 // Services additionnels sélectionnés
    type: "",                     // Type de stand sélectionné
    selectedStandConfig: null,    // Configuration du stand sélectionné
  });

  // Fonction pour mettre à jour des parties spécifiques de la configuration
  const updateConfig = (key, value) => {
    setConfiguration((prevConfig) => ({
      ...prevConfig,
      [key]: value,
    }));
  };

  return (
    <ConfigContext.Provider value={{ configuration, setConfiguration, updateConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};
