// src/Site/Legal/PolitiqueCookies/PolitiqueCookies.js
import React from "react";

function PolitiqueCookies() {
  return (
    <div>
      <h1 className="text-4xl font-bold mb-12 text-[#1e3a8a] font-roboto text-center">
        Politique de Gestion des Cookies
      </h1>
      <section className="mb-12">
        <p className="text-gray-700 mb-6">
          <strong>Date de mise à jour :</strong> 7 novembre 2024
        </p>
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Qu'est-ce qu'un cookie ?
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Un cookie est un petit fichier texte enregistré sur votre appareil
          (ordinateur, smartphone, tablette) lors de votre visite sur notre site
          internet. Il permet de stocker des informations sur vos préférences
          et votre navigation pour améliorer votre expérience utilisateur.
        </p>
      </section>
      
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Types de cookies utilisés
        </h2>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mb-6">
          <li>
            <strong>Cookies essentiels :</strong> Ces cookies sont nécessaires
            au bon fonctionnement du site et vous permettent de naviguer et
            d'utiliser ses fonctionnalités de base.
          </li>
          <li>
            <strong>Cookies de performance :</strong> Ils nous aident à
            comprendre comment les utilisateurs interagissent avec notre site
            en collectant des données anonymes pour améliorer le contenu.
          </li>
          <li>
            <strong>Cookies de personnalisation :</strong> Ces cookies
            permettent de mémoriser vos préférences afin de vous offrir une
            expérience personnalisée.
          </li>
          <li>
            <strong>Cookies publicitaires :</strong> Ces cookies sont utilisés
            pour vous présenter des publicités pertinentes et pour mesurer
            l'efficacité des campagnes publicitaires.
          </li>
        </ul>
      </section>
      
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Gestion des cookies
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Lors de votre première visite, une bannière vous informe de l'utilisation
          des cookies. Vous pouvez accepter tous les cookies, refuser les cookies
          non essentiels ou personnaliser vos préférences. Vous pouvez également
          configurer votre navigateur pour bloquer les cookies ou pour vous informer
          lorsqu'un cookie est émis.
        </p>
      </section>
      
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Durée de conservation
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Les cookies ont une durée de vie limitée en fonction de leur type. La
          durée maximale de conservation est de 13 mois, conformément aux
          recommandations de la CNIL.
        </p>
      </section>
      
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Modifications de la politique de cookies
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          REALITIM se réserve le droit de modifier cette Politique de Cookies
          pour se conformer aux évolutions réglementaires ou pour refléter tout
          changement dans nos pratiques de traitement des données.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Contact
        </h2>
        <p className="text-gray-700 leading-relaxed">
          Pour toute question relative à cette politique ou pour exercer vos
          droits concernant vos données personnelles, contactez-nous à :
          contact@realitim.com.
        </p>
      </section>
    </div>
  );
}

export default PolitiqueCookies;
