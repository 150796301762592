"use client";
import React from "react";
import BOExposant from "./BOExposant";
import Header from "../Site/Accueil/Header";
import Footer from "../Site/Accueil/Footer";
import "firebase/auth";
import "firebase/firestore";

function MainBoe() {
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-gray-50 to-gray-100">
      {/* Header */}
      <Header />

      {/* Main Content */}
      <main className="flex-grow mt-0 px-4 sm:px-8 lg:px-16 pt-8 pb-20">
        <div className="max-w-7xl mx-auto bg-white shadow-lg rounded-lg p-6 md:p-10 lg:p-12">
          <BOExposant initialNotifications={3} />
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default MainBoe;
