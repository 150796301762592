import React, { useState } from 'react'; 
import BosMenu from './BosMenu'; // Menu latéral
import BosBoard from './BosBoard'; // Tableau de bord principal
import BosHeader from './BosHeader'; // En-tête
import MainBosProfil from './BosProfil/MainBosProfil';
import LesFormules from '../../Formules/LesFormules/LesFormules';
import MainRecherche from '../../Demandes/MainRecherche';

function MainBos() {
  const [activeTab, setActiveTab] = useState('espace-exposant'); // Onglet actif par défaut
  const [showNotifications, setShowNotifications] = useState(false); // Gestion des notifications
  const notificationsCount = 3; // Nombre de notifications (statique pour cet exemple)

  const handleNotificationToggle = () => {
    setShowNotifications(!showNotifications);
  };

  const handleLogout = () => {
    // Fonction de déconnexion
    console.log('Déconnexion');
  };

  // Fonction pour afficher le contenu selon l'onglet actif
  const renderContent = () => {
    switch (activeTab) {
      case 'dashboard':
        return <BosBoard />;
      case 'profile':
        return <MainBosProfil />;
      case 'stats':
        return <div>Statistiques avancées (à implémenter)</div>;
      case 'LesFormules':
        return <LesFormules />;
      case 'MainRecherche':
        return <MainRecherche />;
      case 'support':
        return <div>Support et assistance (à implémenter)</div>;
      default:
        return <div>Contenu non disponible</div>;
    }
  };


  return (
    <div className="flex h-screen flex-col">
      {/* Header en tant que "Maître Ultime" (fixé en haut) */}
      <div className="fixed top-0 left-0 w-full z-30">
        <BosHeader
          onNotificationToggle={handleNotificationToggle}
          notificationsCount={notificationsCount}
          onLogout={handleLogout}
        />
      </div>

      {/* Contenu principal avec menu latéral */}
      <div className="flex flex-1 mt-16">
        {/* Menu latéral fixé */}
        <div className="fixed top-16 left-0 w-64 h-full bg-gray-800 z-20">
          <BosMenu activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>
        
        {/* Rendu du contenu avec une marge pour éviter de recouvrir le menu */}
        <main className="ml-64 flex-1 overflow-y-auto bg-gray-100 p-6">
          {renderContent()}
        </main>
      </div>
    </div>
  );
}

export default MainBos;
