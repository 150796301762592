"use client";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../firebase"; // Assurez-vous que Firebase est configuré et importé
import { collection, addDoc } from "firebase/firestore"; // Import des fonctions Firestore

function Footer({ newsletterPlaceholder = "Votre email", buttonText = "S'inscrire" }) {
  const [email, setEmail] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const handleNewsletterSubmit = async (e) => {
    e.preventDefault();
    try {
      // Enregistrer l'email dans Firestore
      await addDoc(collection(db, "users"), {
        email: email,
        subscribedAt: new Date(),
      });
      setShowPopup(true); // Affiche le popup de confirmation
      setEmail(""); // Réinitialise le champ email
    } catch (error) {
      console.error("Erreur d'enregistrement :", error);
    }
  };

  return (
    <footer className="bg-gradient-to-b from-[#0a1525] to-[#050a14] text-gray-300 py-16 font-roboto">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          <div>
            <h3 className="text-xl font-bold mb-6 text-white border-b border-[#1a2942] pb-2">Découvrir Stand3D</h3>
            <ul className="space-y-3">
              <li><Link to="/configurateur" className="hover:text-white transition-colors duration-200">Configurateur 3D</Link></li>
              <li><Link to="/annuaire" className="hover:text-white transition-colors duration-200">Annuaire des prestataires</Link></li>
              <li><Link to="/ressources" className="hover:text-white transition-colors duration-200">FAQ</Link></li>
              <li><Link to="/ressources" className="hover:text-white transition-colors duration-200">Blog</Link></li>
              <li><Link to="/qui-sommes-nous" className="hover:text-white transition-colors duration-200">Qui sommes nous ?</Link></li>
              <li><Link to="/contact" className="hover:text-white transition-colors duration-200">Contact</Link></li>
              <li><Link to="/ressources" className="hover:text-white transition-colors duration-200">Ressources</Link></li>
            </ul>
          </div>

          <div>
            <h3 className="text-xl font-bold mb-6 text-white border-b border-[#1a2942] pb-2">Espace Exposant</h3>
            <ul className="space-y-3">
              <li><Link to="/espace-exposant" className="hover:text-white transition-colors duration-200">Espace Exposant</Link></li>
              <li><Link to="/configurateur" className="hover:text-white transition-colors duration-200">Créer un stand en 3D</Link></li>
              <li><Link to="/annuaire" className="hover:text-white transition-colors duration-200">Trouver un prestataire</Link></li>
              <li><Link to="/devis" className="hover:text-white transition-colors duration-200">Devis rapide</Link></li>
            </ul>
          </div>

          <div>
            <h3 className="text-xl font-bold mb-6 text-white border-b border-[#1a2942] pb-2">Espace Prestataire</h3>
            <ul className="space-y-3">
              <li><Link to="/espace-prestataire" className="hover:text-white transition-colors duration-200">Espace Prestataire</Link></li>
              <li><Link to="/onboarding" className="hover:text-white transition-colors duration-200">Inscription Prestataire</Link></li>
              <li><Link to="/demande" className="hover:text-white transition-colors duration-200">Demandes de devis Exposants</Link></li>
              <li><Link to="/formules" className="hover:text-white transition-colors duration-200">Formules et Tarifs</Link></li>
              <li><Link to="/devenir-prestataire" className="hover:text-white transition-colors duration-200">Ajoutez votre entreprise</Link></li>
            </ul>
          </div>

          <div>
            <h3 className="text-xl font-bold mb-6 text-white border-b border-[#1a2942] pb-2">Newsletter</h3>
            <form onSubmit={handleNewsletterSubmit} className="mb-8">
              <div className="flex flex-col space-y-3">
                <input
                  type="email"
                  name="email"
                  placeholder={newsletterPlaceholder}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-[#0f1f35] px-4 py-3 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-[#4a90e2] transition-all duration-200"
                  required
                />
                <button
                  type="submit"
                  className="bg-[#4a90e2] px-6 py-3 rounded-lg text-white font-semibold hover:bg-[#357abd] transition-colors duration-200 transform hover:scale-[1.02]"
                >
                  {buttonText}
                </button>
              </div>
            </form>

            <div className="space-y-6">
              <div>
                <h3 className="text-xl font-bold mb-4 text-white">Suivez-nous</h3>
                <div className="flex space-x-6">
                  <a href="https://fr.linkedin.com/company/realitim.com" target="_blank" rel="noopener noreferrer" className="text-2xl hover:text-[#4a90e2] transition-colors duration-200"><i className="fab fa-linkedin"></i></a>
                  <a href="https://www.facebook.com/realitim/" target="_blank" rel="noopener noreferrer" className="text-2xl hover:text-[#4a90e2] transition-colors duration-200"><i className="fab fa-facebook"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Pop-up de confirmation d'inscription */}
        {showPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full text-center">
              <h2 className="text-xl font-bold mb-4">Merci pour votre inscription !</h2>
              <p className="text-gray-700 mb-6">Vous êtes maintenant inscrit(e) à notre newsletter.</p>
              <button
                onClick={() => setShowPopup(false)}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
              >
                Fermer
              </button>
            </div>
          </div>
        )}

        {/* Footer Info */}
        <div className="mt-12 pt-8 border-t border-[#1a2942] text-sm">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="flex flex-wrap justify-center md:justify-start gap-6">
              <Link to="/mentions-legales" className="hover:text-white transition-colors duration-200">Mentions légales</Link>
              <Link to="/cgv" className="hover:text-white transition-colors duration-200">CGV</Link>
              <Link to="/cgu" className="hover:text-white transition-colors duration-200">CGU</Link>
              <Link to="/politique-confidentialite" className="hover:text-white transition-colors duration-200">Politique de confidentialité</Link>
              <Link to="/cookies" className="hover:text-white transition-colors duration-200">Gestion des cookies</Link>
            </div>
            <div className="text-center md:text-right">
              <p className="mb-1">© 2024 Stand3D</p>
              <a 
                href="https://www.realitim.com" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="hover:text-white transition-colors duration-200"
              >
                Plateforme "Made in Nantes" par REALITIM
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
