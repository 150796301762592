// src/Formules/CancelPage/CancelPage.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function CancelPage() {
  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const navigate = useNavigate();

  // Redirection automatique après 5 secondes si un feedback est donné
  useEffect(() => {
    if (feedbackGiven) {
      const timer = setTimeout(() => {
        navigate("/formules"); // Remplacez "/formules" par la route exacte de vos formules
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [feedbackGiven, navigate]);

  // Gestion des réponses de l'utilisateur
  const handleFeedback = (reason) => {
    console.log("Raison du non-abonnement:", reason); // Enregistrer la raison
    setFeedbackGiven(true); // Affiche le message de remerciement et déclenche le délai de redirection
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-red-100 via-white to-red-50">
      <div className="text-center p-10 max-w-lg bg-white rounded-lg shadow-xl border border-red-200 mt-24"> {/* Décalage de 100px avec mt-24 */}
        <h1 className="text-4xl font-extrabold text-red-600 mb-4">Paiement annulé</h1>
        <p className="text-gray-600 mb-6">
          Vous avez annulé le processus de paiement. Si c'était une erreur, vous pouvez réessayer.
        </p>

        {/* Section de feedback */}
        {!feedbackGiven ? (
          <>
            <p className="text-gray-700 font-semibold mb-4 text-lg">
              Pourquoi n'avez-vous pas finalisé votre achat ?
            </p>
            <div className="flex flex-col gap-4 mb-6">
              <button
                onClick={() => handleFeedback("Cela ne fonctionne pas")}
                className="bg-blue-100 text-blue-700 py-3 px-5 rounded-lg hover:bg-blue-200 transition-all font-medium flex items-center gap-2"
              >
                <i className="fas fa-exclamation-circle text-blue-600"></i>
                Cela ne fonctionne pas
              </button>
              <button
                onClick={() => handleFeedback("Pas intéressé")}
                className="bg-blue-100 text-blue-700 py-3 px-5 rounded-lg hover:bg-blue-200 transition-all font-medium flex items-center gap-2"
              >
                <i className="fas fa-times-circle text-blue-600"></i>
                Pas intéressé
              </button>
              <button
                onClick={() => handleFeedback("Trop cher")}
                className="bg-blue-100 text-blue-700 py-3 px-5 rounded-lg hover:bg-blue-200 transition-all font-medium flex items-center gap-2"
              >
                <i className="fas fa-euro-sign text-blue-600"></i>
                Trop cher
              </button>
              <button
                onClick={() => handleFeedback("Je le garde pour moi")}
                className="bg-blue-100 text-blue-700 py-3 px-5 rounded-lg hover:bg-blue-200 transition-all font-medium flex items-center gap-2"
              >
                <i className="fas fa-user-secret text-blue-600"></i>
                Je le garde pour moi
              </button>
            </div>
          </>
        ) : (
          <div>
            <p className="text-green-600 font-semibold text-lg mb-4">
              Merci pour votre retour !
            </p>
            <p className="text-gray-500">
              Vous allez être redirigé vers les formules dans quelques instants...
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default CancelPage;
