import React from "react";
import { Link } from "react-router-dom";

function Qui() {
  return (
    <div className="bg-gradient-to-b from-blue-50 to-white min-h-screen font-sans">
      {/* Main Content */}
      <main className="container mx-auto px-4 py-16">
        {/* Section 1: À Propos */}
        <section className="mb-16 bg-white rounded-lg shadow-lg p-8 transition transform hover:shadow-xl hover:scale-105">
          <h2 className="text-4xl font-bold mb-6 text-center text-blue-800">À Propos de Nous</h2>
          <p className="text-lg text-gray-700 leading-relaxed mb-6">
            Chez <strong className="text-blue-600">Stand3D</strong>, notre objectif est simple :{" "}
            <strong className="text-blue-600">
              révolutionner la manière dont les professionnels de l'événementiel conçoivent, configurent et installent leurs stands d'exposition
            </strong>.
            Nous croyons que la technologie doit simplifier les processus complexes. Grâce à notre{" "}
            <Link to="/configurateur" className="text-blue-600 underline hover:text-blue-800">
              configurateur 3D intuitif
            </Link>, nous permettons à nos utilisateurs de concevoir leur stand sur-mesure en quelques clics, de générer un devis instantanément, et de{" "}
            <Link to="/annuaire-standistes" className="text-blue-600 underline hover:text-blue-800">
              trouver les meilleurs standistes
            </Link> grâce à un annuaire exhaustif.
          </p>
        </section>

        {/* Section 2: L'Équipe */}
        <section className="mb-16 bg-white rounded-lg shadow-lg p-8 transition transform hover:shadow-xl hover:scale-105">
          <h3 className="text-3xl font-semibold mb-4 text-center text-blue-800">L'Équipe</h3>
          <p className="text-lg text-gray-700 mb-8 text-center">
            L'équipe derrière <strong className="text-blue-600">Stand3D</strong> est composée de passionnés en{" "}
            <strong className="text-blue-600">technologies numériques</strong> et en{" "}
            <strong className="text-blue-600">événementiel</strong>. Chaque membre apporte un savoir-faire unique pour
            garantir une expérience fluide.
          </p>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-blue-50 p-6 rounded-lg flex items-start shadow-md hover:shadow-xl">
              <img
                src="/qui-sommes-nous/arthur-devouge.png"
                alt="Portrait of Arthur Devouge"
                className="w-24 h-24 rounded-full mr-4 object-cover transition-transform duration-300 hover:scale-110"
              />
              <div>
                <h4 className="text-xl font-bold mb-2 text-blue-700">Arthur Devouge – CEO & Fondateur</h4>
                <p className="text-gray-600 leading-relaxed">
                  Arthur est un visionnaire du secteur des technologies digitales pour les événements professionnels.
                  Après avoir passé plus de 15 ans à accompagner des entreprises dans l'organisation de salons et
                  d'événements à travers le monde, il a fondé REALITIM avec l'ambition de créer des solutions numériques
                  innovantes pour l'industrie événementielle.
                </p>
              </div>
            </div>
            <div className="bg-blue-50 p-6 rounded-lg flex items-start shadow-md hover:shadow-xl">
              <img
                src="/qui-sommes-nous/tanguy.jpeg"
                alt="Portrait of Tanguy Villiot"
                className="w-24 h-24 rounded-full mr-4 object-cover transition-transform duration-300 hover:scale-110"
              />
              <div>
                <h4 className="text-xl font-bold mb-2 text-blue-700">Tanguy Villiot – Directeur Technique (CTO)</h4>
                <p className="text-gray-600 leading-relaxed">
                  Avec une solide expérience en développement de plateformes 3D interactives, Tanguy dirige l'équipe technique.
                  Sa passion pour les nouvelles technologies et son expertise dans la réalité virtuelle et augmentée
                  sont au cœur du développement du configurateur de stands.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Section 3: Historique */}
        <section className="bg-white rounded-lg shadow-lg p-8 transition transform hover:shadow-xl hover:scale-105">
          <h3 className="text-3xl font-semibold mb-4 text-center text-blue-800">Historique</h3>
          <div className="space-y-4 text-lg text-gray-700 leading-relaxed">
            <p>
              <strong className="text-blue-600">Stand3D</strong> est née de l'expertise de{" "}
              <strong className="text-blue-600">REALITIM</strong>, une entreprise spécialisée dans le développement de{" "}
              <strong className="text-blue-600">plateformes digitales pour les salons professionnels</strong>.
            </p>
            <p>
              Face à la demande croissante pour des outils digitaux, REALITIM a décidé de créer{" "}
              <strong className="text-blue-600">Stand3D</strong> en 2023.
            </p>
            <p>
              Aujourd'hui, <strong className="text-blue-600">Stand3D</strong> continue d'innover pour offrir une{" "}
              <strong className="text-blue-600">expérience utilisateur optimale</strong> et transformer le processus de création de stands.
            </p>
          </div>
          <div className="mt-6 text-center">
            <p className="text-gray-600">Pour en savoir plus sur REALITIM et ses autres solutions, visitez notre site web :</p>
            <a
              href="https://www.realitim.com"
              className="inline-block mt-2 px-6 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-300 transform hover:-translate-y-1"
            >
              www.realitim.com
            </a>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Qui;
