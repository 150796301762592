"use client";
import React from "react";
import { useNavigate } from "react-router-dom";

function OeStart({ nextStep }) {
  const [step, setStep] = React.useState(0);
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-100 via-purple-100 to-pink-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto bg-white rounded-3xl shadow-2xl overflow-hidden">
        <div className="md:flex">
          <div className="md:w-1/2 bg-gradient-to-br from-indigo-600 to-purple-700 p-12 text-white flex flex-col justify-center">
            <h2 className="text-4xl font-extrabold mb-6 leading-tight">
              Bienvenue sur le Configurateur de Stand en 3D
            </h2>
            <p className="text-xl mb-8 leading-relaxed">
              Vous allez pouvoir créer votre stand en 3D et le personnaliser en quelques clics seulement.
            </p>
            <ul className="space-y-4 text-lg">
              <li className="flex items-center">
                <i className="fas fa-star mr-3 text-yellow-300"></i> Créez le stand idéal pour votre salon professionnel
              </li>
              <li className="flex items-center">
                <i className="fas fa-bullseye mr-3 text-yellow-300"></i> Des milliers de combinaisons possibles pour un rendu unique
              </li>
              <li className="flex items-center">
                <i className="fas fa-cogs mr-3 text-yellow-300"></i> Une estimation du coût en temps réel 
              </li>
            </ul>
          </div>
          <div className="md:w-1/2 p-8 flex flex-col items-center justify-center">
            {step === 0 && (
              <div className="text-center">
                <h1 className="text-4xl font-extrabold text-indigo-600 mb-6 leading-tight">
                  Créez votre compte en moins d'une minute
                </h1>
                <div className="flex justify-center">
                  <button
                    onClick={() => {
                      setStep(1);
                      nextStep();
                    }}
                    className="px-8 py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white text-lg font-semibold rounded-full hover:from-indigo-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 shadow-lg"
                  >
                    Commencer l'inscription
                  </button>
                </div>
                <p className="mt-6 text-gray-600 text-lg">
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OeStart;
