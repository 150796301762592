import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faSignOutAlt, faBriefcase, faUser, faPlus, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { auth, db } from '../../firebase'; // Assurez-vous que `db` est configuré correctement
import { doc, getDoc } from 'firebase/firestore';

function MonProfil() {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [role, setRole] = useState("visiteur");
  const navigate = useNavigate();
  const menuRef = useRef(null);

  const truncateText = (text) => text && text.length > 15 ? `${text.substring(0, 15)}...` : text || '';

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        try {
          const userDoc = await getDoc(doc(db, "users", currentUser.uid));
          if (userDoc.exists()) {
            setRole(userDoc.data().role || "visiteur");
          }
        } catch (error) {
          console.error("Erreur lors de la récupération du rôle :", error);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const closeDropdown = () => setIsOpen(false);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      alert("Déconnexion réussie");
      closeDropdown();
      navigate('/');
    } catch (error) {
      console.error("Erreur lors de la déconnexion :", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeDropdown();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative inline-block text-left">
      <button onClick={toggleDropdown} className="flex items-center focus:outline-none">
        {user?.photoURL ? (
          <img src={user.photoURL} alt="Profile" className="w-8 h-8 rounded-full border-2 border-gray-300" />
        ) : (
          <FontAwesomeIcon icon={faUserCircle} className="text-gray-600 w-8 h-8 rounded-full border-2 border-gray-300" />
        )}
        <div className="ml-2 text-gray-700 hidden md:block">
          <p className="font-semibold text-sm">{truncateText(user?.displayName || user?.email)}</p>
        </div>
      </button>

      {isOpen && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-20 z-20" onClick={closeDropdown}></div>
          
          <div ref={menuRef} className="absolute top-14 right-0 w-64 bg-white border border-gray-200 rounded-lg shadow-lg z-30 md:w-60">
            {role === "visiteur" && (
              <>
                <Link to="/configurateur" className="flex items-center px-4 py-3 text-gray-700 hover:bg-blue-100 hover:text-blue-600 rounded-t-lg">
                  <FontAwesomeIcon icon={faPlus} className="mr-2 text-blue-500" /> Créer un stand en 3D
                </Link>
                <Link to="/onboarding" className="flex items-center px-4 py-3 text-gray-700 hover:bg-blue-100 hover:text-blue-600">
                  <FontAwesomeIcon icon={faUserPlus} className="mr-2 text-blue-500" /> Devenir Prestataire
                </Link>
              </>
            )}
            {(role === "exposant" || role === "exposant_prestataire") && (
              <Link to="/espace-exposant" className="flex items-center px-4 py-3 text-gray-700 hover:bg-blue-100 hover:text-blue-600">
                <FontAwesomeIcon icon={faBriefcase} className="mr-2 text-blue-500" /> Espace Exposant
              </Link>
            )}
            {(role === "prestataire" || role === "exposant_prestataire") && (
              <Link to="/espace-prestataire" className="flex items-center px-4 py-3 text-gray-700 hover:bg-blue-100 hover:text-blue-600">
                <FontAwesomeIcon icon={faBriefcase} className="mr-2 text-blue-500" /> Espace Prestataire
              </Link>
            )}
            <Link to="/mon-profil/details" className="flex items-center px-4 py-3 text-gray-700 hover:bg-blue-100 hover:text-blue-600">
              <FontAwesomeIcon icon={faUser} className="mr-2 text-green-500" /> Mon Profil
            </Link>
            <button onClick={handleLogout} className="w-full text-left px-4 py-3 text-gray-700 hover:bg-red-100 hover:text-red-600 border-t border-gray-200 rounded-b-lg">
              <FontAwesomeIcon icon={faSignOutAlt} className="mr-2 text-red-500" /> Déconnexion
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default MonProfil;
