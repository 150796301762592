import React, { useState, useContext } from "react";
import ColorPicker from "./Composants/ColorPicker";
import { ConfigContext } from "../Contexte"; // Importer ConfigContext pour sauvegarder localement

function Screen3({ updateConfig }) {
  const { configuration, setConfiguration } = useContext(ConfigContext);

  const floorMaterials = ["Moquette", "Parquet", "Lino", "Sisal"];
  const colors = [
    { name: "Blanc cassé", hex: "#F5F5F5" },
    { name: "Beige clair", hex: "#F5F1E6" },
    { name: "Bleu pâle", hex: "#E6F3FF" },
    { name: "Vert menthe", hex: "#E6FFF2" },
    { name: "Gris perle", hex: "#F0F0F0" },
    { name: "Lavande", hex: "#E6E6FA" },
    { name: "Pêche", hex: "#FFDAB9" },
    { name: "Jaune pastel", hex: "#FFFACD" },
  ];

  const [selectedWallColor, setSelectedWallColor] = useState(configuration.wallColor || "");
  const [selectedFloorColor, setSelectedFloorColor] = useState(configuration.floorColor || "");
  const [selectedMaterial, setSelectedMaterial] = useState(configuration.floorMaterial || "");
 
    // Mise à jour de la configuration dans le contexte
    const updateLocalConfig = (key, value) => {
      setConfiguration((prevConfig) => ({
        ...prevConfig,
        [key]: value,
      }));
    };
  
    // Gestion de la sélection de couleur des murs
    const handleWallColorChange = (color) => {
      setSelectedWallColor(color);
      updateConfig("wallColor", color);
      updateLocalConfig("wallColor", color);
    };
  
    // Gestion de la sélection de couleur du sol
    const handleFloorColorChange = (color) => {
      setSelectedFloorColor(color);
      updateConfig("floorColor", color);
      updateLocalConfig("floorColor", color);
    };
  
    // Gestion de la sélection du matériau du sol
    const handleMaterialChange = (material) => {
      setSelectedMaterial(material);
      updateConfig("floorMaterial", material);
      updateLocalConfig("floorMaterial", material);
    };
  
    return (
      <div className="p-4 bg-white shadow-md rounded-lg lg:p-6">
        <h2 className="text-xl lg:text-2xl font-bold mb-4 lg:mb-6 text-blue-800">
          Sélectionnez les couleurs et matériaux
        </h2>
  
        {/* Sélection de la couleur des murs */}
        <div className="mb-6 lg:mb-8">
          <h3 className="text-lg lg:text-xl font-semibold mb-2 lg:mb-4 text-blue-700">
            Couleur des murs
          </h3>
          <ColorPicker
            colors={colors}
            selectedColor={selectedWallColor}
            onChange={handleWallColorChange}
          />
        </div>
  
        {/* Sélection du matériau du sol */}
        <div className="mb-6 lg:mb-8">
          <h3 className="text-lg lg:text-xl font-semibold mb-2 lg:mb-4 text-blue-700">Sol</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4 lg:mb-6">
            {floorMaterials.map((material) => (
              <button
                key={material}
                onClick={() => handleMaterialChange(material)}
                className={`p-3 border-2 rounded-lg transition-all duration-300 shadow-md text-sm lg:text-base flex items-center justify-between ${
                  selectedMaterial === material
                    ? "bg-blue-600 text-white border-blue-700"
                    : "bg-white text-blue-800 border-blue-300 hover:border-blue-500 hover:bg-blue-50"
                }`}
                aria-pressed={selectedMaterial === material}
              >
                <div className="flex items-center">
                  <div className="w-8 h-8 mr-2 rounded-full overflow-hidden">
                    <img
                      src={`/materiaux/${material.toLowerCase().replace(/ /g, "-")}.jpg`}
                      alt={material}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  {material}
                </div>
              </button>
            ))}
          </div>
  
          {/* Sélection de la couleur du sol */}
          <h3 className="text-lg lg:text-xl font-semibold mb-2 lg:mb-4 text-blue-700">
            Couleur du sol
          </h3>
          <ColorPicker
            colors={colors}
            selectedColor={selectedFloorColor}
            onChange={handleFloorColorChange}
          />
        </div>
      </div>
    );
  }
  
  export default Screen3;