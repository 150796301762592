import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase"; // Importer Firebase Auth

function EmailVerified() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmailStatus = async () => {
      try {
        if (auth.currentUser) {
          // Recharger les informations utilisateur
          await auth.currentUser.reload();
          
          // Vérifier si l'email a été vérifié
          if (auth.currentUser.emailVerified) {
            // Rediriger vers OsProfil si l'email est vérifié
            navigate("/onboarding/profil");
          } else {
            setError("Votre email n'a pas encore été vérifié.");
          }
        } else {
          setError("Aucun utilisateur connecté.");
        }
      } catch (error) {
        setError("Erreur lors de la vérification de l'email.");
      } finally {
        setLoading(false);
      }
    };

    verifyEmailStatus();
  }, [navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      {loading ? (
        <p>Vérification en cours...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <p>Email vérifié, redirection en cours...</p>
      )}
    </div>
  );
}

export default EmailVerified;
