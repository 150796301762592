import React, { useState } from "react";
import Header from "./Header";
import AccueilHero from "./AccueilHero";
import Arguments from "./Arguments";
import NosServices from "./NosServices";
import AvisClients from './AvisClients';
import FAQ from "./FAQ";
import Footer from "./Footer";
import PopupInscription from "./PopupInscription";
import { createPortal } from 'react-dom';

function MainAccueil() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>
      {/* Affichage de la popup via un portail */}
      {isPopupOpen && createPortal(
        <PopupInscription isOpen={isPopupOpen} onClose={handleClosePopup} />,
        document.body
      )}

      {/* Contenu principal de la page */}
      <Header />
      <AccueilHero />
      <Arguments />
      <NosServices />
      <AvisClients />
      <FAQ />
      <Footer />
    </div>
  );
}

export default MainAccueil;
