import React from "react";
import { Link } from "react-router-dom";

function NosServices() {
  return (
    <section className="container mx-auto px-4 py-16">
      <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">
        Nos Services
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
        {/* Service 1 - Configurateur 3D */}
        <div className="bg-white rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300 overflow-hidden transform hover:scale-105">
          <div className="overflow-hidden">
            <img
              src="\stands-demo\stand-demo-2.png"
              alt="Configurateur 3D"
              className="w-full h-56 object-cover transition-transform duration-500 hover:scale-110"
            />
          </div>
          <div className="p-6 text-center"> {/* Centre le texte */}
            <h3 className="font-bold text-2xl mb-3 text-gray-800">Configurateur de stand 3D</h3>
            <p className="text-gray-600 mb-6">
              Créez votre stand sur mesure avec notre outil intuitif et visualisez-le en temps réel.
            </p>
            <div className="flex justify-center"> {/* Centre le bouton */}
              <Link
                to="/configurateur"
                className="inline-block bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold py-3 px-8 rounded-full hover:shadow-md transition-transform duration-300 transform hover:-translate-y-1"
              >
                Commencer
              </Link>
            </div>
          </div>
        </div>

        {/* Service 2 - Devis Rapide */}
        <div className="bg-white rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300 overflow-hidden transform hover:scale-105">
          <div className="overflow-hidden">
            <img
              src="accueil\devis-stand.jpg"
              alt="Devis Rapide"
              className="w-full h-56 object-cover transition-transform duration-500 hover:scale-110"
            />
          </div>
          <div className="p-6 text-center"> {/* Centre le texte */}
            <h3 className="font-bold text-2xl mb-3 text-gray-800">Devis Rapide</h3>
            <p className="text-gray-600 mb-6">
              Obtenez jusqu'à 5 devis de professionnels pour votre projet de Stand d'Exposition.
            </p>
            <div className="flex justify-center"> {/* Centre le bouton */}
              <Link
                to="/devis"
                className="inline-block bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold py-3 px-8 rounded-full hover:shadow-md transition-transform duration-300 transform hover:-translate-y-1"
              >
                Demander un devis
              </Link>
            </div>
          </div>
        </div>

        {/* Service 3 - Annuaire de Professionnels */}
        <div className="bg-white rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300 overflow-hidden transform hover:scale-105">
          <div className="overflow-hidden">
            <img
              src="accueil\annuaire-standistes.jpg"
              alt="Annuaire de Professionnels"
              className="w-full h-56 object-cover transition-transform duration-500 hover:scale-110"
            />
          </div>
          <div className="p-6 text-center"> {/* Centre le texte */}
            <h3 className="font-bold text-2xl mb-3 text-gray-800">Annuaire des Prestataires</h3>
            <p className="text-gray-600 mb-6">
              Trouvez les meilleurs prestataires pour votre Stand d'exposition parmi notre réseau de professionnels.
            </p>
            <div className="flex justify-center"> {/* Centre le bouton */}
              <Link
                to="/annuaire"
                className="inline-block bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold py-3 px-8 rounded-full hover:shadow-md transition-transform duration-300 transform hover:-translate-y-1"
              >
                Consulter l'annuaire
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NosServices;
