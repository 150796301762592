import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PopupInscription from "../../Site/Accueil/PopupInscription";
import Connexion from "../../Connexion/Connexion";
import { auth } from "../../firebase";
import MonProfil from "./MonProfil";

function Header() {
  const [activeLink, setActiveLink] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isConnexionOpen, setIsConnexionOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    const headerElement = document.getElementById("main-header");
    if (headerElement) {
      setHeaderHeight(headerElement.offsetHeight);
    }

    return () => unsubscribe();
  }, []);

  const openInscriptionPopup = () => {
    setIsPopupOpen(true);
    setIsConnexionOpen(false);
  };

  const NavLink = ({ to, label, icon }) => (
    <Link
      to={to}
      onMouseEnter={() => setActiveLink(label)}
      onMouseLeave={() => setActiveLink("")}
      className={`text-white hover:text-[#4a90e2] transition-all duration-300 font-raleway relative px-2 py-1 ${
        activeLink === label ? "text-[#4a90e2]" : ""
      }`}
      onClick={() => setIsMobileMenuOpen(false)} // Ferme le menu mobile au clic sur le lien
    >
      <i className={`fas ${icon} mr-2`}></i>
      {label}
    </Link>
  );

  return (
    <>
      <nav id="main-header" className="fixed top-0 left-0 right-0 z-50 bg-[#0a1128]/100 shadow-lg">
        <div className="container mx-auto px-6 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center gap-2">
            <img src="/logo-stand-3d-192.png" alt="Stand3D Logo" className="h-6 w-auto" />
            <span className="text-white font-montserrat text-xl font-bold">Stand-3D</span>
          </Link>

          {/* Navigation pour les écrans larges */}
          <div className="hidden md:flex items-center gap-12">
            <NavLink to="/configurateur" label="Stand en 3D" icon="fa-cube" />
            <NavLink to="/annuaire" label="Annuaire Prestataires" icon="fa-address-book" />
            <NavLink to="/devis" label="Devis Stand" icon="fa-file-invoice" />
          </div>

          <div className="flex items-center gap-4">
            {user ? (
              <MonProfil user={user} />
            ) : (
              <>
                {/* Menu burger pour les petits écrans */}
                <button
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                  className="text-white md:hidden"
                >
                  <i className={`fas ${isMobileMenuOpen ? "fa-times" : "fa-bars"} text-xl`}></i>
                </button>

                {/* Connexion et Inscription pour les écrans larges */}
                <button
                  onClick={() => setIsConnexionOpen(true)}
                  className="text-white hover:text-[#4a90e2] transition-colors px-4 py-2 rounded-lg font-raleway hidden md:flex items-center"
                >
                  <i className="fas fa-sign-in-alt mr-2"></i> Connexion
                </button>
                <button
                  onClick={() => setIsPopupOpen(true)}
                  className="bg-[#4a90e2] text-white px-4 py-2 rounded-lg font-raleway hover:bg-[#357abd] transition-colors hidden md:flex items-center"
                >
                  <i className="fas fa-user-plus mr-2"></i> Inscription
                </button>
              </>
            )}
          </div>
        </div>

        {/* Menu mobile pour les utilisateurs non connectés */}
        {isMobileMenuOpen && !user && (
          <div className="bg-[#0a1128] md:hidden flex flex-col items-center gap-4 py-4">
            <NavLink to="/configurateur" label="Stand en 3D" icon="fa-cube" />
            <NavLink to="/annuaire" label="Annuaire des Prestataires" icon="fa-address-book" />
            <NavLink to="/devis" label="Devis Stand" icon="fa-file-invoice" />

            <button
              onClick={() => {
                setIsConnexionOpen(true);
                setIsMobileMenuOpen(false);
              }}
              className="text-white hover:text-[#4a90e2] transition-colors px-4 py-2 rounded-lg font-raleway flex items-center"
            >
              <i className="fas fa-sign-in-alt mr-2"></i> Connexion
            </button>
            <button
              onClick={() => {
                setIsPopupOpen(true);
                setIsMobileMenuOpen(false);
              }}
              className="bg-[#4a90e2] text-white px-4 py-2 rounded-lg font-raleway hover:bg-[#357abd] transition-colors flex items-center"
            >
              <i className="fas fa-user-plus mr-2"></i> Inscription
            </button>
          </div>
        )}
      </nav>

      {/* Espace pour éviter le recouvrement du contenu par le header */}
      <div style={{ height: `${headerHeight}px` }}></div>

      {/* Popups d'Inscription et de Connexion */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-10 backdrop-blur-sm flex items-center justify-center z-50">
          <PopupInscription isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
        </div>
      )}

      {isConnexionOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-10 backdrop-blur-sm flex items-center justify-center z-50">
          <Connexion onClose={() => setIsConnexionOpen(false)} onOpenInscription={openInscriptionPopup} />
        </div>
      )}
    </>
  );
}

export default Header;
