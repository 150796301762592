import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function AccueilHero() {
  const [isHoveredBtn1, setIsHoveredBtn1] = useState(false);
  const [isHoveredBtn2, setIsHoveredBtn2] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="relative overflow-hidden bg-gradient-to-br from-[#0a1128] via-[#1e2d5d] to-[#2c4187] flex items-center justify-center">
      {/* Hero Content */}
      <div className="relative z-10 container mx-auto px-6 py-20 flex flex-col md:flex-row items-center gap-12">
        <div className={`flex-1 transition-all duration-1000 ${isVisible ? "translate-x-0 opacity-100" : "-translate-x-full opacity-0"}`}>
          <div className="flex flex-col items-start">
            <h1 className="font-montserrat text-3xl md:text-5xl font-bold text-white uppercase tracking-wide mb-8 text-left">
              Créer votre Stand
              <div className="flex items-center gap-3 mt-1">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#4a90e2] to-[#87ceeb] animate-gradient-x text-3xl md:text-5xl">
                  en 3D
                </span>
                <i className="fas fa-cube text-[#4a90e2] animate-bounce-slow text-2xl md:text-3xl"></i>
              </div>
            </h1>
            <p className="font-raleway text-xl md:text-2xl text-[#f8f9fa] mb-12 max-w-3xl leading-relaxed text-left">
              Personnalisez votre stand, choisissez vos services et obtenez votre devis en un instant.
            </p>

            {/* Buttons */}
            <div className="flex flex-col gap-6 w-full max-w-xl">
              <button
                className="bg-[#4a90e2] text-white px-8 py-4 rounded-lg font-montserrat text-lg hover:bg-[#357abd] transition-all hover:shadow-lg flex items-center gap-2"
                onMouseEnter={() => setIsHoveredBtn1(true)}
                onMouseLeave={() => setIsHoveredBtn1(false)}
                onClick={() => navigate('/configurateur')}
                style={{ transform: isHoveredBtn1 ? "scale(1.02)" : "scale(1)" }}
              >
                <i className="fas fa-cube"></i> Lancer le Configurateur de Stand en 3D <i className="fas fa-arrow-right ml-2"></i>
              </button>
              <button
                className="bg-transparent border-2 border-[#4a90e2] text-white px-8 py-4 rounded-lg font-montserrat text-lg hover:bg-[#4a90e2]/10 transition-all hover:shadow-lg flex items-center gap-2"
                onMouseEnter={() => setIsHoveredBtn2(true)}
                onMouseLeave={() => setIsHoveredBtn2(false)}
                onClick={() => navigate('/annuaire')}
                style={{ transform: isHoveredBtn2 ? "scale(1.02)" : "scale(1)" }}
              >
                <i className="fas fa-users"></i> Accéder à l'Annuaire des Prestataires <i className="fas fa-arrow-right ml-2"></i>
              </button>
            </div>
          </div>
        </div>

        {/* Preview Icon */}
        <div className={`flex-1 mt-12 md:mt-0 transition-all duration-1000 ${isVisible ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"}`}>
          <div className="relative">
            <div className="absolute -inset-4 bg-gradient-to-r from-[#4a90e2] via-[#357abd] to-[#4a90e2] opacity-20 blur-2xl rounded-2xl animate-pulse"></div>
            <img
              src="\stands-demo\stand-demo-1.jpeg"
              alt="Aperçu"
              className="w-83 h-83 animate-float relative z-10 border border-[#4a90e2]/20 rounded-2xl"
            />
          </div>
        </div>
      </div>

      <style>
        {`
          @keyframes gradient-x { 
            0%, 100% { background-position: 0% 50%; } 
            50% { background-position: 100% 50%; } 
          }
          .animate-gradient-x { 
            background-size: 200% 100%; 
            animation: gradient-x 4s ease infinite; 
          }
          @keyframes bounce-slow { 
            0%, 100% { transform: translateY(0); } 
            50% { transform: translateY(-10px); } 
          }
          .animate-bounce-slow { 
            animation: bounce-slow 2s ease-in-out infinite; 
          }
          @keyframes float { 
            0%, 100% { transform: translateY(0px); } 
            50% { transform: translateY(-20px); } 
          }
          .animate-float { 
            animation: float 6s ease-in-out infinite; 
          }
        `}
      </style>
    </div>
  );
}

export default AccueilHero;
