import React from "react";
import { Link } from "react-router-dom";
import { FaCogs, FaAddressBook } from "react-icons/fa"; // Import des icônes

function Devis() {
  return (
    <div className="container mx-auto px-4 py-16">
      {/* Titre principal */}
      <h1 className="text-5xl font-bold mb-10 text-center text-gray-900 leading-tight">
        Obtenez Votre Devis Personnalisé
      </h1>

      {/* Sous-titre et explication */}
      <p className="text-lg mb-12 text-center text-gray-700 max-w-3xl mx-auto leading-relaxed">
        Créez un stand d'exposition unique qui reflète votre image de marque. Remplissez
        le formulaire ci-dessous pour recevoir un devis ou personnalisez votre stand via
        notre configurateur 3D. Nous vous fournirons un devis détaillé sous 24h.
      </p>

      {/* Formulaire de devis */}
      <form className="bg-white shadow-lg rounded-lg p-10 mb-16 max-w-4xl mx-auto">
        {/* Informations sur l'événement */}
        <h2 className="text-3xl font-semibold mb-8 text-gray-800">
          Informations sur l'Événement
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-10">
          <div>
            <label className="block text-gray-700 font-medium">Nom de l'événement</label>
            <input
              type="text"
              className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Ex: Salon du Marketing 2024"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium">Date de l'événement</label>
            <input
              type="date"
              className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium">Lieu de l'événement</label>
            <input
              type="text"
              className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Ex: Parc des Expositions, Paris"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium">Durée de l'événement</label>
            <input
              type="number"
              className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Nombre de jours"
            />
          </div>
        </div>

        {/* Dimensions du Stand */}
        <h2 className="text-3xl font-semibold mb-8 text-gray-800">
          Dimensions du Stand
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-10">
          <div>
            <label className="block text-gray-700 font-medium">Surface souhaitée</label>
            <select className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option>6 à 12 m²</option>
              <option>12 à 24 m²</option>
              <option>24 m² et plus</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-700 font-medium">Forme du stand</label>
            <select className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option>Îlot</option>
              <option>Angle</option>
              <option>Linéaire</option>
              <option>Autre</option>
            </select>
          </div>
        </div>

        {/* Design et Matériaux */}
        <h2 className="text-3xl font-semibold mb-8 text-gray-800">
          Design et Matériaux
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-10">
          <div>
            <label className="block text-gray-700 font-medium">Style de stand</label>
            <select className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option>Moderne et minimaliste</option>
              <option>Professionnel et institutionnel</option>
              <option>Créatif et original</option>
              <option>Écologique et durable</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-700 font-medium">Matériaux préférés</label>
            <select className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option>Bois</option>
              <option>Métal</option>
              <option>Verre</option>
              <option>Matériaux éco-responsables</option>
            </select>
          </div>
        </div>

        {/* Budget et Coordonnées */}
        <h2 className="text-3xl font-semibold mb-8 text-gray-800">
          Budget et Coordonnées
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-10">
          <div>
            <label className="block text-gray-700 font-medium">Budget estimé</label>
            <select className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option>Moins de 5000 €</option>
              <option>5000 - 10 000 €</option>
              <option>10 000 - 20 000 €</option>
              <option>Plus de 20 000 €</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-700 font-medium">Nom et Prénom</label>
            <input
              type="text"
              className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Votre nom complet"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium">Nom de l'entreprise</label>
            <input
              type="text"
              className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Nom de votre entreprise"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium">Email</label>
            <input
              type="email"
              className="w-full border border-gray-300 p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Votre email"
            />
          </div>
        </div>

        {/* Message concernant l'acceptation des conditions */}
        <p className="text-gray-600 text-sm text-center mb-8">
          En cliquant sur "Recevoir Mon Devis", j'accepte que mon projet soit
          accessible à un maximum de cinq standistes basés en France, pour leur permettre
          d'établir un chiffrage.
        </p>

        {/* Bouton Soumettre */}
        <div className="text-center mb-12">
          <button
            type="submit"
            className="bg-blue-600 text-white font-bold py-4 px-10 rounded-full shadow-lg hover:bg-blue-700 hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300 ease-in-out"
          >
            Recevoir Mon Devis
          </button>
        </div>
      </form>

      {/* CTA - deux boutons côte à côte sur desktop, l'un au-dessus de l'autre sur mobile */}
      <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-6 mt-12">
        <Link
          to="/configurateur"
          className="inline-flex items-center bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-4 px-8 rounded-full shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300 ease-in-out"
        >
          <FaCogs className="mr-3 text-xl" />
          Configurez votre stand en 3D
        </Link>
        <Link
          to="/annuaire"
          className="inline-flex items-center bg-gradient-to-r from-green-500 to-teal-600 text-white font-bold py-4 px-8 rounded-full shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300 ease-in-out"
        >
          <FaAddressBook className="mr-3 text-xl" />
          Consultez l'annuaire des standistes
        </Link>
      </div>
    </div>
  );
}

export default Devis;
