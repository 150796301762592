import React, { useState, useEffect } from "react";
import { auth } from "../firebase"; 
import { sendEmailVerification } from "firebase/auth";

function OsVerif({ nextStep, prevStep, formData, setFormData }) {
  const [verificationError, setVerificationError] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const [checkingVerification, setCheckingVerification] = useState(false);
  
  // Fonction pour envoyer l'email de vérification
  const sendVerificationEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      setEmailSent(true);
      setVerificationError(null); // Réinitialiser l'erreur
      console.log("Email de vérification envoyé");
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'email de vérification", error);
      setVerificationError("Impossible d'envoyer l'email de vérification. Veuillez réessayer.");
    }
  };

  // Vérifier si l'utilisateur a vérifié son email
  const checkEmailVerified = async () => {
    setCheckingVerification(true);
    try {
      // Rafraîchir l'utilisateur actuel pour obtenir la dernière version
      await auth.currentUser.reload();

      // Si l'email est vérifié, on passe à l'étape suivante
      if (auth.currentUser.emailVerified) {
        nextStep(); // Passe à l'étape suivante
      } else {
        setVerificationError("Votre email n'a pas encore été vérifié.");
      }
    } catch (error) {
      console.error("Erreur lors de la vérification de l'email", error);
      setVerificationError("Une erreur est survenue lors de la vérification.");
    }
    setCheckingVerification(false);
  };

  // Envoie l'email de vérification à la première ouverture
  useEffect(() => {
    sendVerificationEmail();
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-100 via-purple-100 to-pink-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto bg-white rounded-3xl shadow-2xl overflow-hidden">
        <div className="md:flex">
          <div className="md:w-1/2 bg-gradient-to-br from-indigo-600 to-purple-700 p-12 text-white flex flex-col justify-center">
            <h2 className="text-4xl font-extrabold mb-6 leading-tight">Confirmez votre email</h2>
            <p className="text-xl mb-8 leading-relaxed">
              Un email de vérification a été envoyé à <strong>{formData.contactEmail}</strong>.
              Cliquez sur le lien dans l'email pour confirmer votre adresse et valider votre inscription.
              Après avoir confirmé, appuyez sur "Vérifier" pour continuer.
            </p>
            <ul className="space-y-4 text-lg">
              <li className="flex items-center">
                <i className="fas fa-envelope mr-3 text-yellow-300"></i> Vérifiez vos spams si vous ne voyez pas l'email.
              </li>
              <li className="flex items-center">
                <i className="fas fa-redo-alt mr-3 text-yellow-300"></i> Vous pouvez renvoyer l'email si nécessaire.
              </li>
            </ul>
          </div>
          <div className="md:w-1/2 p-8">
            {emailSent && <p className="text-green-500 text-lg">Email envoyé avec succès. Veuillez vérifier votre boîte de réception.</p>}
            {verificationError && <p className="text-red-500 text-lg">{verificationError}</p>}

            <div className="mt-6 flex flex-col space-y-4">
              <button
                type="button"
                onClick={checkEmailVerified}
                disabled={checkingVerification}
                className="w-full px-6 py-3 bg-indigo-600 text-white text-lg font-semibold rounded-md hover:bg-indigo-700 transition duration-300 ease-in-out transform hover:scale-105"
              >
                {checkingVerification ? "Vérification en cours..." : "Vérifier"}
              </button>
              <button
                type="button"
                onClick={sendVerificationEmail}
                disabled={emailSent}
                className={`w-full px-6 py-3 ${
                  emailSent ? "bg-gray-300 cursor-not-allowed" : "bg-purple-600 hover:bg-purple-700"
                } text-white text-lg font-semibold rounded-md transition duration-300 ease-in-out transform hover:scale-105`}
              >
                {emailSent ? "Email déjà envoyé" : "Renvoyer l'email"}
              </button>
              <button
                type="button"
                onClick={prevStep}
                className="w-full px-6 py-3 bg-gray-300 text-gray-800 text-lg font-semibold rounded-md hover:bg-gray-400 transition duration-300 ease-in-out transform hover:scale-105"
              >
                Retour
              </button>
            </div>

            <div className="mt-8 text-center text-sm text-gray-500">
              <p>Assurez-vous d'avoir accès à l'email <strong>{formData.contactEmail}</strong>.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OsVerif;
