import React from "react";

const PrestatairesAvantages = () => {
  const benefits = [
    {
      icon: "fa-eye",
      title: "Visibilité immédiate",
      desc: "Soyez visible auprès des exposants qualifiés",
    },
    {
      icon: "fa-bullseye",
      title: "Leads qualifiés",
      desc: "Accédez aux demandes de devis personnalisées",
    },
    {
      icon: "fa-bolt",
      title: "Inscription rapide",
      desc: "Inscrivez-vous en moins d'une minute",
    },
  ];

  return (
    <section
      className="py-32 px-4 relative overflow-hidden"
      style={{
        backgroundImage: `url('/stands-demo/stand-demo-1.jpeg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="max-w-7xl mx-auto relative z-10">
        <h2 className="text-4xl md:text-5xl text-center font-bold mb-16 text-white">
          Vos avantages exclusifs
        </h2>
        <div className="grid md:grid-cols-3 gap-10">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="text-center p-10 bg-white rounded-3xl shadow-xl border-2 border-[#007bff] hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2"
            >
              <div className="w-20 h-20 bg-[#007bff]/20 rounded-full flex items-center justify-center mx-auto mb-6">
                <i className={`fas ${benefit.icon} text-4xl text-[#007bff]`}></i>
              </div>
              <h3 className="text-2xl font-bold mb-4 text-[#007bff]">{benefit.title}</h3>
              <p className="text-[#333] text-lg">{benefit.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PrestatairesAvantages;
