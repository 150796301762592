"use client"; 
import React from "react";
import Header from "../../Site/Accueil/Header";
import AnnuaireAccueil from "./AnnuaireAccueil"; // Chemin ajusté pour AnnuaireAccueil
import Footer from "../../Site/Accueil/Footer"; // Import du Footer
import firebase from "firebase/compat/app"; // Compat pour assurer compatibilité Firebase v9+
import "firebase/compat/auth";
import "firebase/compat/firestore";

function MainAnnuaire() {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Header */}
      <Header />

      {/* Contenu principal */}
      <main className="flex-grow">
        <AnnuaireAccueil />
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default MainAnnuaire;
