import React, { useContext, useState, useEffect } from "react";
import { ConfigContext } from '../Contexte';
import StandsElements from './Composants/StandsElements';

function Screen1() {
  const { configuration, updateConfig } = useContext(ConfigContext); 
  const [selectedSize, setSelectedSize] = useState(configuration.floorSize || "300x300"); // Valeur par défaut si non définie

  // Fonction pour gérer la sélection de taille et mise à jour du contexte
  const handleSizeSelection = (floorSize) => {
    setSelectedSize(floorSize);
    updateConfig("floorSize", floorSize); 

    const selectedFloor = StandsElements.floors.find((floor) => floor.size === floorSize);
    if (selectedFloor) {
      updateConfig("floor", selectedFloor); // Met à jour le modèle 3D choisi
      console.log("Updated floor in context:", selectedFloor);
    }
  };

  useEffect(() => {
    // Initialiser la sélection avec le modèle par défaut si nécessaire
    if (configuration.floorSize) {
      handleSizeSelection(configuration.floorSize);
    }
  }, [configuration.floorSize]);

  return (
    <div className="p-4 bg-white shadow-md rounded-lg lg:p-6">
      <h2 className="text-xl lg:text-2xl font-bold mb-4 lg:mb-6 text-blue-800">
        Sélection du format du stand
      </h2>
      
      <div className="grid grid-cols-3 gap-4 mb-6">
        {["300x200", "300x300", "400x300"].map((floorSize) => {
          const isActive = selectedSize === floorSize;
          const imagePath = `/tailles-stands/${floorSize}.png`;
  
          return (
            <button
              key={floorSize}
              onClick={() => handleSizeSelection(floorSize)}
              className={`p-4 border-2 rounded-lg transition-all duration-300 transform hover:scale-105 ${
                isActive
                  ? "bg-blue-700 text-white border-blue-800 shadow-lg"
                  : "bg-white text-blue-800 border-blue-300 hover:border-blue-500 hover:bg-blue-50"
              }`}
            >
              <div className="flex flex-col items-center">
                <img
                  src={imagePath}
                  alt={`Stand ${floorSize}`}
                  className="mb-3"
                  style={{
                    width: '100px',
                    height: 'auto',
                    objectFit: 'contain',
                  }}
                />
                <span className="font-semibold text-lg">{floorSize} m</span>
              </div>
            </button>
          );
        })}
      </div>
  
      <div className="mt-6">
        <h3 className="text-xl font-semibold mb-3 text-indigo-700">
          Autres formats
        </h3>
        <select
          onChange={(e) => handleSizeSelection(e.target.value)}
          value={selectedSize}
          className="w-full p-3 border-2 border-indigo-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
        >
          <option value="">Sélectionnez un format</option>
          {[
            "200x100", "200x200", "300x200", "300x300", "400x300", 
            "500x300", "400x400", "500x500", "600x300", "600x600", "900x600"
          ].map((floorSize) => (
            <option key={floorSize} value={floorSize}>
              {floorSize} m
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default Screen1;
