// src/Site/Legal/CGV/CGV.js 
import React from "react";

function CGV() {
  return (
    <div>
      <h1 className="text-4xl font-bold mb-12 text-[#1e3a8a] font-roboto text-center">
        Conditions Générales de Vente
      </h1>
      
      <section className="mb-12">
        <p className="text-gray-700 mb-6">
          <strong>Date de mise à jour :</strong> 08 novembre 2024
        </p>

        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Préambule
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Les présentes Conditions Générales de Vente (CGV) définissent les modalités et conditions de souscription aux services proposés par REALITIM sur la plateforme Stand3D. En souscrivant aux services payants, le Client accepte sans réserve les présentes CGV.
        </p>

        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          1. Définitions
        </h2>
        <ul className="list-disc pl-6 text-gray-700 space-y-2 mb-6">
          <li><strong>Client :</strong> Toute personne physique ou morale utilisant la plateforme Stand3D.</li>
          <li><strong>Prestataire :</strong> Professionnel proposant ses services via la plateforme.</li>
          <li><strong>Plateforme :</strong> Stand3D, accessible à l'adresse https://stand-3d.com.</li>
        </ul>

        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          2. Description des Services
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          REALITIM propose trois formules d'abonnement :
          <ul className="list-disc pl-6">
            <li><strong>Formule Free :</strong> Abonnement gratuit offrant une présence limitée dans l'annuaire, alertes de demandes de devis, accès partiel aux détails des demandes.</li>
            <li><strong>Formule Pro :</strong> Abonnement payant offrant une visibilité accrue, coordonnées visibles, réponses limitées aux demandes de devis, support client premium.</li>
            <li><strong>Formule Pro+ :</strong> Abonnement payant avec accès illimité aux coordonnées, mise en avant dans l'annuaire, et support client premium.</li>
          </ul>
        </p>

        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          3. Tarifs et Modalités de Souscription
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Les tarifs des abonnements sont indiqués en euros hors taxes (HT) et facturés mensuellement. Les abonnements sont renouvelés automatiquement jusqu'à résiliation. Le Client peut souscrire et régler par carte bancaire ou tout autre moyen proposé.
        </p>

        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          4. Paiement et Facturation
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Le paiement est exigible immédiatement lors de la souscription. REALITIM garantit la sécurité des transactions et propose une facturation mensuelle téléchargeable depuis l'espace utilisateur.
        </p>

        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          5. Droit de Rétractation et Remboursement
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Le Client dispose d'un délai de 14 jours pour exercer son droit de rétractation. REALITIM propose également une garantie satisfait ou remboursé de 30 jours pour les abonnements Pro et Pro+.
        </p>

        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          6. Responsabilités
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          REALITIM s'engage à assurer un service de qualité. Le Client est responsable des informations qu’il fournit et de l’utilisation qu’il fait de la plateforme.
        </p>

        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          7. Résiliation de l’Abonnement
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Le Client peut résilier son abonnement à tout moment depuis son espace utilisateur. REALITIM se réserve le droit de résilier un abonnement en cas de non-respect des CGV.
        </p>

        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          8. Modifications des CGV
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          REALITIM se réserve le droit de modifier les présentes CGV. Toute modification sera communiquée sur la plateforme et prendra effet 30 jours après notification.
        </p>

        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          9. Protection des Données Personnelles
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          REALITIM traite les données personnelles des utilisateurs conformément à la réglementation en vigueur et à la Politique de Confidentialité.
        </p>

        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          10. Loi Applicable et Juridiction Compétente
        </h2>
        <p className="text-gray-700 leading-relaxed mb-6">
          Les présentes CGV sont régies par le droit français. Tout litige relatif aux CGV sera soumis aux tribunaux compétents de Nantes.
        </p>

        <h2 className="text-2xl font-semibold mb-6 text-[#1e3a8a] font-roboto border-b-2 border-[#1e3a8a] pb-2">
          Contact
        </h2>
        <p className="text-gray-700 leading-relaxed">
          Pour toute question, contactez-nous à : contact@realitim.com ou par téléphone au +33 06 61 18 01 15.
        </p>
      </section>
    </div>
  );
}

export default CGV;
