import React from "react";

function Arguments() {
  return (
    <section className="container mx-auto px-4 py-16">
      <h2 className="text-4xl font-bold mb-12 text-center text-gray-800">
        Pourquoi choisir notre configurateur ?
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
        {/* Argument 1 */}
        <div className="group bg-white rounded-lg shadow-lg p-8 text-center hover:shadow-2xl transition-shadow duration-500 transform hover:-translate-y-2">
          <div className="flex justify-center mb-6">
            <i className="fas fa-magic text-5xl text-blue-600 group-hover:text-blue-800 transition-colors duration-500"></i>
          </div>
          <h3 className="text-2xl font-semibold mb-4 text-gray-800 group-hover:text-blue-800 transition-colors duration-500">
            Conception intuitive
          </h3>
          <p className="text-gray-600">
            Créez facilement votre stand personnalisé en quelques clics.
          </p>
        </div>

        {/* Argument 2 */}
        <div className="group bg-white rounded-lg shadow-lg p-8 text-center hover:shadow-2xl transition-shadow duration-500 transform hover:-translate-y-2">
          <div className="flex justify-center mb-6">
            <i className="fas fa-bolt text-5xl text-blue-600 group-hover:text-blue-800 transition-colors duration-500"></i>
          </div>
          <h3 className="text-2xl font-semibold mb-4 text-gray-800 group-hover:text-blue-800 transition-colors duration-500">
            Devis instantané
          </h3>
          <p className="text-gray-600">
            Obtenez un devis immédiat pour votre projet.
          </p>
        </div>

        {/* Argument 3 */}
        <div className="group bg-white rounded-lg shadow-lg p-8 text-center hover:shadow-2xl transition-shadow duration-500 transform hover:-translate-y-2">
          <div className="flex justify-center mb-6">
            <i className="fas fa-users text-5xl text-blue-600 group-hover:text-blue-800 transition-colors duration-500"></i>
          </div>
          <h3 className="text-2xl font-semibold mb-4 text-gray-800 group-hover:text-blue-800 transition-colors duration-500">
            Réseau de professionnels
          </h3>
          <p className="text-gray-600">
            Accédez à notre annuaire de standistes qualifiés.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Arguments;
